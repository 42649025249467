import React, { useState, useImperativeHandle } from 'react';
import classNames from 'classnames';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';

export type AppProps = {
  name: string;
  placeholder?: string;
  initialValue?: string;
  validator: any;
  disabled?: boolean;
  testId?: string;
};

const PhoneNumber = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      placeholder = '',
      initialValue = '',
      validator,
      disabled = false,
      testId = null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState(initialValue);
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => setValue(v),
        setDisabled,
        getValue: () => ({ [name]: value }),
        getError: () => ({ [name]: error }),
        checkError: () => {
          try {
            if (validator.validateSync(value) && isValidPhoneNumber(value)) {
              return false;
            }
            return true;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
            }
            return true;
          }
        },
      })
    );

    const handleOnChange = (v: string) => {
      try {
        v.length > 12 ? (v = v.slice(0, 12)) : v;
        setValue(v);
        validator.validateSync(v);
        if (v) {
          if (isValidPhoneNumber(v)) {
            setError(null);
          } else {
            // @ts-ignore
            setError('Please enter a valid phone number');
          }
        }
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          setError(e.errors[0]);
        }
      }
    };

    const inputStyle = classNames(
      'w-full text-base leading-normal text-gray-900 outline-none placeholder-gray-400 px-3 py-2 bg-white shadow border rounded-md border-gray-300',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
        'bg-gray-100': isDisabled,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <PhoneInput
          ref={inputRef}
          className={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          international
          smartCaret
          country="US"
          withCountryCallingCode
          disabled={isDisabled}
          maxLength={15}
        />
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

PhoneNumber.displayName = 'PhoneNumber';

export default PhoneNumber;
