import { gql } from '@apollo/client';

export const fragments = {
  driver: gql`
    fragment DriverFragment on Driver {
      _id
      carrier
      driverContact {
        email
        extension
        firstName
        lastName
        phoneNumber
        type
      }
      isOwnerOperator
      canAccessApp
      isCredentialsCreated
      createdAt
      updatedAt
    }
  `,
};

export const truckFragments = {
  shipmentDriverTruck: gql`
    fragment ShipmentDriverTruckFragment on ShipmentDriverTruck {
      _id
      createdAt
      driver {
        ...DriverFragment
      }
      truckNumber
      trailerNumber
      driverPhoneNumber
      dispatcherName
      dispatcherPhoneNumber
      createdAt
      updatedAt
    }
    ${fragments.driver}
  `,
};

export const CREATE_DRIVERS = gql`
  mutation createDrivers($drivers: [DriverInput!]!, $isOwnerOperator: Boolean) {
    createDrivers(drivers: $drivers, isOwnerOperator: $isOwnerOperator) {
      ...DriverFragment
    }
  }
  ${fragments.driver}
`;

export const UPDATE_DRIVER = gql`
  mutation UpdateDriver($body: UpdateDriverInput!) {
    updateDriver(updateDriverInput: $body) {
      ...DriverFragment
    }
  }
  ${fragments.driver}
`;

export const GET_DRIVER_BY_ID = gql`
  query GetDriverById($id: String!) {
    getDriverById(id: $id) {
      ...DriverFragment
    }
  }
  ${fragments.driver}
`;

export const REMOVE_DRIVER = gql`
  mutation RemoveDriver($id: String!) {
    removeDriver(id: $id) {
      ...DriverFragment
    }
  }
  ${fragments.driver}
`;

export const CREATE_SHIPMENT_DRIVER_TRUCK = gql`
  mutation CreateShipmentDriverTruck($body: CreateShipmentDriverTruckInput!) {
    createShipmentDriverTruck(createShipmentDriverTruckInput: $body) {
      _id
    }
  }
`;

export const UPDATE_SHIPMENT_DRIVER_TRUCK = gql`
  mutation UpdateShipmentDriverTruck($body: UpdateShipmentDriverTruckInput!) {
    updateShipmentDriverTruck(updateShipmentDriverTruckInput: $body) {
      _id
    }
  }
`;

export const GET_DRIVER_AND_TRUCK_BY_SHIPMENT_ID = gql`
  query GetDriverAndTruckByShipmentId($id: String!) {
    getShipmentById(id: $id) {
      assignedDriverTruck {
        ...ShipmentDriverTruckFragment
      }
    }
  }
  ${truckFragments.shipmentDriverTruck}
`;

export const ALLOW_MOBILE_ACCESS_FOR_DRIVER = gql`
  mutation AllowMobileAccessForDriver($driverId: String!) {
    allowMobileAccessForDriver(driverId: $driverId) {
      ...DriverFragment
    }
  }
  ${fragments.driver}
`;

export const REVOKE_MOBILE_ACCESS_FOR_DRIVER = gql`
  mutation RevokeMobileAccessForDriver($driverId: String!) {
    revokeMobileAccessForDriver(driverId: $driverId) {
      ...DriverFragment
    }
  }
  ${fragments.driver}
`;
