import { gql } from '@apollo/client';
import { fragments as miscFragments, locationFragments } from './miscFragments';
import { fragments as toggleManagerFragments } from './toggleManagers';

export const fragments = {
  shipperBasicInfo: gql`
    fragment ShipperBasicInfoFragment on Shipper {
      _id
      companyName
      onboardingStatus
      primaryContacts {
        ...ContactFragment
      }
      createdAt
      updatedAt
    }
    ${miscFragments.contact}
  `,
  shipperAsPayorInfo: gql`
    fragment ShipperAsPayorInfoFragment on Shipper {
      _id
      companyName
      nameOfDBA
      onboardingStatus
      primaryContacts {
        ...ContactFragment
      }
      companyAddress {
        ...AddressFragment
      }
    }
    ${miscFragments.address}
    ${miscFragments.contact}
  `,
  shipperInfo: gql`
    fragment ShipperFragment on Shipper {
      _id
      EIN
      companyName
      onboardingStatus
      isBillingAddressSameAsCompany
      accountingContact {
        ...ContactFragment
      }
      companyAddress {
        ...AddressFragment
      }
      billingAddress {
        ...AddressFragment
      }
      primaryContacts {
        ...ContactFragment
      }
      billTo
      notes
      businessHours
      createdAt
      isShippingHighValue
      largeShipmentValue
      nameOfDBA
      primaryContacts {
        ...ContactFragment
      }
      toggleManager {
        ...ToggleManagerFragment
      }
      updatedAt
      createdByShipper
    }
    ${miscFragments.address}
    ${miscFragments.contact}
    ${toggleManagerFragments.toggleManager}
  `,
};

export const GET_SHIPPER_BASIC_INFO = gql`
  query GetShipperBasicInfo($companyName: String!) {
    getShipperByCompanyName(companyName: $companyName) {
      ...ShipperBasicInfoFragment
    }
  }
  ${fragments.shipperBasicInfo}
`;

export const GET_SHIPPER_BY_COMPANY = gql`
  query GetShipperByCompany($companyName: String!) {
    getShipperByCompanyName(companyName: $companyName) {
      ...ShipperFragment
    }
  }
  ${fragments.shipperInfo}
`;

export const CREATE_SHIPPER = gql`
  mutation CreateShipper($body: CreateShipperInput!) {
    createShipper(createShipperInput: $body) {
      ...ShipperBasicInfoFragment
    }
  }
  ${fragments.shipperBasicInfo}
`;

export const UPDATE_SHIPPER = gql`
  mutation UpdateShipper($body: UpdateShipperInput!) {
    updateShipper(updateShipperInput: $body) {
      ...ShipperBasicInfoFragment
    }
  }
  ${fragments.shipperBasicInfo}
`;

export const GET_SHIPPER_MEDIUM_INFO = gql`
  query GetShipperMediumInfo($id: String!) {
    getShipperById(id: $id) {
      ...ShipperAsPayorInfoFragment
    }
  }
  ${fragments.shipperAsPayorInfo}
`;

export const GET_SHIPPER_BY_ID = gql`
  query GetShipperById($id: String!) {
    getShipperById(id: $id) {
      ...ShipperFragment
    }
  }
  ${fragments.shipperInfo}
`;

export const GET_SHIPPER_LOCATIONS = gql`
  query GetShipperLocations($shipperId: String!) {
    getShipperLocations(shipperId: $shipperId) {
      ...PickupDropFragment
    }
  }
  ${locationFragments.pickupDrop}
`;

export const GET_ALL_SHIPPERS = gql`
  query GetAllShippers {
    shippers {
      ...ShipperBasicInfoFragment
    }
  }
  ${fragments.shipperBasicInfo}
`;

export const GET_ALL_SHIPPERS_BY_ID = gql`
  query GetAllShippersById($shipperId: String!,
    $limit: Int
    $internalUserFilter: InternalUserFilterInput
    $offset: Int
    $search: String
    $sortBy: String
    $sortingOrder: SortingOrder) {
    getShippersById(shipperId: $shipperId,
     limit: $limit
      internalUserFilter: $internalUserFilter
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortingOrder: $sortingOrder) {
    shipperData{
      ...ShipperFragment
      createdByShipper
    }
    info {
        ...PaginationFragment
      }
    }
  }
  ${fragments.shipperInfo}
  ${miscFragments.pagination}

`;
export const CREATE_NEW_SHIPPER_UNDER_BY_ID = gql`
  mutation NewShipperCreatedById($body: CreateShipperByIdInput!) {
    newShipperCreatedById(shipperInput: $body) {
      ...ShipperFragment
      createdByShipper
    }
  }
  ${fragments.shipperInfo}
`;

export const SHIPPER_REMOVE_BY_ID = gql`
  mutation ShipperRemovalById($shipperId: String!) {
    shipperRemovalById(shipperId: $shipperId) {
      ...ShipperFragment
    }
  }
  ${fragments.shipperInfo}
`;
export const SHIPPER_EDIT_BY_ID = gql`
  mutation editInternalUser($contact: ShipperContact!,$shipperId: String!) {
    shipperUpdateById(shipperId: $shipperId,contact:$contact) {
      ...ShipperFragment
    }
  }
  ${fragments.shipperInfo}
`;