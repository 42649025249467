import React from 'react';
import { formatCurrency } from 'utils/misc';

type AppProps = {
  minRate: number;
  maxRate: number;
  testId?: string | null;
};

const AmountRange: React.FC<AppProps> = ({ minRate, maxRate, testId = null }) => {
  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {minRate ? formatCurrency(minRate) : '-'} - {maxRate ? formatCurrency(maxRate) : '-'}
    </p>
  );
};

export default AmountRange;
