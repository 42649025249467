/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createContext } from 'react';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useApolloClient } from '@apollo/client';
import { GET_SHIPPER_BASIC_INFO } from 'queries/shipper';
import { GET_CARRIER_BASIC_INFO } from 'queries/carrier';
import { Status } from 'utils/enum';
import { isCarrier, isShipper, isTAM, setToken } from 'utils/misc';
import { errorToast } from 'components/Toaster';
import { GET_TOGGLE_EMPLOYEE_BY_EMAIL } from 'queries/toggleManagers';

interface IAccountInfo {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  organisation: string;
  userId: string;
}

interface ICognitoUserInfo {
  'custom:organization': string;
  'custom:role': string;
  email: string;
  family_name: string;
  name: string;
  phone_number: string;
}

export const AuthContext = createContext({
  isAuthenticated: false,
  accountInfo: null as null | IAccountInfo,
  context: null,
  getTokenFromLocalstorage: (): string | null => '',
  setContext: (data: any): void => {},
  saveAuthInfo: (token: string): boolean => true,
  resetAuthInfo: (): void => {},
  isImpersonateMode: (): boolean => false,
  setImpersonateData: (role: string, orgName: string) => {},
  removeImpersonateData: () => {},
});

type AppProps = {
  children: React.ReactNode;
};

const setUserBack = (user: Record<string, any>) => {
  // @ts-ignore
  if (window?.Userback) {
    // @ts-ignore
    window.Userback.setEmail(user?.email);
    // @ts-ignore
    window.Userback.setName(user?.name);
    // @ts-ignore
    window.Userback.setData({
      email: user?.email,
      username: user?.name,
      organisation: user?.organisation,
    });
  }
};

const AuthProvider: React.FC<AppProps> = ({ children }) => {
  const client = useApolloClient();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accountInfo, setAccountInfo] = useState<IAccountInfo | null>(null);
  const [context, setContext] = useState(null);
  const [loading, setLoading] = useState(true);

  const _convertContactToUser = (contact: Record<string, any>) => {
    return {
      name: `${contact.firstName} ${contact.lastName}`,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phoneNumber,
    };
  };

  const _convertCarrierContactToUser = (
    carrierContact: Record<string, any>,
    email: string
  ) => {
    const contact = carrierContact?.find(
      (contct: any) => contct.email === email
    );
    return {
      name: `${contact.firstName} ${contact.lastName}`,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phoneNumber,
      isInternalUser: contact.isInternalUser,
    };
  };

  const fetchShipper = async (companyName: string) => {
    let shipperData;
    try{
      const { data } = await client.query({
        query: GET_SHIPPER_BASIC_INFO,
        variables: { companyName },
        fetchPolicy: 'network-only',
      });
      shipperData = data?.getShipperByCompanyName;
    }
   catch (error:any) {
    errorToast(`${error.message}`);
    throw error
  }
    if (!shipperData) {

      return {
        status: shipperData?.onboardingStatus || Status.Draft,
      };
    }
    return {
      userId: shipperData?._id || null,
      status: shipperData?.onboardingStatus || Status.Draft,
      ..._convertContactToUser(shipperData?.primaryContacts[0]),
    };
  };

  const fetchCarrier = async (companyName: string) => {
    const { data } = await client.query({
      query: GET_CARRIER_BASIC_INFO,
      variables: { companyName },
      fetchPolicy: 'network-only',
    });
    const carrierData = data?.getCarrierByCompanyName;
    if (!carrierData) {
      return {
        status: carrierData?.onboardingStatus || Status.Draft,
      };
    }
    return {
      userId: carrierData?._id || null,
      status: carrierData?.onboardingStatus || Status.Draft,
      ..._convertContactToUser(carrierData?.primaryContacts[0]),
    };
  };

  const fetchManager = async (email: string) => {
    const { data } = await client.query({
      query: GET_TOGGLE_EMPLOYEE_BY_EMAIL,
      variables: { email },
      fetchPolicy: 'network-only'
    })
    const managerData = data?.getToggleEmployeeByEmail;
    if (!managerData){
      return {}
    }
    return {
      userId: managerData?._id || null,
      ..._convertContactToUser(managerData?.contact)
    }
  }

  const fetchCarrierDetails = async (companyName: string, email: string) => {
    const { data } = await client.query({
      query: GET_CARRIER_BASIC_INFO,
      variables: { companyName },
      fetchPolicy: 'network-only',
    });
    const carrierData = data?.getCarrierByCompanyName;
    if (!carrierData) {
      return {
        status: carrierData?.onboardingStatus || Status.Draft,
      };
    }
    return {
      userId: carrierData?._id || null,
      status: carrierData?.onboardingStatus || Status.Draft,
      ..._convertCarrierContactToUser(carrierData?.primaryContacts, email),
    };
  };

  useEffect(() => {
    // make call here on first page load to verify the user
    checkIfUserIsAuthenticated();
  }, []);

  const getTokenFromLocalstorage = () => {
    return localStorage.getItem(process.env.REACT_APP_SESSION_KEY || 'sid');
  };

  const setImpersonateData = (role: string, orgName: string) => {
    localStorage.setItem('impRole', role);
    localStorage.setItem('impOrg', orgName);
  };

  const removeImpersonateData = () => {
    localStorage.removeItem('impRole');
    localStorage.removeItem('impOrg');
  };

  const isImpersonateMode = () => {
    return !!getImpersonateData();
  };

  const getImpersonateData = () => {
    const role = localStorage.getItem('impRole');
    const companyName = localStorage.getItem('impOrg');
    if (role && companyName) {
      return {
        companyName,
        role,
      };
    }
    return null;
  };

  const checkIfUserIsAuthenticated = () => {
    const token = getTokenFromLocalstorage();
    if (!token) {
      resetAuthInfo();
    } else {
      getAccountInfo();
    }
  };

  const resetAuthInfo = () => {
    localStorage.clear();
    client.cache.reset();
    setIsAuthenticated(false);
    setAccountInfo(null);
    setLoading(false);
  };

  const saveAuthInfo = (token: string) => {
    setToken(token);
    getAccountInfo();
    return true;
  };

  const getAccountInfo = () => {
    const poolData = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
    };

    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      resetAuthInfo();
    } else {
      cognitoUser.getSession((err: any, session: any) => {
        if (err || !session.isValid()) {
          resetAuthInfo();
        } else {
          cognitoUser.getUserAttributes(async (err, userData) => {
            if (err) {
              resetAuthInfo();
            } else {
              let user: ICognitoUserInfo | Record<string, any> = {};
              userData?.forEach((u) => {
                user = { [u.Name]: u.Value, ...user };
              });
              let role = user['custom:role'];
              let organisation = null;
              let extraData: any = {};

              // Check for impersonation mode
              const impData = getImpersonateData();
              if (impData) {
                if (!isTAM(role)) {
                  resetAuthInfo();
                } else {
                  const { role: impRole, companyName } = impData;
                  organisation = companyName;
                  if (isShipper(impRole)) {
                    extraData = (await fetchShipper(companyName)) || {};
                  } else if (isCarrier(impRole)) {
                    extraData = (await fetchCarrier(companyName)) || {};
                  }
                  role = impRole;
                }
              } else {
                organisation = user['custom:organization'];
                if (isShipper(role)) {
                  extraData = await fetchShipper(organisation);
                } else if (isCarrier(role)) {
                  extraData = await fetchCarrierDetails(
                    organisation,
                    user.email
                  );
                } else if (isTAM(role)) {
                  extraData = await fetchManager(user.email)
                }
                extraData = {
                  name: `${user.name} ${user.family_name}`,
                  firstName: user.name,
                  lastName: user.family_name,
                  email: user.email,
                  phone: user.phone_number,
                  ...extraData,
                };
              }
              const userInfo = {
                role,
                organisation,
                ...extraData,
              };
              
              setUserBack(userInfo);
              setAccountInfo(userInfo);
              setIsAuthenticated(true);
              setLoading(false);
            }
          });
        }
      });
    }
  };

  if (loading) {
    return <Loader variant={LoaderVariants.Page} title="Loading..." />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        // @ts-ignore
        accountInfo,
        context,
        getTokenFromLocalstorage,
        setContext,
        saveAuthInfo,
        resetAuthInfo,
        setImpersonateData,
        removeImpersonateData,
        isImpersonateMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
