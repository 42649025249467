import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Lottie from 'react-lottie';
import tricube from './lotties/tricube-spinner.json';

import Icon from '../Icon';

export enum Variants {
  Page = 'page',
  Overlay = 'overlay',
  Section = 'section',
  Inline = 'inline',
}

export type AppProps = {
  variant?: Variants;
  title?: string;
  testId?: string;
};

const Loader: React.FC<AppProps> = ({
  title = '',
  variant = Variants.Page,
  testId = 'loader',
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: tricube,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (variant === Variants.Page) {
    return (
      <div
        className="w-screen h-screen flex flex-col justify-center items-center bg-indigo-50"
        data-testid={testId}
      >
        <Lottie options={defaultOptions} height={240} width={240} />
      </div>
    );
  }

  if (variant === Variants.Overlay) {
    return (
      <div
        className="fixed z-40 top-0 bottom-0 right-0 left-0 bg-indigo-50 bg-opacity-50"
        data-testid={testId}
      >
        <div className="h-full flex flex-col justify-center items-center">
          <Lottie options={defaultOptions} height={240} width={240} />
        </div>
      </div>
    );
  }

  if (variant === Variants.Inline) {
    return (
      <div
        className="w-full flex justify-center items-center"
        data-testid={testId}
      >
        <svg
          className="animate-spin h-5 w-5 text-primary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div
      className="w-full h-full flex flex-col justify-center items-center bg-indigo-50"
      data-testid={testId}
    >
      <Icon
        name="loading"
        className="text-primary h-10 w-10 animate-spin"
        testId={testId}
      />
      {title && <div className="text-primary mt-4">{title}</div>}
    </div>
  );
};

export default Loader;
