import React, { useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import { InputType } from '../utils';
import Icon from 'components/Icon';

export type AppProps = {
  name: string;
  placeholder?: string;
  initialValue?: string;
  validator: any;
  disabled?: boolean;
  lefIcon?: string;
  suffix?: string;
  variant?: InputType;
  maxLength?: number;
  onChange?: (val: any) => void;
  trim?: boolean;
  testId?: string;
};

const Text = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      placeholder = '',
      initialValue = '',
      disabled = false,
      validator,
      lefIcon = null,
      suffix = '',
      variant = InputType.Text,
      maxLength = undefined,
      testId = null,
      trim = false,
      onChange = () => null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState(initialValue || '');
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => {
          setValue(v);
          setError(null);
        },
        setDisabled,
        getValue: () => ({
          [name]: variant === InputType.Number ? Number(value) : value,
        }),
        getError: () => ({ [name]: error }),
        checkError: () => {
          try {
            validator.validateSync(value || '');
            return false;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
              return true;
            }
            return false;
          }
        },
      })
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      const _v = trim ? (v || '').trim() : v;

      if (variant === InputType.Number) {
        // @ts-ignore
        if (!isNaN(_v)) {
          onChange(_v);
          setValue(_v);
        }
      } else {
        onChange(_v);
        setValue(_v);
      }
      try {
        validator.validateSync(_v || '');
        setError(null);
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          setError(e.errors[0]);
        }
      }
    };

    const inputStyle = classNames(
      'w-full text-base leading-normal text-gray-900 outline-none placeholder-gray-400 px-3 py-2 bg-white shadow border rounded-md border-gray-300',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
        'bg-gray-100': isDisabled,
        'pl-8': lefIcon,
      }
    );

    return (
      <div className="w-full relative" data-testid={testId}>
        {lefIcon && (
          <div className="absolute inset-y-0 left-2 flex items-center text-base leading-normal text-gray-500">
            <Icon name={lefIcon} className="w-5 h-5" />
          </div>
        )}
        <input
          autoComplete="never"
          ref={inputRef}
          className={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          disabled={isDisabled}
          maxLength={maxLength}
        />
        {suffix && (
          <div className="absolute inset-y-0 right-2 flex items-center text-base leading-normal text-gray-500">
            {suffix}
          </div>
        )}
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

Text.displayName = 'Text';

export default Text;
