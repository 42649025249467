import React, { useImperativeHandle, useRef } from 'react';

import { initializeArray } from '../../utils/misc';
import Input from '../Input';

export type AppProps = {
  config: Array<Record<string, any>>;
  className?: string;
  onSubmit?: () => null;
  initialValues?: any;
  testId?: string | null;
};

const getTotalFields = (config: Array<any>) => {
  let count = 0;

  const countFields = (field: any) => {
    if (field.fields) {
      field.fields.map((f: any) => countFields(f));
    } else {
      count += 1;
    }
  };

  config.map((group) => {
    group.fields.map((field: any) => {
      countFields(field);
    });
  });

  return count;
};

const Form = React.forwardRef<HTMLElement, AppProps>(
  ({ config, className = '', testId = null }, ref) => {
    const totalFields = getTotalFields(config);

    // eslint-disable-next-line
    const inputRefs = initializeArray(totalFields).map(() => useRef(null));
    let idx = -1;

    const focusOn = (inputRef: any): void => inputRef?.focus?.();

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      focusOn,
      overrideAllValues: (values: Record<string, any>) => {
        for (const ref of inputRefs) {
          if (ref?.current?.overrideValue) {
            ref?.current?.overrideValue?.(values[ref.current.name] || '');
          }
        }
      },
      setAllDisabled: (disable = true) => {
        for (const ref of inputRefs) {
          if (ref?.current?.setDisabled) {
            ref?.current?.setDisabled?.(disable);
          }
        }
      },
      overrideValueByKey: (key: string, value: any) => {
        for (const ref of inputRefs) {
          if (ref?.current?.name === key) {
            ref?.current?.overrideValue?.(value);
          }
        }
      },
      getInputRef: (name: string): null | React.MutableRefObject<any> => {
        for (const ref of inputRefs) {
          if (ref?.current?.name === name) return ref.current;
        }
        return null;
      },
      getValues: () => {
        // collect all the values
        let values = {};

        for (let i = 0; i < totalFields; i += 1) {
          if (inputRefs[i]?.current?.getValue) {
            const value = inputRefs[i]?.current?.getValue?.();
            value && (values = { ...values, ...value });
          }
        }
        return values;
      },
      getErrors: () => {
        // collect all errors
        let errors = {};

        for (let i = 0; i < totalFields; i += 1) {
          if (inputRefs[i]?.current?.getError) {
            const error = inputRefs[i]?.current?.getError?.();
            error && (errors = { ...errors, ...error });
          }
        }
        return errors;
      },
      hasErrors: (focus = true) => {
        let hasErrors = false;
        let firstError = true;
        for (let i = 0; i < totalFields; i += 1) {
          if (inputRefs[i]?.current?.checkError?.()) {
            if (firstError) {
              firstError = false;
              focus && focusOn(inputRefs[i]?.current);
            }
            hasErrors = true;
          }
        }
        return hasErrors;
      },
    }));

    const renderField = (field: any) => {
      if (field.fields) {
        return (
          <div
            className={`flex items-center ${field.className}`}
            key={field.key}
          >
            {field.fields.map((f: any) => (
              <div key={f.key} className="flex-1 mr-5 last:mr-0">
                {renderField(f)}
              </div>
            ))}
          </div>
        );
      }

      idx += 1;
      return (
        <div key={field.key} className={`py-2 ${field.className}`}>
          <Input
            {...field}
            ref={inputRefs[idx]}
            name={field.key}
            variant={field.type}
            label={field.label}
            placeholder={field.placeholder}
          />
        </div>
      );
    };

    const renderGroup = (group: any) => {
      return (
        <div key={group.key}>
          {group.label && (
            <div
              className={`w-full inline-flex flex-col py-3 my-3 items-start justify-start border-b-1 border-gray-200 ${group.className}`}
            >
              <p className="text-lg font-bold leading-normal text-gray-900">
                {group.label}
              </p>
              {group.description && (
                <p className="text-sm leading-tight text-gray-500">
                  {group.description}
                </p>
              )}
            </div>
          )}
          <div className={group.className}>
            {group.fields.map((field: Record<string, any>) => {
              return renderField(field);
            })}
          </div>
        </div>
      );
    };

    return (
      <div className={className} data-testid={testId}>
        {config.map((group) => renderGroup(group))}
      </div>
    );
  }
);

Form.displayName = 'Form';

export default Form;
