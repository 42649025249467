export enum Roles {
  Shipper = 'SHIPPER',
  Carrier = 'CARRIER',
  Carrier_Driver = 'CARRIER;DRIVER',
  Toggle = 'TOGGLE',
  Driver = 'DRIVER',
}

export enum Status {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Open = 'OPEN',
  Rejected = 'REJECTED',
}

export enum ShipmentConfirmation {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}

export enum ShipmentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  AwaitingApproval = 'AWAITING_APPROVAL',
  RejectedLoads = 'PRICING_REJECTED',
  InTransit = 'IN_TRANSIT',
  NotReady = 'NOT_READY',
  Open = 'OPEN',
  PendingConfiramtion = 'PENDING_CONFIRMATION',
  PickedUp = 'PICKED_UP',
  ReadyForPickup = 'READY_FOR_PICKUP',
}

export enum LocationType {
  Pickup = 'PICKUP',
  Drop = 'DROP',
  Other = 'OTHER'
}

export enum BidStatus {
  BidPlaced = 'BID_PLACED',
  ConfirmOffer = 'CONFIRM_OFFER',
  NotAvailable = 'NOT_AVAILABLE',
  OfferConfirmed = 'OFFER_CONFIRMED',
  Expired = 'BID_EXPIRED',
}

export enum Order {
  Asc = 'ASC',
  Dsc = 'DESC',
}

export enum Sort {
  Asc = 'asc',
  Dsc = 'desc',
}
