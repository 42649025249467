import { gql } from '@apollo/client';
import { locationFragments } from './miscFragments';
import { fragments as lookupFragments } from './lookup';

export const fragments = {
  accessorialCharges: gql`
    fragment AccessorialChargesFragment on AccessorialCharge {
      _id
      accessorialType {
        ...LookupAccessorialFragment
      }
      customUnitPrice
      customUnitType {
        ...LookupFragment
      }
      locationDetail
      quantity
      isApproved
      createdBy
      createdUserId
      createdUserType
      createdUserEmail
    }
    ${lookupFragments.lookup}
    ${lookupFragments.lookupAccessorial}
  `,
};

export const GET_ACCESORIAL_CHARGES_BY_SHIPMENT_ID = gql`
  query GetLocationDetailsByShipmentID(
    $shipmentId: String!
    $includeAll: Boolean
  ) {
    getLocationDetailByShipmentId(
      shipmentId: $shipmentId
      includeAll: $includeAll
    ) {
      _id
      documentUpdatedBy
      documentLink
      location {
        ...PickupDropFragment
      }
      locationType
      accessorialCharges {
        ...AccessorialChargesFragment
      }
    }
  }
  ${locationFragments.pickupDrop}
  ${fragments.accessorialCharges}
`;

export const CREATE_ACCESSORIAL_CHARGE = gql`
  mutation createAccessorialCharge($body: AccessorialChargeInput!) {
    createAccessorialCharge(createAccessorialChargeInput: $body) {
      _id
    }
  }
`;

export const UPDATE_ACCESSORIAL_CHARGE_BY_ID = gql`
  mutation UpdateAccessorialCharge($body: UpdateAccessorialChargeInput!) {
    updateAccessorialCharge(updateAccessorialChargeInput: $body) {
      _id
    }
  }
`;

export const REMOVE_ACCESSORIAL_CHARGE_BY_ID = gql`
  mutation RemoveAccessorialCharge($id: String!) {
    removeAccessorialCharge(id: $id) {
      _id
    }
  }
`;

export const GET_TOGGLEMANAGER_ID = gql`
query GetToggleManagerID($shipmentId:String!){
  getToggleManager(shipmentId:$shipmentId){
    email
  }
}
`;
export const UPDATE_ACCESSORIALCHARGE_REQUEST=gql`
mutation UpdateAccessorialChargeRequest($body:UpdateAccessorialChargeRequestInput!){
  updateAccessorialChargeRequest(updateAccessorialChargeRequestInput:$body){
_id
  }
}
`
