import React, { useState } from 'react';
import Logo from 'components/Logo';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export type AppProps = {
  filePath: string;
};

const TermsDocument: React.FC<AppProps> = ({ filePath }) => {
  const [numPages, setNumPages] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <div className="bg-gray-50 h-screen flex">
      <div className="flex-1">
        <div className="w-full border-b-1 py-4 pl-8 items-left sticky top-0 bg-white z-10">
          <Logo />
        </div>
        <div className="bg-gray-50 h-screen flex">
          <div className="flex-1">
            <div className="px-8">
              <div className="flex flex-col items-center py-10">
                <div className="w-2/3 h-1/3 inline-flex flex-col space-y-6 items-center justify-start px-5 pt-5 pb-8 bg-white shadow border rounded-lg border-gray-200">
                  <Document
                    file={filePath}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={900}
                      />
                    ))}
                  </Document>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsDocument;
