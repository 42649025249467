import React from 'react';

export type AppProps = {
  label: string;
  bgColor?: string;
  textColor?: string;
  testId?: string | null;
};

const Tag: React.FC<AppProps> = ({
  label,
  bgColor = '',
  textColor = '',
  testId = null,
}) => {
  return (
    <div
      className={`inline-flex items-center justify-center px-3 py-1 rounded-full ${bgColor}`}
      data-testid={testId}
    >
      <span
        className={`text-sm font-medium leading-tight text-center ${textColor}`}
      >
        {label}
      </span>
    </div>
  );
};

export default Tag;
