import React from 'react';
import Tag from 'components/Tag';
import { statusToTagClass } from 'utils/misc';
import Search from 'components/Table1/components/Search';
import Button, { Variants } from 'components/Button';
import { NavLink } from 'react-router-dom';
import Menu from 'components/Menu';

const Profile = () => {
  const getStatusTag = (value: string) => {
    const { textColor, bgColor } = statusToTagClass[value];
    return <Tag textColor={textColor} bgColor={bgColor} label={value} />;
  };

  const menuItems: Array<any> = [
    {
      renderNode: (): React.ReactNode => <NavLink to="/internal-user" >
        <p className="px-5 py-3 hover:bg-primary hover:text-white ">Internal Team</p></NavLink>,
    },
    {
      renderNode: (): React.ReactNode => <NavLink to="/role" >
        <p className="px-5 py-3 hover:bg-primary hover:text-white "> Role</p></NavLink>,
    },
  ];
  return (
    <div className="w-screen h-full bg-gray-100">
      <div className="w-full inline-flex flex-col items-start justify-start p-5 bg-white border-gray-200 border-b-1">
        <p className="text-3xl font-bold leading-9 text-gray-900">
          Profile
        </p>
      </div>
      <div>
        <Search setSearch={function (search: string): void {
          throw new Error('Function not implemented.');
        }} />
      </div>
      <div className="shadow bg-white rounded-lg m-5 p-5 profile_user_roles_wrapper">
        <div className="px-5 pt-5 pb-16">
          <div className="w-80 px-4 py-4 "><Button label="Notification Settings" variant={Variants.Primary} /></div>
          <Menu
            trigger={
              (<div className="w-80 px-4 py-4 "><Button label="User & Role Administration" rightIcon="chevron-right" variant={Variants.Primary} /></div>)
            }
            items={menuItems}
          />
          <div className="w-80 px-4 p-4 "><Button label="Account Terms & Agrements" variant={Variants.Primary} /></div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
