import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

export enum Variants {
  Primary = 'primary',
  Negative = 'negative',
  Outline = 'outline',
  Warning = 'warning',
  Secondary = 'secondary',
  Link = 'link',
  Accept = 'accept',
  Custom = 'Custom',
}

export enum RenderAs {
  Button = 'button',
  Div = 'div',
}

export type AppProps = {
  variant?: Variants;
  as?: RenderAs;
  label: string;
  disabled?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  loading?: boolean;
  onClick?: (args?: any) => void;
  className?: string;
  tooltip?: string;
  testId?: string | null;
  render?: React.ReactNode;
};

const Button: React.FC<AppProps> = ({
  variant = Variants.Primary,
  as = RenderAs.Button,
  label,
  disabled = false,
  onClick,
  leftIcon = null,
  rightIcon = null,
  loading = false,
  className = '',
  tooltip = null,
  testId = null,
  render = null,
}) => {
  const btnStyle = classNames(
    `w-full inline-flex items-center justify-center px-3 py-2 shadow rounded-md cursor-pointer ${className}`,
    {
      'pointer-events-none opacity-50': disabled || loading,
      'bg-primary': variant === Variants.Primary,
      'bg-indigo-100': variant === Variants.Negative,
      'bg-yellow-600': variant === Variants.Custom,
      'bg-green-600 text-white': variant === Variants.Accept,
      'text-white': variant === Variants.Custom,
      'bg-red-600': variant === Variants.Warning,
      'bg-white border-gray-300':
        variant === Variants.Outline || variant === Variants.Secondary,
      'bg-none border-0 shadow-none': variant === Variants.Link,
    }
  );

  const textStyle = classNames({
    'text-white': variant === Variants.Primary || variant === Variants.Warning,
    'text-primary': variant === Variants.Negative || variant === Variants.Link,
    'text-gray-700': variant === Variants.Outline,
    'text-indigo-700': variant === Variants.Secondary,
  });

  const renderAsButton = (child: ReactNode) => {
    return <button className={btnStyle}>{child}</button>;
  };

  const renderAsDiv = (child: ReactNode) => {
    return <div className={btnStyle}>{child}</div>;
  };

  const btnContent = (
    <div className={`inline-flex justify-center items-center ${textStyle}`}>
      {!loading && leftIcon && (
        <Icon name={leftIcon} className={`h-5 w-5 ${textStyle}`} />
      )}
      {loading && (
        <svg
          className={`animate-spin h-5 w-5 ${textStyle}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      <span className="px-2 whitespace-nowrap">{label}</span>
      {render && <span>{render}</span>}
      {rightIcon && (
        <Icon name={rightIcon} className={`h-5 w-5 ${textStyle}`} />
      )}
    </div>
  );

  const renderButton = () => {
    return as === RenderAs.Button
      ? renderAsButton(btnContent)
      : renderAsDiv(btnContent);
  };

  return (
    <div
      className="w-full"
      onClick={disabled || loading ? () => null : onClick}
      data-testid={testId}
    >
      {tooltip ? (
        <Tooltip message={tooltip}>{renderButton()}</Tooltip>
      ) : (
        renderButton()
      )}
    </div>
  );
};

export default Button;
