import Icon from 'components/Icon';
import moment from 'moment';
import React, { useImperativeHandle, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clx from 'classnames';

export type AppProps = {
  name: string;
  validator: any;
  initialValue?: [string, string];
  placeholder?: string;
  className?: string;
  isClearable?: boolean;
  testId?: string;
};

const TimeRange = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      validator,
      initialValue = [null, null],
      placeholder = '',
      className = '',
      isClearable = false,
      testId = null,
    },
    ref
  ) => {
    const validateInputDate = (date?: string | null) => {
      if (date) {
        const _date = moment(date, 'hh:mm A');
        if (_date.isValid()) {
          return new Date(_date.format('YYYY/MM/DD hh:mm a'));
        }
      }
      return null;
    };

    const inputRef = React.useRef<any>(null);
    const [startValue, setStartValue] = useState<any>(
      validateInputDate(initialValue[0])
    );
    const [endValue, setEndValue] = useState<any>(
      validateInputDate(initialValue[1])
    );
    const [error, setError] = useState(null);
    const [skipValidation, setSkipValidation] = useState(false);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: [string, string]) => {
          setStartValue(validateInputDate(v[0]));
          setEndValue(validateInputDate(v[1]));
          setError(null);
        },
        getValue: () => ({
          [name]:
            startValue && endValue
              ? [
                  moment(startValue).format('hh:mm A'),
                  moment(endValue).format('hh:mm A'),
                ]
              : [],
        }),
        skipValidation: (v: boolean) => {
          setSkipValidation(v);
          setError(null);
        },
        getError: () => ({ [name]: error }),
        checkError: () => {
          if (skipValidation || isClearable) {
            return false;
          }
          try {
            validator.validateSync((startValue && endValue) || '');
            return false;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
              return true;
            }
            return false;
          }
        },
      })
    );

    const handleStartValueOnChange = (date: any) => {
      const val = date;
      const endVal = moment(endValue);

      if (moment(val).isBefore(endVal, 'minute') || !endVal.isValid()) {
        setStartValue(val);
        setError(null);
        return;
      }

      setStartValue('');
      try {
        validator.validateSync(startValue || '');
        return false;
      } catch (e: any) {
        // @ts-ignore
        setError('Start time should be earlier than end time');
        return true;
      }
    };

    const handleEndValueOnChange = (date: any) => {
      const val = date;
      const startVal = moment(startValue);

      if (moment(val).isAfter(startVal, 'minute') || !startVal.isValid()) {
        setEndValue(val);
        setError(null);
        return;
      }

      setEndValue('');
      try {
        validator.validateSync(endValue || '');
        return false;
      } catch (e: any) {
        // @ts-ignore
        setError('End time should be later than start time');
        return true;
      }
    };

    const inputStyle = clx(
      'w-full inline-flex space-x-2 items-center justify-start pl-2 py-2 bg-white shadow border rounded-md border-gray-300 outline-none focus-within:border-primary',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <div className={`w-full flex items-center ${className}`}>
          <div className={inputStyle}>
            <Icon name="clock" className="w-5 h-5 text-gray-500 rounded-full" />
            <DatePicker
              ref={inputRef}
              selected={startValue}
              onChangeRaw={(e) => e.preventDefault()}
              onChange={handleStartValueOnChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Pickup Time"
              dateFormat="h:mm aa"
              placeholderText={placeholder}
              isClearable={isClearable || skipValidation}
              className="w-full outline-none focus:outline-none"
            />
          </div>
          <p className="w-8 h-10 text-sm font-medium text-center text-gray-700 pt-2">
            -
          </p>
          <div className={inputStyle}>
            <Icon name="clock" className="w-5 h-5 text-gray-500 rounded-full" />
            <DatePicker
              selected={endValue}
              onChangeRaw={(e) => e.preventDefault()}
              onChange={handleEndValueOnChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Pickup Time"
              dateFormat="h:mm aa"
              placeholderText={placeholder}
              isClearable={isClearable || skipValidation}
              className="w-full outline-none focus:outline-none"
            />
          </div>
        </div>

        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

TimeRange.displayName = 'TimeRange';

export default TimeRange;
