import React from 'react';

import classNames from 'classnames';

import Icon from 'components/Icon';

import checkFormat from '../utils/checkFormat';

// Some input definitions
type InputLabelOrError = string | React.ReactNode;

// Input types and actions
enum AmountRangeInputTypes {
  MIN = 'min',
  MAX = 'max',
}
enum AmountRangeInputActions {
  DEC = 'dec',
  INC = 'inc',
}

// Component props
export type AppProps = {
  name: string;
  validator?: any;
  initialValue?: [string, string];
  prefix?: string;
  isRequestReview: boolean
  label?: InputLabelOrError;
  labels?: [InputLabelOrError, InputLabelOrError];
  placeholders?: [string, string];
  className?: string;
  disabled?: [boolean, boolean];
  showRangeInitial?: boolean;
  onChange?: (min: any, max: any) => void;
};

// Replace rules to pre-process user input
const goodFormat: RegExp[] = [
  // Ensures
  // - Only digits, commas, and a period.
  // - No commas after the period.
  // - No period immediately after a comma.
  // - Shouldn't start with a comma.
  //
  // Allows rules like
  // - End with comma or period.
  // - Less than 3 digits in between commas.
  // Because those are "bad formats" on the user's part and not "bad values".
  // Bad format shall be fixed anyways on `blur` and will not hamper internal processing.
  /^\d+(,\d+)*((\.[0-9]*)|,)?$/,
];
// Min/max rules
const MIN = 1;
const MAX = Number.POSITIVE_INFINITY;
const STEP = 10;
// Misc
const defErr = <>&nbsp;</>;

// Styles for the inputs
const baseStyles =
  'w-full relative text-base leading-normal text-gray-900 outline-none placeholder-gray-500 px-3 py-2 bg-white shadow border rounded-md border-gray-300';
const errorStyles = 'border-error focus:border-red';
const focusStyles = 'focus:border-primary';
const disabledStyles = 'bg-gray-100';

// Some uitlities

const coDependentErrorsStartWith = 'Must be';
enum CoDependentErrorMessageTypes {
  LESS = 'less',
  GREAT = 'greater',
}
const codErrorMessage = (type: CoDependentErrorMessageTypes, partner: string) =>
  `Must be ${type} than ${partner}.`;
const replaceComma = (val = ''): string => (val ? val.replace(/,/g, '') : '');
const getFloat = (val = ''): number => parseFloat(replaceComma(val));
const outputAmountRange = (
  min: string,
  max: string
): { min: number | null; max: number | null } => ({
  min: min !== '' ? getFloat(min) : null,
  max: max !== '' ? getFloat(max) : null,
});

// Value Formatter
const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
});
const format = (val = '' as string | number): string => {
  if (typeof val !== 'string') {
    val = val.toString();
  }

  val = getFloat(val);
  if (isNaN(val)) {
    return '';
  }
  val = formatter.format(val);
  // Add '.00' or '00' to `val` and then ensure there are only 2 digits after decimal by stripping the rest.
  val = `${val}${val.includes('.') ? '' : '.'}00`.replace(
    /(\.[0-9]{0,2})[0-9]*$/,
    '$1'
  );
  return val;
};

const AmountRange = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      validator = null,
      isRequestReview = false,
      initialValue = ['', ''],
      prefix = '$',
      label = '',
      labels = ['', ''],
      placeholders = ['', ''],
      className = '',
      disabled = [false, false],
      showRangeInitial = true,
      onChange = () => null,
    },
    ref
  ) => {
    /** Component states and refs **/
    const [showRange, setShowRange] = React.useState<boolean>(showRangeInitial);
    const minRef = React.useRef<any>(null);
    const maxRef = React.useRef<any>(null);
    const [minValue, setMinValue] = React.useState<string>(
      format(initialValue[0])
    );
    const [maxValue, setMaxValue] = React.useState<string>(
      format(initialValue[1])
    );
    const [minValueError, setMinValueError] =
      React.useState<InputLabelOrError>(defErr);
    const [maxValueError, setMaxValueError] =
      React.useState<InputLabelOrError>(defErr);
    const [isMinDisabled, setIsMinDisabled] = React.useState(disabled[0]);
    const [isMaxDisabled, setIsMaxDisabled] = React.useState(disabled[1]);

    /** Component methods **/

    // Handle change in input value and return appropriate error
    const handleValueChange = (
      e: any,
      type: AmountRangeInputTypes,
      blur = false
    ): InputLabelOrError => {
      let args: { [key: string]: any } = {};
      // Choose args
      switch (type) {
        case AmountRangeInputTypes.MIN:
          args = {
            setValue: setMinValue,
            partner: maxValue,
          };
          break;
        case AmountRangeInputTypes.MAX:
          args = {
            setValue: setMaxValue,
            partner: minValue,
          };
          break;
        default:
          return;
      }

      let val = e.target.value;
      args?.setValue(val); // User MUST see their input in `onchange`
      // !! DO NOT call `setValue` again in this function.
      // If your cursor is not at the end, and you type,
      //   Cursor will abruptly jump to end,
      //   If `setValue` is called with any value other than user's input.
      // - Any forced format changes must be done on `blur` and not on `change`.

      // If empty, stop processing.
      if (val === '') {
        return blur ? 'This is a required field.' : defErr;
      }

      // Pre-process input to remove illegal characters
      const badInput = checkFormat(goodFormat, val);
      if (badInput) return 'Please enter a valid value.';

      // Parse into float, format into proper string, and set input value.
      val = getFloat(val);
      if (blur) {
        args?.setValue(format(val));
      }

      // Probably not needed, but for consistency,
      // Execute default validator
      try {
        validator?.validateSync(val);
      } catch (e: any) {
        return 'Please enter a valid value.';
      }

      // If it is not greater than `min`, set error.
      if (val < MIN) return `Minimum allowed value is ${format(MIN)}.`;
      // If it is not greater than `max`, set error.
      if (val > MAX) return `Maximum allowed value is ${format(MAX)}.`;

      // Sanity check partner before doing this.
      const partnerVal = checkFormat(goodFormat, args?.partner)
        ? NaN
        : getFloat(args?.partner);
      if (!isNaN(partnerVal) && showRange && blur) {
        args.partner = format(partnerVal.toFixed(2));
        if (type === AmountRangeInputTypes.MIN) {
          // If it is not less than `args?.partner`, set error.
          if (val >= partnerVal)
            return codErrorMessage(
              CoDependentErrorMessageTypes.LESS,
              args?.partner
            );
        } else if (type === AmountRangeInputTypes.MAX) {
          // If it is not greater than `args?.partner`, set error.
          if (!isNaN(partnerVal) && val <= partnerVal)
            return codErrorMessage(
              CoDependentErrorMessageTypes.GREAT,
              args?.partner
            );
        }
      }

      return defErr;
    };

    // Dispatch redux type actions for increasing and decreasing input amount
    const dispatchAction = (
      action: AmountRangeInputActions,
      type: AmountRangeInputTypes
    ): void => {
      let args: { [key: string]: any } = {};
      let newMinValue = minValue;
      let newMaxValue = maxValue;
      // Choose args
      switch (type) {
        case AmountRangeInputTypes.MIN:
          args = {
            setValue: setMinValue,
            setError: setMinValueError,
            partner: maxValue,
          };
          break;
        case AmountRangeInputTypes.MAX:
          args = {
            setValue: setMaxValue,
            setError: setMaxValueError,
            partner: minValue,
          };
          break;
        default:
          return;
      }

      args?.setValue((latest: string): string => {
        let newVal = '0.00';
        // Use latest value in state update queue to get new value
        let val = 0;
        // Presume 0 if empty or bad input
        if (latest === '' || checkFormat(goodFormat, latest)) val = 0;
        // Presume 0 if can't convert into number
        val = getFloat(latest);
        if (isNaN(val)) val = 0;
        // Perform action
        switch (action) {
          case AmountRangeInputActions.DEC:
            val -= STEP;
            break;
          case AmountRangeInputActions.INC:
            val += STEP;
            break;
        }
        // Ensure constraints
        if (val < MIN) val = MIN;
        if (val > MAX) val = MAX;

        newVal = format(val);
        args?.setError(
          handleValueChange({ target: { value: newVal } }, type, true)
        );

        // Update the appropriate value (min or max)
        if (type === AmountRangeInputTypes.MIN) {
          newMinValue = newVal;
        } else if (type === AmountRangeInputTypes.MAX) {
          newMaxValue = newVal;
        }
        
        // Call onChange with the updated values
        onChange(newMinValue, newMaxValue);
        return newVal;
      });
    };

    // Render the amount input
    const renderAmountInput = (
      type: AmountRangeInputTypes
    ): React.ReactNode => {
      let args: { [key: string]: any } = {};
      // Choose the args
      switch (type) {
        case AmountRangeInputTypes.MIN:
          args = {
            ref: minRef,
            inputStyle: minInputStyle,
            label: showRange ? labels[0] : label,
            placeholder: placeholders[0],
            value: minValue,
            error: minValueError,
            isDisabled: isMinDisabled,
            onChange: (min: any, max: any): void => onChange(min, max),
            partner: maxValue,
            setValue: setMinValue,
            setError: setMinValueError,
          };
          break;
        case AmountRangeInputTypes.MAX:
          args = {
            ref: maxRef,
            inputStyle: maxInputStyle,
            label: showRange ? labels[1] : label,
            placeholder: placeholders[1],
            value: maxValue,
            error: maxValueError,
            isDisabled: isMaxDisabled,
            onChange: (min: any, max: any): void => onChange(max, min),
            partner: minValue,
            setValue: setMaxValue,
            setError: setMaxValueError,
          };
          break;
        default:
          return <></>;
      }

      return (
        <div className="flex flex-col">
          <p className="text-sm leading-5 font-medium text-gray-700">
            {args?.label}
          </p>
          <div
            className={`flex flex-row items-stretch overflow-hidden text-base leading-6 font-normal text-gray-900 ${args?.inputStyle}`}
          >
            {!isRequestReview && (
              <>
                <div className="flex-shrink-0 absolute inset-y-0 left-3 flex items-center">
                  <Icon
                    name="minus"
                    className="w-4 leading-normal text-gray-500 cursor-pointer"
                    onClick={() =>
                      dispatchAction(AmountRangeInputActions.DEC, type)
                    }
                  />
                </div>
                <div className="flex-shrink-0 absolute inset-y-0 right-3 flex items-center">
                  <Icon
                    name="plus"
                    className="w-4 leading-normal text-gray-500 cursor-pointer"
                    onClick={() =>
                      dispatchAction(AmountRangeInputActions.INC, type)
                    }
                  />
                </div>
              </>
            )}
            {/* text-base/leading-6/font-normal */}
            <div className="flex-shrink-0 absolute inset-y-0 left-10 flex items-center pointer-events-none">
              <span>{prefix}</span>
            </div>
            <input
              autoComplete="never"
              ref={args?.ref}
              type="text"
              placeholder={args?.placeholder}
              className="flex-grow w-full outline-none pl-10 pr-5"
              value={args?.value}
              onChange={(e: any) => {
                args?.onChange(e.target.value, args?.partner);
                args?.setError(handleValueChange(e, type, false));
              }}
              onBlur={(e: any) =>
                args?.setError(handleValueChange(e, type, true))
              }
              disabled={args?.isDisabled}
            />
          </div>
          <div className="error-text">{args?.error}</div>
        </div>
      );
    };

    // Set or reset co-dependent errors
    const handleCoDependentErrors = (): void => {
      // `toString` is needed to ensure `startsWith` exists.
      // Shouldn't hamper performance in most cases due to the first condition.
      const isMinErrorCoDependent =
        minValueError !== defErr &&
        minValueError?.toString().startsWith(coDependentErrorsStartWith);
      const isMaxErrorCoDependent =
        maxValueError !== defErr &&
        maxValueError?.toString().startsWith(coDependentErrorsStartWith);
      // To ensure that this `useEffect` only resets error messages if they are already co-dependent, the above values are used.

      // If showrange is false, co-dependent errors are not needed anymore.
      if (!showRange) {
        if (isMinErrorCoDependent) setMinValueError(defErr);
        return;
      }

      // Try to parse both into float if they pass the format check.
      // If either becomes a NaN, reset both errors if they are co-dependent.
      // Usecase:
      // - If either becomes an invalid number and the inputs' error/s are set in co-dependent state,
      //   - Then it doesn't make sense anymore to show that message as one of the inputs is not a number.
      const min = checkFormat(goodFormat, minValue) ? NaN : getFloat(minValue);
      const max = checkFormat(goodFormat, maxValue) ? NaN : getFloat(maxValue);
      if (isNaN(min) || isNaN(max)) {
        if (isMinErrorCoDependent) setMinValueError(defErr);
        if (isMaxErrorCoDependent) setMaxValueError(defErr);
        return;
      }

      // If min is lesser than max, reset both errors if they are co-dependent
      // Usecase:
      // - Min input's error message was set to be a co-dependent error message on blur.
      //   - Now, if the user modifies the max input such that the error message in min is not needed anymore.
      if (min < max) {
        if (isMinErrorCoDependent) setMinValueError(defErr);
        if (isMaxErrorCoDependent) setMaxValueError(defErr);
        return;
      }

      // If errors need to be set,
      // Set both errors with co - dependent messages, BUT,
      //   ONLY if they were already set to co - dependent messages on last blur.
      //   OR if they are being edited.
      // Usecase:
      // - Min was set to 10, max is empty.
      //   - Max was set to 5 and blur was fired on it.
      //   - At this stage, min is '10.00' and max is '5.00'.
      //     - Error message in min is empty and in max is 'Must be greater than 10.00.'.
      //   - Now if min is changed to '110.00' by pressing `1` after positioning cursor after `1`,
      //     - The below will add proper error message for min as it is the one being edited,
      //       - AND also update error message of max with new value of min.
      const isMinFocussed = minRef?.current === document.activeElement;
      const isMaxFocussed = maxRef?.current === document.activeElement;
      if (isMinErrorCoDependent || isMinFocussed)
        setMinValueError(
          codErrorMessage(
            CoDependentErrorMessageTypes.LESS,
            format(max.toFixed(2))
          )
        );
      if (isMaxErrorCoDependent || isMaxFocussed)
        setMaxValueError(
          codErrorMessage(
            CoDependentErrorMessageTypes.GREAT,
            format(min.toFixed(2))
          )
        );

      // The above checks ensure expected user experience.
      // But, if errors need to be shown and none of the factors we depend on to show error are active,
      // And error still needs to be shown,
      // It should be shown.
      if (
        !(
          isMinErrorCoDependent ||
          isMinFocussed ||
          isMaxErrorCoDependent ||
          isMaxFocussed
        )
      ) {
        setMinValueError(
          codErrorMessage(
            CoDependentErrorMessageTypes.LESS,
            format(max.toFixed(2))
          )
        );
        setMaxValueError(
          codErrorMessage(
            CoDependentErrorMessageTypes.GREAT,
            format(min.toFixed(2))
          )
        );
      }
    };

    /** Component APIs and effects **/

    // Provide APIs to get amd modify input data
    React.useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => minRef?.current?.focus(),
        overrideValue: (v: [string, string]) => {
          setMinValue(format(v[0]));
          setMaxValue(format(v[1]));
          setMinValueError(defErr);
          setMaxValueError(defErr);
        },
        setIsDisabled: (min: boolean, max: boolean): void => {
          setIsMinDisabled(min);
          setIsMaxDisabled(max);
        },
        setShowRange,
        getValue: () => ({
          [name]: outputAmountRange(minValue, maxValue),
        }),
        getError: () => ({
          [name]: { min: minValueError, max: maxValueError },
        }),
        checkError: () => {
          const minError = handleValueChange(
            { target: { value: minValue } },
            AmountRangeInputTypes.MIN,
            true
          );
          setMinValueError(minError);
          const maxError = handleValueChange(
            { target: { value: maxValue } },
            AmountRangeInputTypes.MAX,
            true
          );
          setMaxValueError(maxError);
          return minError !== defErr || maxError !== defErr;
        },
      })
    );

    // Update co-dependent errors when input data changes
    React.useEffect(() => {
      handleCoDependentErrors();
    }, [
      minRef,
      maxRef,
      showRange,
      minValue,
      maxValue,
      minValueError,
      maxValueError,
    ]);

    /** Component rendering **/

    // Compute styles based on latest states
    const minInputStyle = classNames(baseStyles, {
      [errorStyles]: minValueError !== defErr,
      [focusStyles]: minValueError === defErr,
      [disabledStyles]: isMinDisabled,
    });
    const maxInputStyle = classNames(baseStyles, {
      [errorStyles]: maxValueError !== defErr,
      [focusStyles]: maxValueError === defErr,
      [disabledStyles]: isMaxDisabled,
    });

    return (
      <div className={`w-full flex items-center gap-4 ${className}`}>
        {renderAmountInput(AmountRangeInputTypes.MIN)}
        {showRange && renderAmountInput(AmountRangeInputTypes.MAX)}
      </div>
    );
  }
);

AmountRange.displayName = 'AmountRange';

export default AmountRange;
