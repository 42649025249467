import React from 'react';
import NavBar from 'components/Navbar';
import Button, { Variants as ButtonVariants } from 'components/Button';

const FallBack: React.FC = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="flex w-full flex-col justify-center justify-content-center text-center m-auto font-bold ">
      <div className="w-full">
        <NavBar />
      </div>
      <div className="flex w-full justify-center items-center px-8 py-10 flex-col">
        <p className="text-xl">
          Something went wrong. Please refresh the page.
        </p>
        <br></br>
        <div className="w-32">
          <Button
            label="Refresh"
            variant={ButtonVariants.Primary}
            onClick={refreshPage}
          />
        </div>
      </div>
    </div>
  );
};

export default FallBack;
export { default as ErrorHandler } from './ErrorHandller';
