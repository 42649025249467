import React from 'react';
import List from 'components/List';
import Breadcrumb from 'components/Breadcrumb';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import { GET_TOGGLE_EMPLOYEE } from 'queries/toggleManagers';
import useErrorToast from 'hooks/useErrorToast';
type AppProps = {
  testId?: string | null;
};
const ViewAdminInternalUser: React.FC<AppProps> = ({ testId = null }) => {
  const pagePath = [
    { label: 'Internal Team', path: '/internal-user-admin' },
    {
      label: 'View User',
      path: '#',
    },
  ];
  const internalUserId: any = useParams();
  const { data:adminData, loading: fetchingAdminData, error: fetchAdminDataError } = useQuery(GET_TOGGLE_EMPLOYEE,
    {
      variables: { id: internalUserId.id },
      fetchPolicy: 'network-only',
      skip: !internalUserId.id,
    }
  );
  useErrorToast(fetchAdminDataError)

  const internalUserDetailsItems: any = [
    {
      label: 'First Name',
      value: adminData?.getToggleEmployee?.contact?.firstName,
    },
    {
      label: 'Last Name',
      value: adminData?.getToggleEmployee?.contact?.lastName,
    },
    {
      label: 'Email address',
      value: adminData?.getToggleEmployee?.contact?.email,
    },
    {
      label: 'Phone Number',
      value:adminData?.getToggleEmployee?.contact?.phoneNumber,
    },
    {
      label: 'Assigned Role',
      value: "Toggle Admin"
    },
  ];
  if (fetchingAdminData) {
    return <Loader variant={LoaderVariants.Overlay} />;
  }
  return (
    <div className="w-screen h-full bg-gray-100">
      <Breadcrumb
        path={pagePath}
        className="border-gray-200 border-b-1 sticky top-14 z-40"
        testId="togglemanager-accountrequestdetails-breadcrumbs"
      />
      <div className="w-full inline-flex items-center justify-between p-5 bg-white">
        <div>
          <div className="flex items-center">
            <p className="text-3xl font-bold leading-9 text-gray-900">
              Internal User
            </p>
          </div>
        </div>
      </div>
      <div className="shadow bg-white rounded-lg m-5">
        <div className="">
          <div className="">
            <List
              items={internalUserDetailsItems}
              title="View User Info"
            //onEdit={() => { }}
            />
            <div className="absolute top-ranger-icon right-12 filter-search-wrapper">
              {/* <div className=" filter-modal-wrapper">
                <EditInternaUserModal
                  trigger={
                    <Icon
                      className="h-3 w-5 text-primary"
                      name="pencil"
                      onClick={() => {}}
                    />
                  }
                  _id={internalUserId}
                  internalUser={internalUserData?.getInternalUserById}
                  roles={roles}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewAdminInternalUser;