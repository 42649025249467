import React, { useEffect, useImperativeHandle, useState } from 'react';
import { GET_SIGNED_URL } from 'queries/uploadFile';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import clx from 'classnames';
import { FileRejection, useDropzone } from 'react-dropzone';
import Icon from 'components/Icon';
import UploadedFile from './components/UploadedFile';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import { errorToast } from 'components/Toaster';
import { getFileNameFromUrl, getFilePathFromUrl } from 'utils/misc';
import { ADD_OTHER_DOCUMENT } from 'queries/shipment';

export type UploadedFileType = {
  file?: File;
  documentLink?: null;
  error?: string;
};

export type AppProps = {
  name: string;
  fileName?: string;
  initialValue?: UploadedFileType;
  info?: string;
  validator?: any;
  companyName?: string;
  onUploadComplete?: (url: any, docId: any) => any;
  onDelete?: () => void;
  deleteButtonEnable: boolean;
  override?: boolean;
  testId?: string;
  prefix?: string;
  shipmentId?: string
  notUpload?:boolean
};

const FileUpload = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      fileName = '',
      initialValue = null,
      info,
      validator = null,
      onUploadComplete = () => null,
      onDelete = () => null,
      deleteButtonEnable = true,
      companyName = 'global',
      override = false,
      testId = null,
      prefix = '',
      shipmentId = '',
      notUpload,
    },
    ref
  ) => {
    const client = useApolloClient();
    const [uploadedFile, setUploadedFile] = useState<any>({
      ...initialValue,
      // @ts-ignore
      filePath: getFilePathFromUrl(initialValue.documentLink),
    });
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const resetUploadedFile = () => {
      setUploadedFile(null);
    };

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      getValue: () => ({ [name]: uploadedFile }),
      getError: () => ({ [name]: null }),
      checkError: () => {
        if (!validator) return false;
        if (error) {
          return true;
        }
        try {
          validator.validateSync(
            uploadedFile?.documentLink || uploadedFile?.filePath || ''
          );
          return false;
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
            return true;
          }
          return false;
        }
      },
      resetUploadedFile,
    }));


    const uploadToS3 = async (file: any, docId?: any) => {
      const path_arr = file.path.split('.');
      const extn = path_arr[path_arr.length - 1] || 'pdf';
      const _key = fileName
        ? `${fileName}.${extn}`
        : file.path.replace(/\s/g, '-');
      const _companyname = companyName.replace(/ /g, '-');
      const filePath = docId ? `${_companyname}/${docId}/${_key}` : `${_companyname}/${_key} `;
      const { data } = await client.query({
        query: GET_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables: { key: filePath },
      });
      const signedUrl = data?.getUploadURL;

      try {
        // const options = {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //   },
        // };
        const config = {
          onUploadProgress: function (progressEvent: any) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percentCompleted);
          },
          header: {
            'Content-Type': file.type,
          },
        };
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('Content-Type', file.type);
        await axios.put(signedUrl, file, config);
        setUploadedFile({ file, filePath });
        return filePath;
      } catch (error) {
        setError('Failed to upload file');
        setUploadedFile({ file });
        return null;
      }
    };

    const handleUploadFile = async (files: any[], rejectedFiles: any[]) => {
      if(notUpload){
        return errorToast(
          'Unable to upload file. Accessorial Charge request is pending'
        );
      }
      setLoading(true);
      if (override) {
        setUploadedFile(null);
      }
      const fileObj = files[0];
      const errorObj = rejectedFiles[0];
      if (!fileObj || errorObj) {
        setUploadedFile(null);
        setError(errorObj.errors[0]?.message);
        setLoading(false);
        return errorToast(
          'Unable to upload file. Please add single file of supported format'
        );
      }

      if (prefix === 'Add Other Documents') {
        const { data } = await client.mutate({
          mutation: ADD_OTHER_DOCUMENT,
          fetchPolicy: 'network-only',
          variables: { body: { shipment: shipmentId } },
        });
        const signedUrl = await uploadToS3(fileObj.file, data?.addOtherDocument._id);
        const onComplete = onUploadComplete(signedUrl, data?.addOtherDocument._id)
        console.log(onComplete);
        if (onComplete) {
          setUploadedFile(null)
        }
      } else {
        const signedUrl = await uploadToS3(fileObj.file);
        // call parent callback 
        onUploadComplete(signedUrl, undefined);
      }
      setLoading(false);
    };

    const handleFileDelete = () => {
      setUploadedFile(null);
      onDelete();
    };

    const getObjectUrl = (file?: File) => {
      if (!file) {
        return null;
      }
      return URL.createObjectURL(file);
    };

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setError(null);
      handleUploadFile(
        acceptedFiles.map((f) => ({
          file: f,
          documentLink: null,
        })),
        fileRejections.map((file) => ({
          errors: file.errors,
        }))
      );
    };

    const isUploadDisabled = () => {
      if (loading) {
        return true;
      }
      if (override) {
        return false;
      }
      if (uploadedFile?.documentLink || uploadedFile?.file) {
        return true;
      }
      return false;
    };

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: ['.jpg', '.png', '.pdf', '.doc', '.docx'],
      maxSize: 10000000, // 10MB
      multiple: false,
      disabled: isUploadDisabled(),
    });

    const boxStyle = clx(
      'w-full inline-flex flex-col space-y-1 items-center justify-start border-2 border-dashed rounded-md mb-6 cursor-pointer',
      {
        'border-gray-300': !error,
        'border-red-300': error,
        'opacity-50 cursor-not-allowed': isUploadDisabled(),
      }
    );

    const documentLink =
      uploadedFile?.documentLink ||
      (uploadedFile?.file ? getObjectUrl(uploadedFile.file) : null);

    return (
      <div className="w-full" data-testid={testId}>
        {!documentLink && (
          <div className={boxStyle}>
            <div {...getRootProps()} className="w-full h-full p-6">
              <input {...getInputProps()} />
              <div className="flex flex-col justify-center items-center">
                <Icon
                  name="document-add"
                  className="w-12 h-12 text-gray-500 mb-1"
                />
                <div className="mb-1">
                  <span className="text-sm font-medium leading-tight text-primary">
                    Upload a file
                  </span>{' '}
                  <span className="text-sm font-medium leading-tight text-gray-600">
                    or drag and drop
                  </span>
                </div>
              </div>

              <p className="text-xs leading-none text-center text-gray-500">
                {info}
              </p>
            </div>
          </div>
        )}

        {error && <div className="error-text">{error}</div>}
        {loading && <Loader variant={LoaderVariants.Inline} />}
        {!!documentLink && (
          <div className="w-full inline-flex flex-col space-y-1 items-start justify-start">
            <p className="text-sm font-medium leading-tight text-gray-500 mb-1 mt-4">
              Attachments
            </p>

            <UploadedFile
              name={getFileNameFromUrl(uploadedFile?.filePath)}
              documentLink={documentLink}
              onDelete={handleFileDelete}
              deleteButtonEnable={deleteButtonEnable}
              error={error}
            />
          </div>
        )}
      </div>
    );
  }
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;
