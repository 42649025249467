import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

export enum Variants {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export type AppProps = {
  variant?: Variants;
  title: string;
  onClose?: () => void;
  canClose?: boolean;
  className?: string;
  leftIcon?: string;
  testId?: string | null;
};

const Banner: React.FC<AppProps> = ({
  variant = Variants.Success,
  title,
  className = '',
  canClose = true,
  onClose = () => null,
  leftIcon = null,
  testId = null,
}) => {
  const bannerStyle = classNames(
    `w-full space-x-3 p-4 border rounded-md ${className}`,
    {
      'bg-green-50 border-green-500': variant === Variants.Success,
      'bg-yellow-50 border-yellow-500': variant === Variants.Warning,
      'bg-red-50 border-red-500': variant === Variants.Error,
      'bg-blue-50 border-blue-500': variant === Variants.Info,
    }
  );

  const titleStyle = classNames(
    'w-full flex-1 text-sm font-medium leading-tight',
    {
      'text-green-800': variant === Variants.Success,
      'text-yellow-800': variant === Variants.Warning,
      'text-red-800': variant === Variants.Error,
      'text-blue-800': variant === Variants.Info,
    }
  );

  const iconStyle = classNames('h-5 w-5', {
    'text-green-800': variant === Variants.Success,
    'text-yellow-800': variant === Variants.Warning,
    'text-red-800': variant === Variants.Error,
    'text-blue-800': variant === Variants.Info,
  });

  return (
    <div className={bannerStyle} data-testid={testId}>
      <div className="flex space-x-3 items-center justify-start">
        {leftIcon && <Icon name={leftIcon} className={iconStyle} />}
        <p className={titleStyle}>{title}</p>
        {canClose && (
          <div className="w-8 h-1/2 pl-1.5 cursor-pointer" onClick={onClose}>
            <Icon name="x" className={iconStyle} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
export { default as ErrorBanner } from './ErrorBanner';
export { default as WarningBanner } from './WarningBanner';
export { default as InfoBanner } from './InfoBanner';
