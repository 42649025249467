import React from 'react';

import Icon from 'components/Icon';

export type AppProps = {
  onClick: (...args: any[]) => any;
};

export const SignOut: React.FC<AppProps> = ({ onClick }) => (
  <div
    onClick={onClick}
    className="cursor-pointer flex items-center text-left text-gray-900 px-4 py-2 hover:bg-primary hover:text-white"
    data-testid="signout-btn"
  >
    Sign Out
    <Icon name="logout" className="h-5 w-5 ml-2" />
  </div>
);

export default SignOut;
