import React, { useImperativeHandle, useState } from 'react';
import classNames from 'classnames';

export type AppProps = {
  name: string;
  placeholder?: string;
  initialValue?: string;
  validator: any;
  rows?: number;
  disabled?: boolean;
  testId?: string;
  onChange?: (val: any) => void;
};

const TextArea = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      placeholder = '',
      initialValue = '',
      disabled = false,
      rows = 3,
      validator,
      testId = null,
      onChange = () => null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState(initialValue || '');
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => {
          setValue(v);
          setError(null);
        },
        setDisabled,
        getValue: () => ({ [name]: value }),
        getError: () => ({ [name]: error }),
        checkError: () => {
          try {
            validator.validateSync(value);
            return false;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
            }
            return true;
          }
        },
      })
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const v = e.target.value;
      setValue(v);
      onChange(v);
      try {
        validator.validateSync(v);
        setError(null);
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          setError(e.errors[0]);
        }
      }
    };

    const inputStyle = classNames(
      'w-full text-base leading-normal text-gray-900 outline-none placeholder-gray-400 px-3 py-2 bg-white shadow border rounded-md border-gray-300',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
        'bg-gray-100': isDisabled,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <textarea
          ref={inputRef}
          className={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          disabled={isDisabled}
          rows={rows}
        />
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
