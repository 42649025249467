import { gql } from '@apollo/client';
import { fragments as miscFragments } from './miscFragments';
import { internalUserFragments } from './miscFragments';
import { fragments as driverFragments } from './driver';
import { fragments as toggleManagerFragments } from './toggleManagers';

export const fragments = {
  carrierBasicInfo: gql`
    fragment CarrierBasicInfoFragment on Carrier {
      _id
      companyName
      nameOfDBA
      mcNumber
      dotNumber
      onboardingStatus
      primaryContacts {
        ...InternalUserContactFragment
      }
      createdAt
      updatedAt
    }
    ${internalUserFragments.internalUserContact}
  `,
  carrierInfo: gql`
    fragment CarrierFragment on Carrier {
      _id
      EIN
      companyName
      onboardingStatus
      isBillingAddressSameAsCompany
      onboardingStep {
        profileInformation
        freightFactoring
        driverDetails
        references
      }
      accountingContact {
        ...ContactFragment
      }
      companyAddress {
        ...AddressFragment
      }
      billingAddress {
        ...AddressFragment
      }
      primaryContacts {
        ...InternalUserContactFragment
      }
      nameOfDBA
      dotNumber
      drivers {
        ...DriverFragment
      }
      isDoubleTripleTrailer
      isHazardousMaterial
      isTank
      expirationDate
      factoring {
        companyAddress {
          ...AddressFragment
        }
        companyName
        expirationDate
        noticeOfAssignment
        primaryContact {
          ...ContactFragment
        }
      }
      insuranceAddress {
        ...AddressFragment
      }
      insuranceCarrier
      insuranceContact {
        ...ContactFragment
      }
      mcNumber
      notes
      policyNumber
      primaryContacts {
        ...InternalUserContactFragment
      }
      proofOfInsuranceLink
      references {
        ...ContactFragment
      }
      timezone
      timezoneCtry
      timezoneFormat
      toggleManager {
        ...ToggleManagerFragment
      }
      valueOfCargoInsurance
      w9Link
      createdAt
      updatedAt
    }
    ${miscFragments.address}
    ${miscFragments.contact}
    ${internalUserFragments.internalUserContact}
    ${driverFragments.driver}
    ${toggleManagerFragments.toggleManager}
  `,
  onBoardCarrierInfo: gql`
    fragment CarrierFragment on Carrier {
      _id
      EIN
      companyName
      onboardingStatus
      isBillingAddressSameAsCompany
      onboardingStep {
        profileInformation
        freightFactoring
        references
      }
      accountingContact {
        ...ContactFragment
      }
      companyAddress {
        ...AddressFragment
      }
      billingAddress {
        ...AddressFragment
      }
      primaryContacts {
        ...InternalUserContactFragment
      }
      nameOfDBA
      dotNumber
      drivers {
        ...DriverFragment
      }
      isDoubleTripleTrailer
      isHazardousMaterial
      isTank
      expirationDate
      factoring {
        companyAddress {
          ...AddressFragment
        }
        companyName
        expirationDate
        noticeOfAssignment
        primaryContact {
          ...ContactFragment
        }
      }
      insuranceAddress {
        ...AddressFragment
      }
      insuranceCarrier
      insuranceContact {
        ...ContactFragment
      }
      mcNumber
      notes
      policyNumber
      primaryContacts {
        ...InternalUserContactFragment
      }
      proofOfInsuranceLink
      references {
        ...ContactFragment
      }
      timezone
      timezoneCtry
      timezoneFormat
      toggleManager {
        ...ToggleManagerFragment
      }
      valueOfCargoInsurance
      w9Link
      createdAt
      updatedAt
    }
    ${miscFragments.address}
    ${miscFragments.contact}
    ${internalUserFragments.internalUserContact}
    ${driverFragments.driver}
    ${toggleManagerFragments.toggleManager}
  `,
};

export const GET_CARRIER_BASIC_INFO = gql`
  query GetCarrierBasicInfo($companyName: String!) {
    getCarrierByCompanyName(companyName: $companyName) {
      ...CarrierBasicInfoFragment
    }
  }
  ${fragments.carrierBasicInfo}
`;

export const GET_CARRIER_BY_COMPANY = gql`
  query GetCarrierByCompany($companyName: String!) {
    getCarrierByCompanyName(companyName: $companyName) {
      ...CarrierFragment
    }
  }
  ${fragments.onBoardCarrierInfo}
`;

export const CREATE_CARRIER = gql`
  mutation CreateCarrier($body: CarrierInput!) {
    createCarrier(createCarrierInput: $body) {
      ...CarrierFragment
    }
  }
  ${fragments.onBoardCarrierInfo}
`;

export const UPDATE_CARRIER = gql`
  mutation UpdateCarrier($body: UpdateCarrierInput!, $stage: OnboardingStage!) {
    updateCarrier(onboardingStage: $stage, updateCarrierInput: $body) {
      ...CarrierFragment
    }
  }
  ${fragments.onBoardCarrierInfo}
`;

export const CREATE_SELF_DRIVER = gql`
  mutation CreateSelfDriver($carrierId: String!){
    createSelfDriver(carrierId: $carrierId){
      ...DriverFragment
    }
  }
  ${driverFragments.driver}
`

export const GET_CARRIER_BY_ID = gql`
  query GetCarrierById($id: String!) {
    getCarrierById(id: $id) {
      ...CarrierFragment
    }
  }
  ${fragments.onBoardCarrierInfo}
`;

export const GET_DRIVER_BY_CARRIER_COMPANY_NAME = gql`
  query GetDriverByCarrierCompanyName($companyName: String!) {
    getCarrierByCompanyName(companyName: $companyName) {
      _id
      drivers {
        ...DriverFragment
      }
    }
  }
  ${driverFragments.driver}
`;

export const GET_MOBILE_ACCESS_DRIVERS = gql`
  query GetMobileAccessDrivers($carrierId: String!) {
    getMobileAccessDrivers(carrierId: $carrierId) {
      ...DriverFragment
    }
  }
  ${driverFragments.driver}
`;
