import React from 'react';

import { titleCase } from 'utils/misc';

export type AppProps = {
  userEmail: string;
  userRole: string;
};

export const UserInfo: React.FC<AppProps> = ({ userEmail, userRole }) => (
  <>
    <p className="w-80 px-4 py-2 pointer-events-none">
      <span>Signed in as</span>
      <br />
      <span className="font-medium">{userEmail}</span>
      <br />
      <span className="font-bold">
        {titleCase(userRole).replace(/driver/i, '')}
      </span>
    </p>
  </>
);

export default UserInfo;
