import React from 'react';
// @ts-ignore
import { ReactComponent as BlackLogo } from '../../images/logo_black.svg';
// @ts-ignore
import { ReactComponent as WhiteLogo } from '../../images/logo_white.svg';

export enum Colors {
  White = 'white',
  Black = 'black',
}

export type AppProps = {
  color?: Colors;
  testId?: string | null;
};

const Logo: React.FC<AppProps> = ({ color = Colors.Black }) => {
  if (color === Colors.White) {
    return <WhiteLogo />;
  }
  return <BlackLogo />;
};

export default Logo;
