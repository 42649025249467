import Tag from 'components/Tag';
import React from 'react';
import {
  ShipmentStatusColor,
  ShipmentStatusBgColor,
  ShipmentStatusLabel,
} from 'utils/constant';

type AppProps = {
  value: string;
  testId?: string | null;
};

const ShipmentStatusTag: React.FC<AppProps> = ({ value, testId = null }) => {
  return (
    <Tag
      textColor={ShipmentStatusColor[value]}
      bgColor={ShipmentStatusBgColor[value]}
      label={ShipmentStatusLabel[value]}
      testId={testId}
    />
  );
};

export default ShipmentStatusTag;
