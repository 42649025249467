import React, { useState, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from 'components/Icon';
import { dateFromNow, unixTimestampToDate } from 'utils/time';
import classNames from 'classnames';

export type AppProps = {
  name: string;
  initialValue?: string | number;
  validator: any;
  allowPast?: boolean;
  allowToday?: boolean;
  testId?: string;
  minDateInput?: string;
};

const convertEpochToDate = (val?: string | number | null) => {
  if (!val) {
    return null;
  }
  if (typeof val === 'number' || !isNaN(Number(val))) {
    return unixTimestampToDate(val);
  }
  return new Date();
};

const TDatePicker = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      initialValue = null,
      validator,
      allowPast = true,
      allowToday = false,
      testId = null,
      minDateInput,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [date, setDate] = useState<Date | null>(
      convertEpochToDate(initialValue)
    );
    const [error, setError] = useState(null);

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      name,
      focus: () => inputRef?.current?.focus?.(),
      overrideValue: (v: string) => {
        setDate(convertEpochToDate(v));
        setError(null);
      },
      getValue: () => ({ [name]: date?.toLocaleDateString('en-US') }),
      getError: () => ({ [name]: null }),
      checkError: () => {
        try {
          validator.validateSync(date);
          return false;
        } catch (e: any) {
          //@ts-ignore
          setError('Please enter a valid value');
          return true;
        }
      },
    }));

    const onChange = (date: Date) => {
      const val = date;

      setDate(date);
      setError(null);

      try {
        if (!val) {
          // @ts-ignore
          setError('This is a required field');
        }
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          // @ts-ignore
          setError('This is a required field');
        }
      }
    };

    const inputStyle = classNames(
      'w-full inline-flex space-x-2 items-center justify-start pl-2 py-2 bg-white shadow border rounded-md border-gray-300 outline-none',
      {
        'border-error focus:border-red': error,
        'focus-within:border-primary': !error,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <div className={inputStyle} tabIndex={0} ref={inputRef}>
          <Icon name="calendar" className="w-5 h-5 text-base text-gray-500" />
          <DatePicker
            selected={date}
            onChange={onChange}
            placeholderText="mm/dd/yyyy"
            dateFormat="MM/dd/yyyy"
            className="w-full outline-none focus:outline-none"
            minDate={
              minDateInput
                ? new Date(parseInt(minDateInput))
                : allowPast
                  ? null
                  : allowToday
                    ? new Date()
                    : dateFromNow(1, 'day')
            }
            shouldCloseOnSelect
          />
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

TDatePicker.displayName = 'DatePickerInput';

export default TDatePicker;
