import React, { useRef } from 'react';
import Modal from 'components/Modal';
import Button, { Variants as ButtonVariants } from 'components/Button';
import { useQuery } from '@apollo/client';
import { GET_ALL_SHIPPERS } from 'queries/shipper';
import { InputType } from 'components/Input/utils';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import Form from 'components/Form';
import useAuth from 'hooks/useAuth';
import { Roles } from 'utils/enum';

type AppProps = {
  trigger: React.ReactElement;
  testId?: string | null;
};

const ShipperSelectorModal: React.FC<AppProps> = ({
  trigger,
  testId = null,
}) => {
  const { setImpersonateData } = useAuth();

  const { data, loading } = useQuery(GET_ALL_SHIPPERS, {
    fetchPolicy: 'network-only',
  });
  const formRef = useRef(null);

  if (loading) {
    return <Loader variant={LoaderVariants.Inline} />;
  }

  const handleSubmit = () => {
    if (formRef.current) {
      // @ts-ignore
      if (!formRef.current.hasErrors()) {
        // @ts-ignore
        const { shipper } = formRef.current.getValues();
        setImpersonateData(Roles.Shipper, shipper);
        location.href = '/';
      }
    }
  };

  const shipperForm = [
    {
      label: '',
      key: 'shipper',
      fields: [
        {
          key: 'shipper',
          label: 'Select Shipper to impersonate',
          description: 'Your active profile will change to this shipper',
          type: InputType.Autocomplete,
          options: data?.shippers?.sort((a: any, b: any) => a?.companyName.localeCompare(b?.companyName))
          ?.map((shipper: any) => ({
            label: shipper.companyName,
            value: shipper.companyName,
          })),
          placeholder: 'Select shipper',
          validations: [
            {
              type: 'required',
              params: ['This is a required field'],
            },
          ],
          testId: { testId },
        },
      ],
    },
  ];

  const renderContent = () => {
    return (
      <div className="h-72 w-full py-2" data-testid={testId}>
        <Form key="shipper" config={shipperForm} ref={formRef} />
      </div>
    );
  };

  const renderFooter = (hideModal: () => void) => {
    return (
      <div className="w-full flex justify-between items-center">
        <div>
          <Button
            variant={ButtonVariants.Outline}
            label="Cancel"
            onClick={hideModal}
            disabled={loading}
            testId="shipper-impersonate-cancel-btn"
          />
        </div>
        <div>
          <Button
            variant={ButtonVariants.Primary}
            label="Impersonate"
            disabled={loading || !data?.shippers}
            onClick={() => handleSubmit()}
            testId="shipper-impersonate-btn"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Impersonate Shipper"
      className="w-3/5"
      trigger={trigger}
      content={renderContent}
      footer={renderFooter}
      testId={testId}
    />
  );
};

export default ShipperSelectorModal;
