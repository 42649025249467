import React from 'react';

import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import Logo, { Colors } from 'components/Logo';
import Avatar from 'components/Avatar';
import Menu from 'components/Menu';
import ShipperSelectorModal from './ShipperSelectorModal';
import UserInfo from './UserInfo';
import SignOut from './SignOut';

import useAuth from 'hooks/useAuth';
import Icon from 'components/Icon';
import { GET_NOTIFICATION_COUNT_BY_USER, UPDATE_NOTIIFCATION_STATUS } from 'queries/notifications';
import { useMutation, useQuery } from '@apollo/client';

type AppProps = {
  testId?: string | null;
};
const Navbar: React.FC<AppProps> = ({ testId = null }) => {
  const {
    // @ts-ignore
    accountInfo: { userId, name, email, role },
    resetAuthInfo,
    // setAccountInfo,
  } = useAuth();

  const activeLinkStyle = (isActive = false) =>
    classNames(
      'px-3 py-2 rounded-md mr-2 text-sm font-medium leading-tight text-white whitespace-nowrap',
      {
        'bg-indigo-700 ': isActive,
      }
    );

    const { data: countValue, refetch: refetchNotificationCount } = useQuery(GET_NOTIFICATION_COUNT_BY_USER, {
      variables: {
        userId: userId, userType: 'TOGGLE'
      },
      skip: !userId,
      fetchPolicy: 'network-only',
    });

    const [updateNotificationStatus,] = useMutation(
      UPDATE_NOTIIFCATION_STATUS,
      {
        onCompleted: refetchNotificationCount
      }
    );
  
    const handleNotificationCount = async (userId: string, userType: string) => {
      try {
        await updateNotificationStatus({
          variables: {
            userId,
            userType
          },
        });
      } catch (error: any) {
        console.log(error.message);
  
      }
    }
  const items: any = !userId ?
    [{
      renderNode: (): React.ReactNode => (

        <NavLink to="/internal-user-admin">
          <p className='text-primary px-4'>User Administration</p>
        </NavLink>

      )
    },
    ] : [];
  return (
    <div
      className="w-full inline-flex space-x-80 items-center justify-between px-5 py-3.5 bg-primary sticky top-0 z-50"
      data-testid={testId}
    >
      <div className="flex items-center">
        <div className="w-36 h-5 mr-16">
          <Logo color={Colors.White} />
        </div>
        <NavLink
          to="/account-requests"
          className={activeLinkStyle}
          data-testid="togglemanager-dashboard-tab"
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/load-board"
          className={activeLinkStyle}
          data-testid="togglemanager-loardboard-tab"
        >
          Load Board
        </NavLink>
        <NavLink
          to="/shipments"
          className={activeLinkStyle}
          data-testid="togglemanager-shipments-tab"
        >
          Shipments
        </NavLink>
        <NavLink
          to="/reports"
          className={activeLinkStyle}
          data-testid="togglemanager-reports-tab"
        >
          Reports
        </NavLink>
      </div>
      <div className="flex space-x-2 items-center justify-center">
        {/* <div className="flex items-center justify-center p-1 rounded-full">
          <Icon name="bell" className="h-6 w-6 text-white rounded-full" />
        </div> */}
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-start px-3">
            {userId ? (
              <NavLink to='/notifications' onClick={() => { handleNotificationCount(userId, 'TOGGLE') }} className={activeLinkStyle}>
                <div className="relative">
                  {(countValue?.getNotificationCountByUser > 0) &&
                    <p className='bg-red-500 text-white rounded-full w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1 flex items-center justify-center text-xs'>
                      {countValue?.getNotificationCountByUser}
                    </p>
                  }
                  <Icon name="bell" className="h-7 w-7 text-white cursor-pointer" />
                </div>
              </NavLink>
            ) : null}
            <p className="text-sm font-medium leading-tight text-right text-white whitespace-nowrap">
              Welcome, {name}
            </p>
          </div>
          <Menu
            trigger={
              <Avatar className="cursor-pointer" name={name} testId="avatar" />
            }
            items={[
              {
                renderNode: (): React.ReactNode => (
                  <UserInfo userEmail={email} userRole={role} />
                ),
              },
              {
                label: 'Impersonate Shipper',
                renderModal: (ref: any) => (
                  <ShipperSelectorModal trigger={<div ref={ref} />} />
                ),
              },
              ...items,
              {
                renderNode: (): React.ReactNode => (
                  <SignOut onClick={resetAuthInfo} />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
