import Table, { ColumnType } from 'components/Table1';
import React from 'react';
import Icon from 'components/Icon';
import { NavLink, useHistory } from 'react-router-dom';
import Button, { Variants } from 'components/Button';
import EditInternaUserModal from './EditInternalUser';
import { useMutation, useQuery } from '@apollo/client';
import useErrorToast from 'hooks/useErrorToast';
import { successToast } from 'components/Toaster';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import useAuth from 'hooks/useAuth';
import { ASSIGN_MANAGER } from 'queries/accountRequests';
import { GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID, REMOVE_ADMIN_BY_ID } from 'queries/toggleManagers';
import CreateAdminInternalUser from './CreateAdminInternalUser';
import DeleteAdminInternalUser from './DeleteAdminInternalUser';
const AdminInternalUser: React.FC = () => {
  const { accountInfo: { email } }: any = useAuth();
  const history = useHistory();
  const [removeAdminById, { loading:removal, error: removalError }] = useMutation(REMOVE_ADMIN_BY_ID, 
    {
    refetchQueries: [
      {
        query: GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID,
        variables: { email: email },
        fetchPolicy: 'network-only',
        },
    ],
  }
)
    const { data: parentAdminData, loading: fetchingParentAdminData, error: fetchParentAdminDataError } = useQuery(GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID,
    {
      variables: { email: email },
      fetchPolicy: 'network-only',
      skip: !email,
    }
  );
  useErrorToast( fetchParentAdminDataError||removalError)
  const handleDeleteUser = async (id: string): Promise<any> => {
    try {
      await removeAdminById({ variables: { id: id } });
      successToast('Shipper removed Successfully');
      history.go(0);
    } catch (e) { }
  };
  const columns = [
    {
      Header: 'NAME',
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        if (!original.contact) {
          return <></>;
        }
        const firstName = original?.contact?.firstName
        const lastName = original?.contact?.lastName
        return (
          <NavLink to={`/internal-user-admin-view/${original?._id}`}>
            {firstName} {lastName}{' '}
          </NavLink>
        );
      },
    },
    {
      Header: 'EMAIL',
      accessor: 'contact.email',
      type: ColumnType.Text,
    },
    {
      Header: 'PHONE NUMBER',
      accessor: 'contact.phoneNumber',
      // disableSortBy: true,
      type: ColumnType.Text,
    },
    {
      Header: 'ADDED DATE',
      accessor: 'createdAt',
      type: ColumnType.Date,
    },
    {
      Header: 'ASSIGNED ROLE',
      disableSortBy: true,
      Cell: () =>  <span className="font-medium">Toggle Admin</span>
    },
    {
      Header: 'Actions',
      disableSortBy: true,
      Cell: ({ row }: { row: any }) => {
        if (!row.original._id) {
          return <></>;
        }
        return (
          <div className="flex items-center">
            <EditInternaUserModal
              trigger={
                <div>
                  <Icon
                    name="edit"
                    className="cursor-pointer text-primary h-5 w-5 mr-2"
                  />
                </div>
              }
              _id={row.original._id}
              internalUser={{
                ...row.original.contact,
                role: "TOGGLE ADMIN"
              }}
              roles={{
                getAllRoles: [{
                  __typename: "Role",
                  roleDesc: "Toggle  Admin",
                  roleId: 'TOGGLE ADMIN',
                }]
              }}
            />
            {
              row.original.linked ? (<DeleteAdminInternalUser
                title="Delete Toggle Admin"
                linked={row.original.linked}
                onConfirm={() => handleDeleteUser(row.original._id)}
              />) : (<DeleteAdminInternalUser
                title="Delete Toggle Admin"
                linked={row.original.linked}
                onConfirm={() => handleDeleteUser(row.original._id)}
              />)
            }

          </div>
        );
      },
      idAccessor: '_id',
    }
  ];
  if ( removal || fetchingParentAdminData) {
    return <Loader variant={LoaderVariants.Overlay} />;
  }
  return (
    <div className="w-screen h-full bg-gray-100">
      <div className="w-full inline-flex flex-col items-start justify-start p-5 bg-white border-gray-200 border-b-1">
        <p className="text-3xl font-bold leading-9 text-gray-900">
         Toggle Internal Team
        </p>
      </div>
      <div className="shadow bg-white rounded-lg m-5">
        <div className="">
          <div className="absolute top-ranger right-12 filter-search-wrapper">
            <div>
            </div>
            <CreateAdminInternalUser trigger={<Button
              label="Add New"
              variant={Variants.Primary}
              leftIcon="plus"
              testId="admin-loadboard-addload-btn" />} 
              roles={{
                getAllRoles: [{
                  __typename: "Role",
                  roleDesc: "Toggle Admin",
                  roleId: 'TOGGLE ADMIN',
                }]
              }}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataFetcher={{
            query: GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID,
            variables: {
              email: email,
              skip: !email,
            },
            listAccessor: 'getToggleEmployeesByEmailId.adminData',
            pageAccessor: 'getToggleEmployeesByEmailId.info',
          }}
          statsFetcher={{ variables: { email: email } }}
          search={true}
        />
      </div>
    </div >
  );
};
export default AdminInternalUser;