import { gql } from '@apollo/client';
import { fragments as miscFragments, locationFragments } from './miscFragments';
import { fragments as lookupFragments } from './lookup';

export const fragments = {
  location: gql`
    fragment LocationFragment on LocationDetail {
      _id
      appointmentTime
      contact {
        ...ContactFragment
      }
      customerReference
      deliveryReference
      documentLink
      documentUpdatedBy
      expectedAccessorialCharges {
        ...LookupAccessorialFragment
      }
      freightCountedBy
      isAppointmentRequired
      loadedBy
      location {
        ...PickupDropFragment
      }
      locationType
      locationOrder
      nameOfDBA
      notes
      orgName
      plannedUnloadTime {
        from
        to
      }
      shipment
      signeeLastName
      unloadMethod {
        ...LookupFragment
      }
      visitDate
      visitTimeFrom
      visitTimeTo
      originalId
    }
    ${miscFragments.address}
    ${miscFragments.contact}
    ${locationFragments.pickupDrop}
    ${lookupFragments.lookup}
    ${lookupFragments.lookupAccessorial}
  `,
};

export const DELETE_LOCATION_DETAIL = gql`
  mutation DeleteLocationDetail($id: String!, $shipmentId: String!) {
    removeLocationDetail(id: $id, shipmentId: $shipmentId) {
      ...LocationFragment
    }
  }
  ${fragments.location}
`;

export const UPDATE_LOCATION_DETAIL_DOCUMENT_LINK = gql`
  mutation updateLocationDetailDocumentLink(
    $body: UpdateLocationDetailDocumentInput!
  ) {
    updateLocationDetailDocumentLink(updateLocationDetailDocumentInput: $body) {
      _id
    }
  }
`;
