import { gql } from '@apollo/client';

export const fragments: any = {
  notifications: gql`
    fragment NotificationFragment on Notification {
      content
      createdAt
      read
      updatedAt
      user_id
      user_type
      _id
    }
  `,
  preferences: gql`
    fragment NotificationPreferenceFragment on NotificationPreferences {
      name
      enable
      inapp
      sms
      email
    }
  `,
  shipperPreferences: gql`
    fragment NotificationPreferenceFragment on NotificationPreferencesForShipper {
      name
      enable
      inapp
      sms
      email
    }
  `,
  managerPreferences: gql`
    fragment NotificationPreferenceFragment on NotificationPreferencesForManager {
      name
      enable
      inapp
      sms
      email
    }
  `
};

export const GET_NOTIFICATIONS = gql`
  query GetNotifications(
    $id: String!
    $limit: Int
    $offset: Int
    $search: String
    $sortBy: String
    $sortingOrder: SortingOrder
  ) {
    getNotifications(
      id: $id
      limit: $limit
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortingOrder: $sortingOrder
    ) {
      info {
        total
      }
      notifications {
        ...NotificationFragment
      }
    }
  }
  ${fragments.notifications}
`;

export const GET_NOTIFICATION_COUNT_BY_USER = gql`
  query GetNotificationCountByUser($userId: String!, $userType: String!) {
    getNotificationCountByUser(userId: $userId, userType: $userType)
  }
`;

export const GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferenceByCarrier($carrierId: String!){
    getNotificationPreferenceByCarrier(carrierId: $carrierId){
      ...NotificationPreferenceFragment
    }
  }
  ${fragments.preferences}
`;

export const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation UpdateNotificationPreference($body: NotificationPreferenceInput!){
    updateNotificationPreference(updateData: $body){
      ...NotificationPreferenceFragment
    }
  }
  ${fragments.preferences}
`;

export const GET_NOTIFICATION_PREFERENCES_FOR_SHIPPER = gql`
  query GetNotificationPreferenceByShipper($shipperId: String!){
    getNotificationPreferenceByShipper(shipperId: $shipperId){
      ...NotificationPreferenceFragment
    }
  }
  ${fragments.shipperPreferences}
`;

export const UPDATE_NOTIFICATION_PREFERENCE_FOR_SHIPPER = gql`
  mutation UpdateNotificationPreferenceForShipper($body: NotificationPreferenceInputForShipper!){
    updateNotificationPreferenceForShipper(updateData: $body){
      ...NotificationPreferenceFragment
    }
  }
  ${fragments.shipperPreferences}
`;

export const GET_NOTIFICATION_PREFERENCES_FOR_MANAGER = gql`
  query GetNotificationPreferenceByManager($managerId: String!){
    getNotificationPreferenceByManager(managerId: $managerId){
      ...NotificationPreferenceFragment
    }
  }
  ${fragments.managerPreferences}
`;

export const UPDATE_NOTIFICATION_PREFERENCE_FOR_MANAGER = gql`
  mutation UpdateNotificationPreferenceForManager($body: NotificationPreferenceInputForManager!){
    updateNotificationPreferenceForManager(updateData: $body){
      ...NotificationPreferenceFragment
    }
  }
  ${fragments.managerPreferences}
`;

export const UPDATE_NOTIIFCATION_STATUS = gql`
  mutation UpdateNotificationStatus($userId: String!, $userType: String!) {
    updateNotificationStatus(userId: $userId, userType: $userType)
  }
`

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: String!) {
    deleteNotification(id: $id) {
      ...NotificationFragment
    }
  }
  ${fragments.notifications}
`;

