import React, { useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import './css/password.css';

export type AppProps = {
  name: string;
  placeholder?: string;
  validator: any;
  testId?: string;
};

const Password = React.forwardRef<HTMLElement, AppProps>(
  ({ name, placeholder = '', validator, testId = null }, ref) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleTogglePassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      name,
      focus: () => inputRef?.current?.focus?.(),
      getValue: () => ({ [name]: value }),
      getError: () => ({ [name]: error }),
      checkError: () => {
        try {
          validator.validateSync(value);
          return false;
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
          }
          return true;
        }
      },
    }));

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      const _v = (v || '').trim();

      setValue(_v);
      try {
        validator.validateSync(_v);
        setError(null);
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          setError(e.errors[0]);
        }
      }
    };

    const inputStyle = classNames(
      'password-input w-full text-base leading-normal text-gray-900 outline-none placeholder-gray-400 px-3 py-2 bg-white shadow border rounded-md border-gray-300',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <div
          className='cont flex items-center password-container'
        >
          <input
            autoComplete="never"
            ref={inputRef}
            className={inputStyle}
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={handleOnChange}
          />
          <div className='mx-1 password-toggle-btn'>
            <Icon name={showPassword ? 'eye' : 'eyeOff'} onClick={handleTogglePassword} className="w-5 h-5 text-primary" />
          </div>
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

Password.displayName = 'Password';

export default Password;
