import React, { useState } from 'react';
import Modal from '../Modal';
import Icon from '../Icon';
import Button, { Variants as ButtonVariants } from '../Button';

export enum Variants {
  Primary = 'primary',
  Warning = 'warning',
}

export type AppProps = {
  trigger?: React.ReactElement;
  title: string;
  message: string;
  loading?: boolean;
  onConfirm: (hideModal: () => void) => Promise<any>;
  confirmLabel?: string;
  onCancel?: (hideModal: () => void) => void;
  showIcon?: boolean;
  variant?: Variants;
  form?: React.ReactElement;
  className?: string;
  autoClose?: boolean;
  testId?: string | null;
};

const ConfirmationBox: React.FC<AppProps> = ({
  title,
  trigger = null,
  message,
  onConfirm,
  confirmLabel = 'Proceed',
  onCancel = null,
  showIcon = true,
  variant = Variants.Warning,
  form = null,
  autoClose = true,
  className = '',
  testId = null,
}) => {
  const [loading, setLoading] = useState(false);
  const renderContent = (hideModal: () => void) => {
    return (
      <div
        className="w-full inline-flex space-x-4 items-start justify-start bg-white pt-8 pb-2 px-4"
        data-testid={testId}
      >
        {showIcon ? (
          <div>
            <div className="flex items-center justify-center w-10 h-10 p-2 bg-red-100 rounded-full">
              <Icon name="ban" className="text-red-600" />
            </div>
          </div>
        ) : null}

        <div className="inline-flex flex-col space-y-4 items-start justify-start">
          <div className="flex flex-col space-y-2 items-start justify-start">
            <p className="text-lg font-medium leading-normal text-gray-900">
              {title}
            </p>
            <p className="text-base leading-tight text-gray-500">{message}</p>
          </div>
          {form}
          <div className="w-full inline-flex space-x-3 pt-2 items-center justify-end">
            <div>
              <Button
                variant={ButtonVariants.Outline}
                label="Cancel"
                onClick={() => {
                  if (onCancel) onCancel(hideModal);
                  if (autoClose) {
                    hideModal();
                  }
                }}
                disabled={loading}
                testId={testId + '-cancel-btn'}
              />
            </div>

            <div>
              <Button
                variant={variant}
                label={confirmLabel}
                onClick={async () => {
                  setLoading(true);
                  await onConfirm(hideModal);
                  setLoading(false);
                  if (autoClose) {
                    hideModal();
                  }
                }}
                loading={loading}
                testId={testId + '-' + confirmLabel.toLowerCase() + '-btn'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className={`"w-1/3" ${className}`}
      trigger={
        trigger || (
          <div>
            <Icon
              name="delete"
              className="cursor-pointer text-red-600 h-5 w-5"
            />
          </div>
        )
      }
      content={renderContent}
      testId={testId}
    />
  );
};

export default ConfirmationBox;
