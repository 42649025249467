import React, { useImperativeHandle, useState } from 'react';

export type RadioProps = {
  name: string;
  options: Array<{ label: string; value: string | number }>;
  initialValue?: string | number;
  disabled?: boolean;
  onChange?: (v: string | number) => void;
  testId?: string;
};

const Radio = React.forwardRef<HTMLElement, RadioProps>(
  (
    {
      name,
      options,
      initialValue = '',
      disabled = false,
      onChange = () => null,
      testId = null,
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState(initialValue);
    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        // @ts-ignore
        focus: () => document.querySelector(`input[name="${name}"]:checked`)?.focus?.(),
        overrideValue: (v: string | number) => setSelectedValue(v),
        getValue: () => ({ [name]: selectedValue }),
        getError: () => ({ [name]: null }),
        checkError: () => false,
      })
    );

    const handleChange = (value: string | number) => {
      onChange(value);
      setSelectedValue(value);
    };

    return (
      <div className="radio-group" data-testid={testId}>
        {options.map((option) => (
          <div className='mb-3' key={option.value}>
            <label
              className={`inline-flex items-center justify-between cursor-pointer ${
                disabled ? 'opacity-50' : ''
              }`}
            >
              <input
                type="radio"
                name={name}
                value={option.value}
                disabled={disabled}
                checked={selectedValue === option.value}
                onChange={() => handleChange(option.value)}
                className="w-4 h-4 rounded-full outline-none focus:border-primary cursor-pointer"
              />
              <p className="px-2 text-sm leading-tight text-gray-900">{option.label}</p>
            </label>
          </div>
        ))}
      </div>
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
