import React from 'react';
import Tooltip from 'components/Tooltip';

type AppProps = {
  from: string;
  to?: string;
  warning?: string;
  testId?: string | null;
};

const TimeRange: React.FC<AppProps> = ({
  from,
  to = null,
  warning = '',
  testId = null,
}) => {
  if (warning) {
    return (
      <Tooltip message={warning} position="bottom" testId={testId}>
        <p className="text-sm font-medium text-yellow-800">
          {from} {to && '-'} {to}
        </p>
      </Tooltip>
    );
  }

  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {from} {to && '-'} {to}
    </p>
  );
};

export default TimeRange;
