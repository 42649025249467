import React, { useRef } from 'react';
import Modal from 'components/Modal';
import Button, { Variants as ButtonVariants } from 'components/Button';
import { InputType } from 'components/Input/utils';
import Form from 'components/Form';
import { useMutation } from '@apollo/client';
import useErrorToast from 'hooks/useErrorToast';
import { successToast } from 'components/Toaster';
import { useHistory } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Loader, { Variants as LoaderVariants } from 'components/Loader';
import { CREATE_TOGGLE_EMPLOYEES, GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID } from 'queries/toggleManagers';
type AppProps = {
  trigger: React.ReactElement;
  testId?: string | null;
  roles: any;
};
const CreateAdminInternalUser: React.FC<AppProps> = ({
  trigger,
  testId = null,
  roles = {},
}) => {
  const history = useHistory();
  const authInfos: any = useAuth();
  const [NewAdminCreatedByEmailId, { loading: adminCreating, error: errorValue }] = useMutation(
    CREATE_TOGGLE_EMPLOYEES,
    {
      refetchQueries: [
        {
          query: GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID,
          variables: { email: authInfos.accountInfo.email },
        },
      ],
    }
  );

  const formRef = useRef(null);
  useErrorToast(errorValue);


  const handleSubmit = async (hideModal: () => void) => {
    let payload: Record<string, any> = {};
    if (formRef.current) {
      // @ts-ignore
      if (!formRef.current.hasErrors()) {
        // @ts-ignore
        const formValues = formRef.current.getValues();
        const { role,...formValuesWithoutRole } = formValues;
        formValues.extension = ""
        payload = {
          ...payload,
          contact: { ...formValuesWithoutRole, extension: "" },
        };
        payload = { ...payload }
        try {
          await NewAdminCreatedByEmailId({
            variables: {
              body: payload,
            },
          });
          successToast('New Admin Added Successfully');
          hideModal();
          history.go(0);
        } catch (e) { }
      }
    }
  };


  if (adminCreating) {
    return <Loader variant={LoaderVariants.Overlay} />;
  }
  const internalUserForm = [
    {
      key: 'admin',
      className: 'px-5',
      fields: [
        {
          key: 'line1',
          fields: [
            {
              key: 'part1',
              fields: [
                {
                  key: 'firstName',
                  label: 'First Name',
                  type: InputType.Text,
                  validations: [
                    {
                      type: 'required',
                      params: ['This is a required field'],
                    },
                  ],
                  testId: `${testId}-firstName`,
                },
                {
                  key: 'lastName',
                  label: 'Last Name',
                  type: InputType.Text,
                  validations: [
                    {
                      type: 'required',
                      params: ['This is a required field'],
                    },
                  ],
                  testId: `${testId}-lastName`,
                },
              ],
            },
          ],
        },
        {
          key: 'email',
          label: 'Email Address',
          type: InputType.Email,
          validations: [
            {
              type: 'required',
              params: ['This is a required field'],
            },
            {
              type: 'email',
              params: ['Please enter a valid email'],
            },
          ],
          testId: `${testId}-email`,
        },
        {
          key: 'line2',
          fields: [
            {
              key: 'phoneNumber',
              label: 'Phone Number',
              placeholder: '(000) 000-0000',
              type: InputType.Phone,
              validations: [
                {
                  type: 'required',
                  params: ['This is a required field'],
                },
              ],
              testId: `${testId}-phoneNumber`,
            },
            {
              key: 'role',
              label: 'Role',
              description: 'role',
              type: InputType.Select,
              value: 'TOGGLE ADMIN',
              disabled:true,
              options: roles?.getAllRoles?.map(
                (role: { roleDesc: any; roleId: any }) => ({
                  label: role.roleDesc,
                  value: role.roleId,
                })
              ),
              testId: { testId },
            },
          ],
        },
      ],
    },
  ];
  const renderContent = () => {
    return (
      <div className="h-80 w-full py-2" data-testid={testId}>
        <Form key="admin" config={internalUserForm} ref={formRef} />
      </div>
    );
  };
  const renderFooter = (hideModal: () => void) => {
    return (
      <div className="w-full flex justify-between items-center">
        <div>
          <Button
            variant={ButtonVariants.Outline}
            label="Cancel"
            onClick={hideModal}
            disabled={adminCreating}
          />
        </div>
        <div>
          <Button
            variant={ButtonVariants.Primary}
            label="SAVE"
            rightIcon="arrow-right"
            loading={adminCreating}
            disabled={adminCreating}
            onClick={() => handleSubmit(hideModal)}
          />
        </div>
      </div>
    );
  };
  return (
    <Modal
      title="Create Internal User"
      className="w-3/5"
      trigger={trigger}
      content={renderContent}
      footer={renderFooter}
      testId={testId}
    />
  );
};
export default CreateAdminInternalUser;