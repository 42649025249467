import { gql } from '@apollo/client';
import { fragments as miscFragments, locationFragments } from './miscFragments';
import { fragments as freightFragments } from './freight';
import { fragments as _locationFragments } from './location';
import { fragments as bidsFragments } from './bids';
import { fragments as shipperFagments } from './shipper';
import { truckFragments } from './driver';
import { fragments as carrierFragments } from './carrier';
import { fragments as lookupFragments } from './lookup';
import { fragments as accessorialChargesFragments } from './accesorialCharages';

export const fragments: any = {
  shipment: gql`
    fragment ShipmentFragment on Shipment {
      _id
      additionalCharges
      assignedDriverTruck {
        ...ShipmentDriverTruckFragment
      }
      bids {
        ...BidFragment
      }
      carrier {
        ...CarrierBasicInfoFragment
      }
      isDoubleTripleTrailer
      isHazardousMaterial
      hazardousMaterialDescription
      isTank
      isBiddingAllowed
      equipmentType {
        ...LookupFragment
      }
      equipmentTypeNotes
      hazardousMaterialCategory {
        ...LookupFragment
      }
      loadCreationSteps {
        additionalDetails
        freightInformation
        priceInformation
        shipmentInformation
      }
      maxRate
      minRate
      carrierCostMin
      carrierCostMax
      margin
      isPriceSet
      nameOfDBA
      payorAddress {
        ...AddressFragment
      }
      payorContact {
        ...ContactFragment
      }
      freights {
        ...FreightFragment
      }
      rateConfirmationLink
      shipmentMode {
        ...LookupFragment
      }
      shipmentNumber
      shipmentStatus
      shipper {
        ...ShipperBasicInfoFragment
      }
      firstPickUpLocation {
        ...LocationFragment
      }
      lastDeliveryLocation {
        ...LocationFragment
      }
      shippingMiles
      shipmentRate
      weight
      isFreightValueAbove100k
      totalFreightValue
      cancellationNotes
      contractDate
      createdAt
      updatedAt
      postedDate
      isSealRequired
      cancellationChargeNotes
      cancellationCharge
    }
    ${miscFragments.address}
    ${miscFragments.contact}
    ${freightFragments.freight}
    ${bidsFragments.bids}
    ${shipperFagments.shipperBasicInfo}
    ${_locationFragments.location}
    ${truckFragments.shipmentDriverTruck}
    ${lookupFragments.lookup}
    ${carrierFragments.carrierBasicInfo}
  `,
  shipmentList: gql`
    fragment BasicShipmentFragment on Shipment {
      _id
      additionalCharges
      isDoubleTripleTrailer
      isHazardousMaterial
      hazardousMaterialDescription
      isTank
      isBiddingAllowed
      bids {
        status
      }
      equipmentType {
        ...LookupFragment
      }
      freights {
        handlingUnit {
          ...LookupFragment
        }
        quantity
      }
      hazardousMaterialCategory {
        ...LookupFragment
      }
      loadCreationSteps {
        additionalDetails
        freightInformation
        priceInformation
        shipmentInformation
      }
      maxRate
      minRate
      carrierCostMin
      carrierCostMax
      margin
      isPriceSet
      nameOfDBA
      payorAddress {
        ...AddressFragment
      }
      payorContact {
        ...ContactFragment
      }
      shipmentMode {
        ...LookupFragment
      }
      shipmentNumber
      shipmentStatus
      shipper {
        ...ShipperBasicInfoFragment
      }
      firstPickUpLocation {
        ...LocationFragment
      }
      lastDeliveryLocation {
        ...LocationFragment
      }
      shippingMiles
      shipmentRate
      isFreightValueAbove100k
      totalFreightValue
      cancellationNotes
      weight
      createdAt
      updatedAt
      postedDate
      isAccessorialRequestExist
      cancellationChargeNotes
      cancellationCharge
    }
    ${miscFragments.address}
    ${miscFragments.contact}
    ${shipperFagments.shipperBasicInfo}
    ${_locationFragments.location}
    ${lookupFragments.lookup}
  `,
  shipmentLocation: gql`
    fragment ShipmentLocation on ShipmentLocation {
      lat
      lng
      updatedAt
    }
  `,
};

// queries
export const GET_SHIPMENT_NUMBER = gql`
  query GetShipmentNumber {
    getShipmentNumber {
      value
    }
  }
`;

export const GET_SHIPMENT_BY_ID = gql`
  query GetShipmentById($id: String!) {
    getShipmentById(id: $id) {
      ...ShipmentFragment
    }
  }
  ${fragments.shipment}
`;

export const GET_SHIPMENTS = gql`
  query GetShipments(
    $status: ShipmentStatus
    $shipperId: String
    $carrierId: String
    $filter: ShipmentFilterInput
    $limit: Int
    $offset: Int
    $search: String
    $sortBy: String
    $sortingOrder: SortingOrder
  ) {
    getShipments(
      shipmentStatus: $status
      shipperId: $shipperId
      carrierId: $carrierId
      filter: $filter
      limit: $limit
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortingOrder: $sortingOrder
    ) @connection(key: "getShipments") {
      info {
        total
      }
      shipments {
        ...BasicShipmentFragment
      }
      # isAccessorialExist
    }
  }
  ${fragments.shipmentList}
`;

export const GET_SHIPMENTS_FOR_CARRIER = gql`
  query GetShipmentsForCarrier(
    $carrierId: String!
    $limit: Int
    $offset: Int
    $status: ShipmentStatus
    $search: String
    $sortBy: String
    $sortingOrder: SortingOrder
  ) {
    getShipmentsForCarrier(
      carrierId: $carrierId
      limit: $limit
      offset: $offset
      status: $status
      search: $search
      sortBy: $sortBy
      sortingOrder: $sortingOrder
    ) {
      info {
        total
      }
      shipments {
        ...BasicShipmentFragment
      }
    }
  }
  ${fragments.shipmentList}
`;

export const GET_SHIPMENT_LOCATIONS = gql`
  query GetShipmentLocations($shipmentId: String!) {
    getAllLocationDetails(shipmentId: $shipmentId) {
      ...LocationFragment
    }
  }
  ${_locationFragments.location}
`;

export const GET_SHIPMENT_FREIGHTS = gql`
  query GetShipmentFreights($shipmentId: String!) {
    getAllFreights(shipmentId: $shipmentId) {
      ...FreightFragment
    }
  }
  ${freightFragments.freight}
`;

export const GET_SHIPMENT_LOCATIONS_BOL_POD = gql`
  query GetLocationDetailByShipmentId(
    $shipmentId: String!
    $includeAll: Boolean
  ) {
    getLocationDetailByShipmentId(
      shipmentId: $shipmentId
      includeAll: $includeAll
    ) {
      _id
      documentLink
      documentUpdatedBy
      loadedBy
      customerReference
      locationType
      location {
        ...PickupDropFragment
      }
      accessorialCharges {
        ...AccessorialChargesFragment
      }
    }
  }
  ${locationFragments.pickupDrop}
  ${accessorialChargesFragments.accessorialCharges}

`;

export const GET_OTHER_DOCUMENTS_BY_SHIPMENT_ID = gql`
  query GetOtherDocumentsByShipmentId($shipmentId: String!) {
    getOtherDocumentsByShipmentId(shipmentId: $shipmentId) {
      _id
      createdAt
      documentLink
      shipment
      updatedAt
    }
  }
`;

export const GET_ALL_UPDATED_LOCATION_DETAILS = gql`
  query GetAllLocationDetailsForShipment($shipmentId: String!) {
    getAllUpdatedLocationDetailsForShipment(shipmentId: $shipmentId) {
      ...ShipmentLocation
    }
  }
  ${fragments.shipmentLocation}
`;

export const GET_TRUCK_LOCATION_BY_SHIPMENT_ID = gql`
  query GetTruckLocationByShipmentId($shipmentId: String!) {
    getLastKnownLocationForShipment(shipmentId: $shipmentId) {
      ...ShipmentLocation
    }
  }
  ${fragments.shipmentLocation}
`;

export const GET_SHIPMENT_STATS = gql`
  query GetShipmentStats($carrierId: String, $shipperId: String) {
    getShipmentStatusCount(carrierId: $carrierId, shipperId: $shipperId) {
      ACTIVE
      CANCELLED
      COMPLETED
      DRAFT
      IN_TRANSIT
      WITH_BID
      OPEN
      PENDING
      PRICING_REJECTED
    }
  }
`;

// mutations

export const ADD_OTHER_DOCUMENT = gql`
mutation AddOtherDocument( $body: OtherDocumentInput! ) {
  addOtherDocument(otherDocumentInput: $body) {
    ...LocationFragment
  }
}
${_locationFragments.location}
`;

export const CREATE_SHIPMENT = gql`
  mutation CreateShipment($body: ShipmentInput!) {
    createShipment(createShipmentInput: $body) {
      ...BasicShipmentFragment
    }
  }
  ${fragments.shipmentList}
`;

export const UPDATE_SHIPMENT = gql`
  mutation UpdateShipment($body: UpdateShipmentInput!) {
    updateShipment(updateShipmentInput: $body) {
      ...BasicShipmentFragment
    }
  }
  ${fragments.shipmentList}
`;

export const CLONE_SHIPMENT = gql`
  mutation CloneShipment($id: String!) {
    cloneShipment(id: $id) {
      ...ShipmentFragment
    }
  }
  ${fragments.shipment}
`;

export const UPDATE_SHIPMENT_ADDITIONAL_INFO = gql`
  mutation UpdateShipmentAdditionalInfo($body: ShipmentAdditionalDetailInput!) {
    updateShipmentAdditionalInfo(shipmentAdditionalInput: $body) {
      ...BasicShipmentFragment
    }
  }
  ${fragments.shipmentList}
`;

export const UPDATE_SHIPMENT_PRICE_INFO = gql`
  mutation UpdateShipmentPriceInfo($body: ShipmentPriceInput!) {
    updateShipmentPriceInfo(shipmentPriceInput: $body) {
      ...BasicShipmentFragment
    }
  }
  ${fragments.shipmentList}
`;

export const UPDATE_SHIPMENT_PRICING = gql`
  mutation UpdatePricingForShipment($body: PricingInput!) {
    updatePricingForShipment(pricingInput: $body) {
      ...ShipmentFragment
    }
  }
  ${fragments.shipment}
`;

export const REJECT_SHIPMENT_PRICING = gql`
  mutation RejectPricingForShipment($body: RejectInput!) {
    rejectPricingForShipment(rejectInput: $body) {
      ...ShipmentFragment
    }
  }
  ${fragments.shipment}
`;

export const CREATE_LOCATION_DETAIL = gql`
  mutation CreateLocationDetail($body: LocationDetailInput!) {
    createLocationDetail(createLocationDetailInput: $body) {
      ...LocationFragment
    }
  }
  ${_locationFragments.location}
`;

export const UPDATE_LOCATION_DETAIL = gql`
  mutation UpdateLocationDetail($body: UpdateLocationDetailInput!) {
    updateLocationDetail(updateLocationDetailInput: $body) {
      ...LocationFragment
    }
  }
  ${_locationFragments.location}
`;

export const DELETE_SHIPMENT_BY_ID = gql`
  mutation DeleteShipment($id: String!) {
    deleteShipment(id: $id) {
      _id
    }
  }
`;

export const CREATE_LOAD = gql`
  mutation CreateLoad($shipmentId: String!) {
    createLoad(shipmentId: $shipmentId) {
      _id
    }
  }
`;

export const CANCEL_SHIPMENT = gql`
  mutation CancelShipment($shipmentId: String!, $notes: String!, $cancellationChargeNotes:String,$cancellationCharge:String) {
    cancelShipment(notes: $notes, shipmentId: $shipmentId,cancellationChargeNotes: $cancellationChargeNotes, cancellationCharge: $cancellationCharge) {
      _id
    }
  }
`;

export const COMPLETE_SHIPMENT = gql`
  mutation completeTrip($shipmentId: String!) {
    completeTrip(shipmentId: $shipmentId) {
      _id
    }
  }
`;