import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import Navbar from 'components/Navbar';
import useAuth from 'hooks/useAuth';
import Scrollbars from 'components/Scrollbars';
import { getRoles } from 'utils/misc';
import { Roles } from 'utils/enum';

type AppProps = {
  path?: string;
  exact?: boolean;
  roles?: Array<string>;
  showNavbar?: boolean;
  children: any;
};

const ProtectedRoute: React.FC<AppProps> = ({
  children,
  roles = [],
  showNavbar = true,
  ...rest
}) => {
  const { isAuthenticated, accountInfo }: any = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const { isCarrier, isShipper, isTAM } = getRoles(accountInfo?.role);
  if (roles.length) {
    let check = true;
    if (roles.includes(Roles.Carrier) && isCarrier) {
      check = true;
    }
    if (roles.includes(Roles.Shipper) && isShipper) {
      check = true;
    }
    if (roles.includes(Roles.Toggle) && isTAM) {
      check = true;
    }

    if (!check) {
      return <Redirect to="/" />;
    }
  }

  return (
    <Scrollbars>
      {showNavbar && <Navbar />}
      <Route {...rest}>{children}</Route>
    </Scrollbars>
  );
};

export default ProtectedRoute;
