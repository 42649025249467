import React from 'react';

import { IAddress } from 'utils/interfaces';
import { unixTimestampToLocaleString } from 'utils/time';

type AppProps = {
  location: { address?: IAddress };
  visitDate?: number;
  visitTimeFrom?: string;
  visitTimeTo?: string;
  appointmentTime?: string;
  testId?: string | null;
};

const Location: React.FC<AppProps> = ({
  location,
  visitDate,
  visitTimeFrom,
  visitTimeTo,
  appointmentTime,
  testId = null,
}) => {
  const address = location?.address;
  if (!address) {
    return <span>-</span>;
  }
  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {address?.city},{address?.state},{address?.country},{address?.zip}
      <br />
      {visitDate && unixTimestampToLocaleString(visitDate, 'MM/DD/YYYY')}
      <br />
      {visitTimeFrom ? `${visitTimeFrom} - ${visitTimeTo}` : appointmentTime}
    </p>
  );
};

export default Location;
