import React, { useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { find } from 'lodash';
import Select, { components } from 'react-select';
import { ERROR_COLOR } from 'utils/constant';
import Tooltip from 'components/Tooltip';

export interface IOptionProps {
  label?: any;
  value?: string;
}

export type AppProps = {
  name: string;
  options?: IOptionProps[];
  initialValue?: string;
  placeholder?: string;
  validator?: any;
  onSelect?: (v: any) => void;
  disabled?: boolean;
  isLoading?: boolean;
  noOptionLabel?: string;
  showTooltip?: boolean;
  testId?: string;
};

const AutoComplete = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      initialValue = '',
      placeholder = '',
      onSelect = () => null,
      validator,
      options = [],
      disabled = false,
      isLoading = false,
      noOptionLabel = '',
      showTooltip = false,
      testId = null,
    },
    ref
  ) => {
    const [_options, setOptions] = useState(options);
    const inputRef = React.useRef<any>(null);
    const initialSelectedOption = initialValue
      ? find(_options, { value: initialValue })
      : null;
    const [selected, setSelected] = useState(initialSelectedOption);
    const [_isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      name,
      focus: () => inputRef?.current?.focus?.(),
      setDisabled,
      overrideValue: (v: any) =>
        setSelected(v ? find(_options, { value: v }) : null),
      overrideOptions: async (newOptions: any) => {
        setSelected(null);
        setOptions(newOptions);
      },
      getValue: () => ({ [name]: selected ? selected.value : null }),
      getError: () => ({ [name]: null }),
      checkError: () => {
        try {
          validator.validateSync(selected?.value);
          return false;
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
            return true;
          }
          return false;
        }
      },
    }));

    const optionStyle = (active: boolean) =>
      classNames('cursor-pointer select-none relative py-2 pl-3 pr-9', {
        'text-gray-900': !active,
        'text-white bg-primary': active,
      });

    const optionLabelStyle = (selected: boolean) =>
      classNames('flex items-center truncate', {
        'font-normal': !selected,
        'font-bold': selected,
      });

    const styles = {
      control: (base: any) => {
        return {
          ...base,
          borderRadius: '0.375rem',
          boxShadow: `0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f`,
          border: error && `1px solid ${ERROR_COLOR}`,
        };
      },
      singleValue: (provided: any) => ({
        ...provided,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#111827',
      }),
    };

    const formatOptionLabel = ({ label }: any) => <div>{label}</div>;

    const CustomOption = ({
      innerProps,
      isDisabled,
      data,
      isFocused,
      isSelected,
    }: any) =>
      !isDisabled ? (
        <div {...innerProps}>
          <div className={optionStyle(isFocused)}>
            <div className="flex items-center">
              <span className={optionLabelStyle(isSelected)}>{data.label}</span>
            </div>
          </div>
        </div>
      ) : null;

    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <span className="flex-center flex-col w-5 h-5">
            <Icon
              name="chevron-up"
              className="w-full flex justify-self-end text-gray-400"
            />
            <Icon
              name="chevron-down"
              className="w-full flex justify-self-start text-gray-400"
            />
          </span>
        </components.DropdownIndicator>
      );
    };

    const SingleValue = (props: any) => (
      <components.SingleValue {...props}>
        <span>{props.data.label}</span>
      </components.SingleValue>
    );

    const handleChange = (option: any) => {
      setSelected(option);
      onSelect(option);
      setError(null);
      return option;
    };

    const formattedOptions = showTooltip
      ? _options.map((o) => ({
          ...o,
          label: (
            <Tooltip message={o.label} key={o.value}>
              {o.label}
            </Tooltip>
          ),
        }))
      : _options;

    return (
      <div className="w-full" data-testid={testId}>
        <Select
          ref={inputRef}
          className="w-full"
          name="autocomplete"
          formatOptionLabel={formatOptionLabel}
          options={formattedOptions}
          placeholder={placeholder}
          value={selected}
          onChange={handleChange}
          isDisabled={_isDisabled}
          components={{
            Option: CustomOption,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            SingleValue,
          }}
          isSearchable
          isClearable
          isLoading={isLoading}
          noOptionsMessage={() => noOptionLabel}
          theme={(theme: any) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'rgba(79, 70, 229, var(--tw-border-opacity))',
            },
          })}
          styles={styles}
        />
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

AutoComplete.displayName = 'AutoComplete';
export default AutoComplete;
