import React from 'react';

import Banner, { Variants as BannerVariants } from './index';

export type AppProps = {
  message?: string;
  className?: string;
  canClose?: boolean;
  testId?: string | null;
};

const ErrorBanner: React.FC<AppProps> = ({
  message = null,
  className = '',
  canClose = false,
  testId = null,
}) => {
  if (!message) {
    return null;
  }

  return (
    <div className={className} data-testid={testId}>
      <Banner
        title={message}
        variant={BannerVariants.Error}
        className="mt-2 mb-6"
        canClose={canClose}
      />
    </div>
  );
};

export default ErrorBanner;
