import React, { useState, useImperativeHandle } from 'react';
import Icon from 'components/Icon';
import { dateFromNow, unixTimestampToDate } from 'utils/time';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type DateInput = {
  startDate: any;
  endDate: any;
};

export type AppProps = {
  name: string;
  initialValue?: DateInput | undefined;
  validator: any;
  allowPast?: boolean;
  allowToday?: boolean;
  allowFuture?: boolean;
  testId?: string;
};

const convertEpochToDate = (val?: string | number | null) => {
  if (!val) {
    return null;
  }
  if (typeof val === 'number') {
    return unixTimestampToDate(val);
  }
  return new Date();
};

const DateRangePicker = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      initialValue = {},
      validator,
      allowPast = true,
      allowToday = false,
      allowFuture = true,
      testId = null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [dateRange, setDateRange]: any = useState([
      convertEpochToDate(initialValue?.startDate || null),
      convertEpochToDate(initialValue?.endDate || null),
    ]);
    const [startDate, endDate]: any = dateRange;
    const [error, setError] = useState(null);

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      name,
      focus: () => inputRef?.current?.focus?.(),
      overrideValue: (v: any) => {
        setDateRange([
          convertEpochToDate(v?.startDate || null),
          convertEpochToDate(v?.endDate || null),
        ]);
      },
      getValue: () => ({
        [name]: {
          startDate: startDate?.toLocaleDateString('en-US'),
          endDate: endDate?.toLocaleDateString('en-US'),
        },
      }),
      getError: () => ({ [name]: error }),
      checkError: () => {
        try {
          validator.validateSync(startDate || undefined);
          validator.validateSync(endDate || undefined);
          return false;
        } catch (e: any) {
          // @ts-ignore
          setError('Please select a valid date');
          return true;
        }
      },
    }));

    const onChange = (update: any) => {
      setDateRange(update);
      setError(null);
    };

    return (
      <div className="w-full" data-testid={testId}>
        <div className="w-full inline-flex space-x-2 items-center justify-start pl-2 py-2 bg-white shadow border rounded-md border-gray-300 outline-none focus-within:border-primary">
          <Icon name="calendar" className="w-5 h-5 text-base text-gray-500" />
          <DatePicker
            ref={inputRef}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            placeholderText="mm/dd/yyyy : mm/dd/yyyy"
            dateFormat="MM/dd/yyyy"
            className="outline-none focus:outline-none text-gray-900 w-full"
            minDate={
              allowPast ? null : allowToday ? new Date() : dateFromNow(1, 'day')
            }
            maxDate={allowFuture ? null : dateFromNow(0, 'day')}
            isClearable={true}
            // locale="en-US"
          />
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

DateRangePicker.displayName = 'DateRangePickerInput';

export default DateRangePicker;
