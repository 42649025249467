import React, { useState, useImperativeHandle } from 'react';
import classNames from 'classnames';

const replaceComma = (val = '') => (val ? val.replace(/,/g, '') : '');

export type AppProps = {
  name: string;
  placeholder?: string;
  initialValue?: string;
  validator: any;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
  onChange?: (val: any) => void;
  testId?: string;
};

const regex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
const Amount = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      placeholder = '0.00',
      initialValue = '',
      validator,
      prefix = '$',
      suffix = 'USD',
      disabled = false,
      onChange = () => null,
      testId = null,
    },
    ref
  ) => {
    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    });

    const format = (val = '') => {
      let _val = val;
      if (typeof val === 'number') {
        _val = String(val);
      }

      const v = replaceComma(_val);
      if (isNaN(Number(v))) {
        return v;
      }
      return v ? formatter.format(Number(v)) : '';
    };

    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState<string>(format(initialValue));
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    const getErrorMessage = (e: any) => {
      let err = e?.errors[0] || 'Please enter a valid value';
      if (err.includes('NaN')) {
        err = 'Please enter a valid value';
      }
      return err;
    };

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => {
          setValue(format(v));
        },
        setDisabled,
        getValue: () => ({
          [name]: value
            ? parseFloat(parseFloat(replaceComma(value)).toFixed(2))
            : 0,
        }),
        getError: () => ({ [name]: error }),
        checkError: () => {
          try {
            validator.validateSync(replaceComma(value));
            return false;
          } catch (e: any) {
            setError(getErrorMessage(e));
            return true;
          }
        },
      })
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const v = replaceComma(val?.toString());
      onChange(v);
      try {
        validator?.validateSync(v);
        if (v) {
          if (regex.test(v)) {
            setValue(formatter.format(Number(v)));
            setError(null);
          } else {
            setValue(val);
            // @ts-ignore
            setError('This is a required field');
          }
        }
      } catch (e: any) {
        if (!v) {
          setValue(val);
          setError(null);
        } else {
          if (e.errors && e.errors[0]) {
            setValue(val);
            setError(getErrorMessage(e));
          }
        }
      }
    };

    const inputStyle = classNames(
      'w-full relative text-base leading-normal text-gray-900 outline-none placeholder-gray-400 px-3 py-2 bg-white shadow border rounded-md border-gray-300',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
        'bg-gray-100': isDisabled,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <div className={inputStyle}>
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-base leading-normal text-gray-500">
              {prefix}
            </span>
          </div>
          <input
            autoComplete="never"
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            className="w-full outline-none pl-3 pr-8"
            value={value}
            onChange={handleOnChange}
            disabled={isDisabled}
          />
          <div className="absolute inset-y-0 right-2 flex items-center text-base leading-normal text-gray-500">
            {suffix}
          </div>
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

Amount.displayName = 'Amount';

export default Amount;
