import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getUnixTimestampNow } from 'utils/time';
import { refreshIdToken } from 'utils/cognito';
import { getUserInfoFromToken, logout, setToken } from 'utils/misc';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_BASE_URL}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(
    process.env.REACT_APP_SESSION_KEY || 'sid'
  );

  if (token) {
    // check if token is valid
    let _token = token;
    const tokenInfo: Record<string, any> = getUserInfoFromToken(token);
    if (tokenInfo.exp < getUnixTimestampNow() + 30) {
      // refresh the token
      try {
        _token = await refreshIdToken();
        setToken(_token);
      } catch (e) {
        logout();
      }
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${_token}`,
      },
    };
  } else {
    return {
      headers,
    };
  }
});

const errLink = onError(({ networkError, graphQLErrors }) => {
  if (
    networkError?.message === 'Unauthorized' ||
    graphQLErrors?.[0].extensions?.HttpStatusCode === 401 ||
    (graphQLErrors?.length && graphQLErrors[0].message === 'Unauthorized')
  ) {
    localStorage.clear();
    // @ts-ignore
    window.location.href = '/login';
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      Account: ['Carrier', 'Shipper'],
    },
  }),
  link: from([errLink, authLink, httpLink]),
});
