import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Logo, { Colors } from 'components/Logo';
import Avatar from 'components/Avatar';
import Menu from 'components/Menu';
import useAuth from 'hooks/useAuth';
import { Status } from 'utils/enum';
import Tooltip from 'components/Tooltip';
import UserInfo from './UserInfo';
import SignOut from './SignOut';
import Icon from 'components/Icon';
import { GET_NOTIFICATION_COUNT_BY_USER, UPDATE_NOTIIFCATION_STATUS } from 'queries/notifications';
import { useMutation, useQuery } from '@apollo/client';

type AppProps = {
  testId?: string | null;
};

const CarrierNavbar: React.FC<AppProps> = ({ testId = null }) => {
  const {
    accountInfo: {
      userId,
      name,
      email,
      role,
      isInternalUser = false,
      status = Status.Draft,
    },
    resetAuthInfo,
    isImpersonateMode,
    removeImpersonateData,
  }: any = useAuth();

  const impMode = isImpersonateMode();

  const { data: countValue, refetch: refetchNotificationCount } = useQuery(GET_NOTIFICATION_COUNT_BY_USER, {
    variables: {
      userId: userId, userType: 'CARRIER'
    },
    fetchPolicy: 'network-only',
  });

  const [updateNotificationStatus, { loading, error }] = useMutation(
    UPDATE_NOTIIFCATION_STATUS,
    {
      onCompleted: refetchNotificationCount
    }
  );

  const handleNotificationCount = async (userId: string, userType: string) => {
    try {
      await updateNotificationStatus({
        variables: {
          userId,
          userType
        },
      });
    } catch (error: any) {
      console.log(error.message);

    }
  }


  const activeLinkStyle = (isActive = false) =>
    classNames(
      'px-3 py-2 rounded-md mr-2 text-sm font-medium leading-tight text-white whitespace-nowrap',
      {
        'bg-indigo-700 ': isActive,
      }
    );

  const avatarStyle = classNames('cursor-pointer', {
    'border-2 animate-pulse': impMode,
  });

  const menuItems: Array<any> = [
    {
      renderNode: (): React.ReactNode => (
        <UserInfo userEmail={email} userRole={role} />
      ),
    },
  ];
  if (impMode) {
    menuItems.push({
      label: 'Stop Impersonation',
      onClick: () => {
        removeImpersonateData();
        location.href = '/';
      },
    });
  }

  if (status === Status.Approved && !isInternalUser) {
    menuItems.push({
      renderNode: (): React.ReactNode => (
        <NavLink to="/internal-user">
          <p className="text-primary px-4">User Administration</p>
        </NavLink>
      ),
    });
  }

  menuItems.push({
    renderNode: (): React.ReactNode => <SignOut onClick={resetAuthInfo} />,
  });

  return (
    <div
      className="w-full inline-flex space-x-80 items-center justify-between px-5 py-3.5 bg-primary sticky top-0 z-50"
      data-testid={testId}
    >
      <div className="flex items-center">
        <div className="w-36 h-5 mr-16">
          <Logo color={Colors.White} />
        </div>
        {
          // @ts-ignore
          [Status.Approved].includes(status) && (
            <>
              <NavLink to="/load-board" className={activeLinkStyle}>
                Load Board
              </NavLink>
              <NavLink to="/shipments" className={activeLinkStyle}>
                Shipments
              </NavLink>
            </>
          )
        }
      </div>
      <div className="flex space-x-2 items-center justify-center">
        {/* <div className="flex items-center justify-center p-1 rounded-full">
          <Icon name="bell" className="h-6 w-6 text-white rounded-full" />
        </div> */}
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-start px-3 rounded-md">
            {userId ? (
              <NavLink to='/notifications' onClick={() => { handleNotificationCount(userId, 'CARRIER') }} className={activeLinkStyle}>
                <div className="relative">
                  {(countValue?.getNotificationCountByUser > 0) &&
                    <p className='bg-red-500 text-white rounded-full w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1 flex items-center justify-center text-xs'>
                      {countValue?.getNotificationCountByUser}
                    </p>
                  }
                  <Icon name="bell" className="h-7 w-7 text-white cursor-pointer" />
                </div>
              </NavLink>
            ) : null}
            <p className="text-sm font-medium leading-tight text-right text-white whitespace-nowrap">
              Welcome, {name}
            </p>
          </div>
          <Menu
            trigger={
              impMode ? (
                <Tooltip message="Impersonating Shipper">
                  <Avatar className={avatarStyle} name={name} testId="avatar" />
                </Tooltip>
              ) : (
                <Avatar className={avatarStyle} name={name} testId="avatar" />
              )
            }
            items={menuItems}
          />
        </div>
      </div>
    </div >
  );
};

export default CarrierNavbar;
