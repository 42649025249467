import React from 'react';
import { Link } from 'react-router-dom';
import Text from './Text';

type AppProps = {
  value: string;
  to?: string | null;
  testId?: string | null;
};

const TLink: React.FC<AppProps> = ({ to = null, value, testId = null }) => {
  if (!to) {
    return <Text value={value} testId={testId} />;
  }

  return (
    <div data-testid={testId}>
      <Link to={to} data-testid={testId}>
        <p className="text-sm font-medium text-primary cursor-pointer">
          {value}
        </p>
      </Link>
    </div>
  );
};

export default TLink;
