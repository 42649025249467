import useAuth from 'hooks/useAuth';
import { getRoles } from 'utils/misc';

const useRoles = () => {
  const { accountInfo }: any = useAuth();
  const role = accountInfo?.role;
  return getRoles(role);
};

export default useRoles;
