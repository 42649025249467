import React from 'react';
import useAuth from 'hooks/useAuth';
import ToggleManagerNavbar from './components/ToggleManagerNavbar';
import MainNavbar from './components/Navbar';
import useRoles from 'hooks/useRoles';
import CarrierNavbar from './components/CarrierNavbar';

type AppProps = {
  testId?: string | null;
};
const Navbar: React.FC<AppProps> = ({ testId = null }) => {
  const { isAuthenticated } = useAuth();
  const { isTAM, isCarrier } = useRoles();

  if (!isAuthenticated) {
    return null;
  }

  if (isTAM) {
    return <ToggleManagerNavbar testId={testId} />;
  }

  if (isCarrier) {
    return <CarrierNavbar testId={testId} />;
  }
  return <MainNavbar testId={testId} />;
};

export default Navbar;
