import { gql } from '@apollo/client';

import { fragments as miscFragments } from './miscFragments';

export const fragments = {
  internalUser: gql`
    fragment InternalUserFragment on InternalUser {
      _id
      email
      firstName
      lastName
      phoneNumber
      role
      createdAt
    }
  `,
  role: gql`
    fragment RoleFragment on Role {
      roleDesc
      roleId
    }
  `,
};

export const CREATE_INTERNAL_USER = gql`
  mutation CreateInternalUser(
    $registerInternalUserInput: RegisterInternalUserInput!
  ) {
    createInternalUser(registerInternalUserInput: $registerInternalUserInput) {
      ...InternalUserFragment
    }
  }
  ${fragments.internalUser}
`;

export const EDIT_INTERNAL_USER = gql`
  mutation EditInternalUser(
    $internalUser: EditInternalUserInput!
    $editInternalUserId: String!
  ) {
    editInternalUser(internalUser: $internalUser, id: $editInternalUserId) {
      ...InternalUserFragment
    }
  }
  ${fragments.internalUser}
`;

export const GET_ALL_ROLES = gql`
  query GetAllRoles {
    getAllRoles {
      ...RoleFragment
    }
  }
  ${fragments.role}
`;

export const GET_ALL_INTERNAL_USERS = gql`
  query GetAllInternalUsers(
    $limit: Int
    $internalUserFilter: InternalUserFilterInput
    $offset: Int
    $search: String
    $sortBy: String
    $sortingOrder: SortingOrder
  ) {
    getAllInternalUsers(
      limit: $limit
      internalUserFilter: $internalUserFilter
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortingOrder: $sortingOrder
    ) {
      internalUsers {
        ...InternalUserFragment
      }
      info {
        ...PaginationFragment
      }
    }
  }
  ${fragments.internalUser}
  ${miscFragments.pagination}
`;

export const GET_ALL_INTERNAL_USERS_BY_ID = gql`
query GetInternalUserById($getInternalUserById: String!) {
  getInternalUserById(id: $getInternalUserById) {
    ...InternalUserFragment
    
  }
}
${fragments.internalUser}
`;
export const REMOVE_INTERNAL_USER = gql`
  mutation RemoveInternalUser($removeInternalUserId: String!) {
    removeInternalUser(id: $removeInternalUserId) {
      ...InternalUserFragment
    }
  }
  ${fragments.internalUser}
`;

export const IS_SHIPMENT_EXIST_FOR_GIVEN_DRIVER = gql`
query Query($id: String!) {
  isShipmentExistForGivenDriver(id: $id)
}`;
