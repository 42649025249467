import React from 'react';
import Icon from 'components/Icon';
import { IAddress } from 'utils/interfaces';
import { LocationType } from 'utils/enum';

type AppProps = {
  address: IAddress;
  locationOrder?: number;
  locationType?: string;
  showLineAddress?: boolean;
  testId?: string | null;
};

const Address: React.FC<AppProps> = ({
  address,
  locationOrder = 1,
  locationType = LocationType.Pickup,
  showLineAddress = true,
  testId = '',
}) => {
  if (!address) {
    return <span data-testid={testId}>-</span>;
  }

  return (
    <div className="flex flex-row" data-testid={testId}>
      <>
        {locationOrder === 0 && (
          <div className="pr-1">
            {locationType === LocationType.Pickup ? (
              <Icon name="arrow-circle-up" className="w-5 h-5 text-blue-400" />
            ) : (
              <Icon
                name="arrow-circle-down"
                className="w-5 h-5 text-green-400"
              />
            )}
          </div>
        )}
        <div className="text-sm font-medium">
          {showLineAddress && (
            <>
              {address?.line1} {address?.line2}
              <br />
            </>
          )}
          {address?.city}, {address?.state}, {address?.zip}
        </div>
      </>
    </div>
  );
};

export default Address;
