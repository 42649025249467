import React from 'react';
import TermsDocument from './components/TermsDocument';

export enum Variants {
  Service = 'service',
  Privacy = 'privacy',
  Lading = 'lading',
}

export type AppProps = {
  variant: Variants;
};

const Terms: React.FC<AppProps> = ({ variant }) => {
  const renderMain = () => {
    if (variant === Variants.Service) {
      return <TermsDocument filePath="/terms_of_service.pdf" />;
    }

    if (variant === Variants.Privacy) {
      return <TermsDocument filePath="/privacy_policy.pdf" />;
    }

    return <TermsDocument filePath="/bill_of_lading.pdf" />;
  };

  return <>{renderMain()}</>;
};

export default Terms;
