import React from 'react';

type AppProps = {
  firstName: string;
  lastName?: string;
  phoneNumber?: string;
  testId?: string | null;
};

const Contact: React.FC<AppProps> = ({
  firstName,
  lastName,
  phoneNumber,
  testId = null,
}) => {
  if (!firstName) {
    return <span data-testid={testId}>-</span>;
  }
  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {firstName} {lastName}
      {phoneNumber && <br />}
      {phoneNumber}
    </p>
  );
};

export default Contact;
