import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import Routes from './routes/Routes';
import { client as apolloClient } from './setupApollo';
import AuthProvider from './context/AuthContext';
import Toaster from './components/Toaster';
import FallBack, { ErrorHandler } from './components/ErrorBoundary';

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={FallBack} onError={ErrorHandler}>
      <Router>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </ApolloProvider>
        <Toaster />
      </Router>
    </ErrorBoundary>
  );
};

export default App;
