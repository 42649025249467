/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useSticky } from 'react-table-sticky';
import Search from 'components/Input/components/Search';
import {
  Date,
  Link,
  Location,
  Text,
  Distance,
  Amount,
  AmountRange,
  Weight,
  TimeRange,
  Contact,
  Address,
  Boolean,
  ShipmentStatusTag,
  Quantity,
  Volume,
  BidStatusTag,
  Equipment,
} from './components/columnRenderers';
import Pagination from './components/Pagination';
import Icon from 'components/Icon';
import { Order } from 'utils/enum';
import clx from 'classnames';
import { errorToast } from 'components/Toaster';
import { GET_SHIPMENT_STATS } from 'queries/shipment';

export enum ColumnType {
  Date = 'date',
  Text = 'text',
  Link = 'link',
  Location = 'location',
  Distance = 'distance',
  Amount = 'amount',
  AmountRange = 'amountRange',
  Weight = 'weight',
  TimeRange = 'timeRange',
  Contact = 'contact',
  Address = 'address',
  Boolean = 'boolean',
  ShipmentStatusTag = 'shipmentStatusTag',
  BidStatusTag = 'bidStatusTag',
  Actions = 'actions',
  Custom = 'custom',
  Quantity = 'quantity',
  Volume = 'volume',
  Equipment = 'equipment',
}

interface IColumn {
  Header: string;
  accessor?: string;
  type?: ColumnType;
  width?: string;
  Cell?: (params: any) => React.ReactElement;
  Footer?: (params: any) => React.ReactElement;
  props?: ({ row, value }: any) => Record<string, any>;
  testId?: string | null;
  sticky?: string;
}

interface IDataFetcher {
  query: any;
  listAccessor: string;
  pageAccessor: string;
  variables: Record<string, any>;
}

interface IStatFetcher {
  variables: Record<string, any>;
}

const TypeComponentMap: Record<string, any> = {
  [ColumnType.Date]: Date,
  [ColumnType.Text]: Text,
  [ColumnType.Link]: Link,
  [ColumnType.Location]: Location,
  [ColumnType.Distance]: Distance,
  [ColumnType.Amount]: Amount,
  [ColumnType.AmountRange]: AmountRange,
  [ColumnType.Weight]: Weight,
  [ColumnType.TimeRange]: TimeRange,
  [ColumnType.Contact]: Contact,
  [ColumnType.Address]: Address,
  [ColumnType.Boolean]: Boolean,
  [ColumnType.ShipmentStatusTag]: ShipmentStatusTag,
  [ColumnType.BidStatusTag]: BidStatusTag,
  [ColumnType.Quantity]: Quantity,
  [ColumnType.Volume]: Volume,
  [ColumnType.Equipment]: Equipment,
};

export type AppProps = {
  columns: IColumn[];
  data?: any[];
  dataFetcher?: IDataFetcher;
  statsFetcher?: IStatFetcher;
  defaultLimit?: number;
  search?: boolean;
  testId?: string | null;
};

const Table: React.FC<AppProps> = ({
  columns,
  data = null,
  dataFetcher = null,
  statsFetcher = null,
  defaultLimit = 5,
  testId = '',
  search = true,
}) => {
  const client = useApolloClient();
  const [rowData, setRowData] = useState(data);
  const [total, setTotal] = useState(1);
  const [limit, setLimit] = useState(defaultLimit);
  const [fetching, setFetching] = useState(!(data && data.length));

  const [refetchStat] = useLazyQuery(GET_SHIPMENT_STATS, {
    fetchPolicy: 'network-only',
  });

  const showMeta = () => {
    if (data && data.length) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const hasFooter = React.useMemo(() => {
    return !!columns.filter((col) => !!col.Footer).length;
  }, [columns]);

  const _columns = React.useMemo(
    () =>
      fetching
        ? columns.map((col) => ({ ...col, Cell: <Skeleton /> }))
        : columns.map((col) => {
          if (col.type === ColumnType.Actions) {
            return {
              ...col,
              // @ts-ignore
              Cell: ({ row }: any) => col.renderActions(row, fetchData),
            };
          } else if (col.type && TypeComponentMap[col.type]) {
            const InputComponent = TypeComponentMap[col.type];
            return {
              ...col,
              Cell: ({ row, value }: any) => (
                <InputComponent
                  value={value}
                  {...(col.props ? col.props({ row, value }) : row.original)}
                />
              ),
            };
          }
          return col;
        }),
    [fetching, columns]
  );

  const _data = React.useMemo(
    () => (fetching ? Array(defaultLimit).fill({}) : rowData),
    [fetching, rowData]
  );
  const tableInstance = useTable(
    {
      // @ts-ignore
      columns: _columns,
      // @ts-ignore
      data: _data,
      initialState: { pageIndex: 0, pageSize: defaultLimit },
      manualPagination: true,
      pageCount: Math.ceil(total / limit),
      manualGlobalFilter: true,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    page,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = tableInstance;

  useEffect(() => {
    if (dataFetcher) {
      fetchData();
      if (statsFetcher) {
        refetchStat({ variables: statsFetcher?.variables });
      }
    }
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    sortBy,
    JSON.stringify(dataFetcher?.variables),
  ]);

  const fetchData = async () => {
    setFetching(true);
    const _sortBy = sortBy[0];
    try {
      const { data } = await client.query({
        query: dataFetcher?.query,
        variables: {
          limit: pageSize,
          offset: pageSize * pageIndex,
          search: globalFilter,
          sortBy: _sortBy?.id,
          filter: {},
          sortingOrder: _sortBy
            ? _sortBy?.desc
              ? Order.Dsc
              : Order.Asc
            : undefined,
          ...dataFetcher?.variables,
        },
        fetchPolicy: 'network-only',
      });
      const total = get(data, dataFetcher?.pageAccessor || '') || 1;
      const totalData = typeof total === 'object' && total !== null ? total.total : total || 1;

      setRowData(get(data, dataFetcher?.listAccessor || '') || []);
      setTotal(totalData);
    } catch (e: any) {
      errorToast(e?.message);
    }
    setFetching(false);
  };

  const getFooterColSpan = (col: any) => {
    return col?.colspan || 1;
  };

  const tableStyle = clx('w-full border-b border-gray-200 rounded-b', {
    shadow: showMeta(),
  });

  const bodyStyle = (isSticky = false, isReq = false) => {
    const textColorClass = isReq ? 'text-red-500' : 'text-gray-500';

    return clx(
      'px-6 py-4 whitespace-pre text-sm leading-5 font-normal',
      textColorClass,
      {
        'bg-white': isSticky,
      }
    );
  };


  return (
    <div className={tableStyle}>
      {showMeta() && search && (
        <div>
          <Search
            setSearch={setGlobalFilter}
            loading={fetching}
            testId={`${testId}-search`}
          />
        </div>
      )}
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full divide-y divide-gray-200 border-t-1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-50">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                    className={`px-6 py-4 text-xs font-medium tracking-wide leading-none uppercase text-gray-500 text-left whitespace-nowrap bg-white ${column.width}`}
                    //@ts-ignore
                    data-testid={`${column?.testId}`}
                  >
                    <div className="flex items-center">
                      {column.render('Header')}
                      {showMeta() && !column.disableSortBy && (
                        <span className="ml-2">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon
                                name="sort-descending"
                                className="w-4 h-4 text-primary"
                              />
                            ) : (
                              <Icon
                                name="sort-ascending"
                                className="w-4 h-4 text-primary"
                              />
                            )
                          ) : (
                            <Icon
                              name="sort-descending"
                              className="w-4 h-4 text-gray-400"
                            />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        // @ts-ignore
                        className={bodyStyle(cell.column?.sticky, row.original.isReq)}
                        // @ts-ignore
                        data-testid={cell.column?.testId}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {hasFooter && (
            <tfoot className="bg-gray-50">
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td
                      {...column.getFooterProps()}
                      colSpan={getFooterColSpan(column)}
                      key={column.getFooterProps()['key']}
                      className="px-6 py-4"
                    >
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>

      {showMeta() && (
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          page={page}
          total={total}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={(v: number) => {
            setLimit(v);
            setPageSize(v);
            gotoPage(0);
          }}
          loading={fetching}
          testId={`${testId}-pagination`}
        />
      )}
    </div>
  );
};

export default Table;
