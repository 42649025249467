import Tag from 'components/Tag';
import React from 'react';
import {
  BidStatusColor,
  BidStatusBgColor,
  BidStatusLabel,
} from 'utils/constant';

type AppProps = {
  status: string;
  testId?: string | null;
};

const BidStatusTag: React.FC<AppProps> = ({ status, testId = null }) => {
  return (
    <Tag
      textColor={BidStatusColor[status]}
      bgColor={BidStatusBgColor[status]}
      label={BidStatusLabel[status]}
      testId={testId}
    />
  );
};

export default BidStatusTag;
