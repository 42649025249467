import React from 'react';

type AppProps = {
  value: string;
  testId?: string | null;
};

const Text: React.FC<AppProps> = ({ value, testId = null }) => {
  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {value}
    </p>
  );
};

export default Text;
