import { startCase } from 'lodash';
import { AppProps as StepAppProps } from 'components/Stepper/components/Step';
import { OTHER_HANDLING_UNIT } from 'utils/constant';
import { client } from 'setupApollo';
import { unixTimestampToDate, unixTimestampToLocaleString } from 'utils/time';
import jwtDecode from 'jwt-decode';
import { Roles } from 'utils/enum';

export const logout = () => {
  client.cache.reset();
  localStorage.clear();
  window.location.href = '/login';
};

export const getUserInfoFromToken = (token: string) => {
  const tokenInfo: Record<string, any> = jwtDecode(token);
  return tokenInfo;
};

export const setToken = (token: string) => {
  localStorage.setItem(process.env.REACT_APP_SESSION_KEY || 'sid', token);
};

export const initializeArray: any = (l: number, v = null) => {
  const arr = [];
  for (let i = 0; i < l; i += 1) {
    arr.push(v);
  }
  return arr;
};

export const titleCase = (s: string) => {
  return startCase(s.toLowerCase());
};

export const shipmentStatusToTagClass: Record<string, any> = {
  ACTIVE: {
    textColor: 'text-green-800',
    bgColor: 'bg-green-100',
  },
  CANCELLED: {
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
  DRAFT: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  IN_TRANSIT: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  NOT_READY: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  OPEN: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  PENDING_CONFIRMATION: {
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
  PICKED_UP: {
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
  READY_FOR_PICKUP: {
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
};

export const statusToTagClass: Record<string, any> = {
  APPROVED: {
    textColor: 'text-green-800',
    bgColor: 'bg-green-100',
  },
  OPEN: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  DRAFT: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  REJECTED: {
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
};

export const getStepsMap: any = (steps: StepAppProps[]) => {
  return steps.reduce((acc, curr) => {
    acc = { ...acc, [curr.key]: curr.state };
    return acc;
  }, {});
};

export const flattenObject = (obj: any, parentKey: any = null) => {
  const flattened: any = {};
  if (!obj) {
    return flattened;
  }
  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      Object.assign(flattened, flattenObject(obj[key], key));
    } else {
      if (parentKey && key === '_id') {
        flattened[parentKey] = obj[key];
      } else {
        flattened[key] = obj[key];
      }
    }
  });
  return flattened;
};

export const currencyFormatter = (currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    currency,
  });

export const formatCurrency = (number: number, currency = 'USD') => {
  if (!number) {
    return '';
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(
    number
  );
};

export const arrayToObject = (key: string, value: string, arr: Array<any>) => {
  const obj: any = {};
  arr.forEach((ele) => {
    obj[ele[key]] = ele[value];
  });
  return obj;
};

export const getAddressLine = (
  address: Record<string, any>,
  showline = true,
  showZip = true
) => {
  if (!address) {
    return '';
  }
  let _address = '';
  if (showline) {
    _address = address.line1;
    if (address.line2) {
      _address = `${_address}, ${address.line2}`;
    }
  }

  if (_address && showZip) {
    return `${_address}, ${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
  }
  if (_address && !showZip)
    return `${_address}, ${address.city}, ${address.state}, ${address.country}`;

  return `${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
};

export const getBasicAddressLine = (address: Record<string, any>) => {
  if (!address) {
    return '';
  }
  return `${address?.city}, ${address?.state}`;
};

export const formatVolume = (
  {
    H,
    L,
    W,
  }: {
    H: number;
    L: number;
    W: number;
  },
  addSuffix = true,
  allowEmpty = true
) => {
  if (!allowEmpty) {
    if (!L || !W || !H) {
      return null;
    }
  }
  const formattedString = `${L || 0}x${W || 0}x${H || 0}`;
  if (addSuffix) {
    return `${formattedString} ft`;
  } else {
    return formattedString;
  }
};

interface ILookup {
  value: string;
  label: string;
}

export const formatHandlingUnit = (
  handlingUnit: ILookup,
  handlingUnitNotes = ''
) => {
  if (handlingUnit?.value === OTHER_HANDLING_UNIT && handlingUnitNotes) {
    return `${handlingUnit.label} (${handlingUnitNotes})`;
  }
  return handlingUnit.label;
};

interface IFormatEndorsements {
  isHazardousMaterial: boolean;
  isTank: boolean;
  isDoubleTripleTrailer: boolean;
}

export const formatEndorsements = ({
  isHazardousMaterial = false,
  isTank = false,
  isDoubleTripleTrailer = false,
}: IFormatEndorsements) => {
  const t = [];
  if (isHazardousMaterial) {
    t.push('Hazardous Material (H)');
  }
  if (isTank) {
    t.push('Tank (N)');
  }
  if (isDoubleTripleTrailer) {
    t.push('Double/Triple Trailer (T)');
  }

  return t.join(', ');
};

export const downloadBase64File = (
  contentType: string,
  base64Data: string,
  fileName: string
) => {
  const linkSource = `data:application/${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getVisitTimeInfo = (location: any) => {
  let s = unixTimestampToLocaleString(location?.visitDate, 'MM/DD/YYYY');
  if (location?.visitTimeFrom) {
    s = `${s}, ${location?.visitTimeFrom} - ${location?.visitTimeTo}`;
  } else {
    s = `${s}, ${location?.appointmentTime}`;
  }
  return s;
};

export const getFileNameFromUrl = (url: string) => {
  if (!url) {
    return '-';
  }
  const unfilteredName = url.split('?')[0].split('/').reverse()[0];
  const url_arr = unfilteredName?.replace(/\s|%20|%2520|%28|%29/g, '')
  return url_arr.split('%')[0];
};

export const getFilePathFromUrl = (url: string) => {
  if (!url) {
    return null;
  }
  const url_arr = url.split('?')[0].split('/');
  return url_arr.slice(3, 5).join('/');
};

export const getRoles = (role: string) => {
  if (!role) {
    return {
      isCarrier: false,
      isShipper: false,
      isDriver: false,
      isTAM: false,
    };
  }
  return {
    isCarrier: role.includes(Roles.Carrier),
    isShipper: role.includes(Roles.Shipper),
    isDriver: role.includes(Roles.Driver),
    isTAM: role.includes(Roles.Toggle),
  };
};

export const compareChangeRequest = (originalArray: any, realArray: any, onlyChanges = false) => {
  if (!originalArray || !realArray) return;

  const dropDeletedText = '*Location Deleted'
  const dropChangedText = '*Location Updated'
  const dropAddedText = '*Location Added'
  const freightDeletedText = '*Freight Deleted'
  const freightChangedText = '*Freight Updated'
  const freightAddedText = '*Freight Added'
  const deleted = 'DELETED'

  const updatedArray = realArray.map((item: any) => {
    const matchingReq = originalArray?.find((req: any) => item.originalId === req._id);
    if (matchingReq) {
      const element1 = { ...item };
      const element2 = { ...matchingReq };

      delete element1._id;
      delete element1.originalId;
      delete element1.isReq;
      delete element1.shipment;
      delete element1.locationOrder;
      delete element2._id;
      delete element2.originalId;
      delete element2.shipment;
      delete element2.locationOrder;
      if (element1.pickupLocation) {
        element1.pickupLocation = getAddressLine(element1.pickupLocation.location.address)
      }
      if (element1.dropLocation) {
        element1.dropLocation = getAddressLine(element1.dropLocation.location.address)
      }
      if (element2.pickupLocation) {
        element2.pickupLocation = getAddressLine(element2.pickupLocation.location.address)
      }
      if (element2.dropLocation) {
        element2.dropLocation = getAddressLine(element2.dropLocation.location.address)
      }
      if (element1.createdAt) {
        delete element1.createdAt;
        delete element2.createdAt;
      }
      if (element2.updatedAt) {
        delete element1.updatedAt;
        delete element2.updatedAt;
      }
      const areEqual = JSON.stringify(element1) === JSON.stringify(element2);
      return areEqual ? null : item;
    }else{
      item.newUpdate = true
    }
    return item;
  }).filter((item: any) => item !== null && !(item.locationType && item.locationType === 'PICKUP'));

  updatedArray.map((item: any) => {
    if (item.customerReference && item.locationType === 'DROP' && !onlyChanges) {
      if (item.newUpdate) {
        item.customerReference = `${item.customerReference}\n${dropAddedText}`
      } else {
        item.customerReference = `${item.customerReference}\n${dropChangedText}`
      }
    }
    if (item.description && !onlyChanges) {
      if (item.newUpdate) {
        item.description = `${item.description}\n${freightAddedText}`
      } else {
        item.description = `${item.description}\n${freightChangedText}`
      }
    }
  })

  originalArray.forEach((element: any,) => {
    if (element.locationType === "PICKUP") {
      return;
    }
    const exists = realArray.some((realElement: any) => realElement.originalId === element._id);
    if (!exists) {
      const item = JSON.parse(JSON.stringify(element))
      if (item.customerReference) {
        if(onlyChanges){
          item.customerReference = `${item.customerReference}\n(${deleted})`
        }else{
          item.customerReference = `${item.customerReference}\n${dropDeletedText}`
        }
      }
      if (item.description) {
        if(onlyChanges){
          item.description = `${item.description}\n(${deleted})`
        }else{
          item.description = `${item.description}\n${freightDeletedText}`
        }
      }
      item.isReq = true;
      updatedArray.push(item)
    }
  });
  if (onlyChanges) {
    return updatedArray
  }
  return originalArray.concat(updatedArray);
};


export const isTAM = (role = '') => {
  return role.includes(Roles.Toggle);
};

export const isShipper = (role = '') => {
  return role.includes(Roles.Shipper);
};

export const isCarrier = (role = '') => {
  return role.includes(Roles.Carrier);
};

export const isOnlyDriver = (role = '') => {
  return role === Roles.Driver;
};

export const checkDuplicateEmails = (data: any) => {
  const emails = data.map((entry:any) => entry.email);
  const uniqueEmails = Array.from(new Set(emails));
  if (emails.length != uniqueEmails.length) {
   return { message:'Same email address already exists in the Primary Contact Details'}
  }
};