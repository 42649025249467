import React from 'react';

type AppProps = {
  value: number;
  isHazardousMaterial?: boolean;
  isTank?: boolean;
  isDoubleTripleTrailer?: boolean;
  testId?: string | null;
};

const Equipment: React.FC<AppProps> = ({
  value,
  isHazardousMaterial = false,
  isTank = false,
  isDoubleTripleTrailer = false,
  testId = null,
}) => {
  const renderChips = () => {
    const chips: string[] = [];
    if (isHazardousMaterial) {
      chips.push('hazmat');
    }
    if (isTank) {
      chips.push('tank');
    }
    if (isDoubleTripleTrailer) {
      chips.push('double triple trailer');
    }

    return (
      <div className="mt-1">
        {chips.map((chip, idx) => (
          <div
            key={idx}
            className="py-1 px-2 rounded-md mb-1 w-max text-xs bg-gray-100"
          >
            {chip}
          </div>
        ))}
      </div>
    );
  };

  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {value}
      {renderChips()}
    </p>
  );
};

export default Equipment;
