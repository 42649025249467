import moment from 'moment';
const momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

export const unixTimestampToLocaleString = (
  timeStamp: number,
  format = 'L hh:mm:ss A'
): string => {
  if (!timeStamp) {
    return '';
  }

  try {
    //return moment(Number(timeStamp)).format(format);
    return moment.utc(Number(timeStamp)).format(format);
  } catch (error) {
    return '';
  }
};

export const unixTimestampToDate = (timeStamp: string | number) => {
  if (!timeStamp) {
    return null;
  }
  try {
    //return moment(Number(timeStamp) / 1000, 'X').toDate();
    const dateString = moment.utc(Number(timeStamp)).format('DD/MM/YYYY');
    const newData = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1');
    const dateNew = new Date(newData);
    return dateNew;
  } catch (error) {
    return null;
  }
};

export const getUnixTimestampNow = () => {
  return moment().unix();
};

export const unixTimestampFromNow = (
  amount: moment.DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor | undefined,
  multiplier = 1
) => {
  return moment().add(amount, unit).unix() * multiplier;
};

export const dateFromNow = (
  amount: moment.DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor | undefined
) => {
  return moment().add(amount, unit).toDate();
};

export const unixTimestampSinceFromNow = (timeStamp: string | number) => {
  if (!timeStamp) {
    return null;
  }
  return moment.utc(Number(timeStamp) / 1000, 'X').fromNow();
};

export const calculateDuration = (
  eventTime: any,
  duration = 15,
  durationType = 'minutes',
  hereafter = false
) => {
  let time = null;
  try {
    if (!hereafter) {
      time = moment
        .utc(Number(eventTime))
        .add(
          duration,
          durationType as moment.unitOfTime.DurationConstructor | undefined
        )
        .diff(moment.utc());
    } else {
      time = moment.utc().diff(moment.utc(Number(eventTime)));

      time = time < 0 ? Math.abs(time) : 0;
    }

    return moment.duration(time <= 0 ? 0 : time);
  } catch (error) {
    return moment.duration(0);
  }
};

export const isValidTimeInterval = (
  eventTime: any,
  duration = 15,
  durationType = 'minutes',
  hereafter = false
) => {
  const obj: any = calculateDuration(
    eventTime,
    duration,
    durationType,
    hereafter
  );

  return obj?._milliseconds !== 0;
};

export const dateToUnixTimestamp = (dateString: string) => {
  if (!dateString) {
    return null;
  }
  return moment(dateString).utc().valueOf();
};

export const isDateInPast = (timestamp: number) => {
  return timestamp < unixTimestampFromNow(0, 'day', 1000);
};
export const isDateInPastExcludingToday = (timestamp: number) => {
  const todayDate = new Date().toISOString().split('T')[0];
  const givenDate = new Date(Number(timestamp)).toISOString().split('T')[0];
  // if (moment(givenDate).isBefore(todayDate)) {
  //   console.log('date is past');
  // } else {
  //   console.log('Date is not past');
  // }
  return moment(givenDate).isBefore(todayDate);
  //return timestamp < unixTimestampFromNow(-1, 'day', 1000);
};

export const getTimeZone = () => {
  const date = new Date();
  const timeZone = date.toString().match(/([+-]\d{2})(\d{2})/)?.[1] + ':' + date.toString().match(/([+-]\d{2})(\d{2})/)?.[2];
  console.log(`TimeZone: ${timeZone}`);
  return timeZone
}