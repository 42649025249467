import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../Icon';
import './toaster.css';

export type AppProps = {
  message?: string;
};

const Toaster: React.FC<AppProps> = () => {
  const CloseButton = () => (
    <Icon name="x" className="w-4 h-4 mx-3 text-gray-500 rounded-full" />
  );

  return (
    <ToastContainer
      autoClose={3500}
      closeButton={CloseButton}
      pauseOnHover
      closeOnClick
      hideProgressBar
      newestOnTop
      transition={Slide}
      position="top-right"
      toastClassName="bg-gray-50"
      limit={3}
    />
  );
};

export default Toaster;

export const successToast: any = (message: string) => {
  return toast.success(message, {
    className: 'bg-green-50 border rounded-md border-green-500',
    bodyClassName: 'text-sm font-bold leading-tight text-green-800',
    icon: (
      <Icon
        name="check-circle"
        className="w-6 h-6 text-green-400 rounded-full"
      />
    ),
    closeButton: (
      <Icon name="x" className="w-6 h-6 mx-2 text-green-400 rounded-full" />
    ),
  });
};

export const errorToast: any = (message: string) => {
  return toast.error(message, {
    className: 'bg-red-50 border rounded-md border-red-500',
    bodyClassName: 'text-sm font-bold leading-tight text-red-800',
    icon: (
      <Icon name="exclamation" className="w-6 h-6 text-red-400 rounded-full" />
    ),
    closeButton: (
      <Icon name="x" className="w-6 h-6 mx-2 text-red-400 rounded-full" />
    ),
  });
};
