import React from 'react';
import { formatVolume } from 'utils/misc';

type AppProps = {
  value: any;
  testId?: string | null;
};

const Volume: React.FC<AppProps> = ({ value, testId = null }) => {
  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {value ? formatVolume(value) : '-'}
    </p>
  );
};

export default Volume;
