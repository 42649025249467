import React from 'react';
import useAuth from 'hooks/useAuth';
import OnboardingStatus from 'components/OnboardingStatus';
import { Redirect } from 'react-router-dom';
import Loader from 'components/Loader';
import useRoles from 'hooks/useRoles';

const Home: React.FC = () => {
  const { accountInfo } = useAuth();
  const { isTAM } = useRoles();

  // @ts-ignore
  const status = accountInfo?.status;

  if (isTAM) {
    return <Redirect to="/account-requests" />;
  }

  // @ts-ignore
  if (!accountInfo?.email) {
    return <Redirect to="/onboarding" />;
  }

  if (!status) {
    return <Loader title="Wait for a moment" />;
  }

  return <OnboardingStatus onboardingStatus={status} />;
};

export default Home;
