import React, { useImperativeHandle, useState } from 'react';
import { Switch } from '@headlessui/react';
import Icon from 'components/Icon';
import classNames from 'classnames';

export type AppProps = {
  name: string;
  initialValue?: boolean | string;
  label?: string;
  disabled?: boolean;
  onChange?: (v: boolean) => void;
  testId?: string;
};

const Toggle = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      initialValue = false,
      label = '',
      disabled = false,
      onChange = () => null,
      testId = null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [enabled, setEnabled] = useState(!!initialValue);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: boolean) => {
          setEnabled(v);
        },
        getValue: () => ({ [name]: enabled }),
        getError: () => ({ [name]: null }),
        checkError: () => false,
      })
    );

    const switchStyle = (enabled: boolean) =>
      classNames(
        'relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none',
        {
          'bg-gray-200': !enabled,
          'bg-primary': enabled,
        }
      );

    const switchButtonStyle = (enabled: boolean) =>
      classNames(
        'inline-flex items-center justify-center w-5 h-5 transform bg-white rounded-full transition-transform',
        {
          'translate-x-1': !enabled,
          'translate-x-5': enabled,
        }
      );

    return (
      <div className="w-full" data-testid={testId} tabIndex={0} ref={inputRef}>
        <Switch.Group>
          <div className="w-full inline-flex space-x-3 items-center justify-start cursor-pointer">
            <Switch
              disabled={disabled}
              checked={enabled}
              onChange={(v) => {
                setEnabled(v);
                onChange(v);
              }}
              className={switchStyle(enabled)}
            >
              <span className={switchButtonStyle(enabled)}>
                {enabled ? (
                  <Icon name="check" className="w-3 h-3 text-grey-400" />
                ) : (
                  <Icon name="x" className="w-3 h-3 text-grey-400" />
                )}
              </span>
            </Switch>
            <Switch.Label className="text-sm font-medium leading-tight text-gray-900 cursor-pointer">
              {label}
            </Switch.Label>
          </div>
        </Switch.Group>
      </div>
    );
  }
);

Toggle.displayName = 'Toggle';

export default Toggle;
