import React, { useRef } from 'react';
import Button from 'components/Button';
import { InputType } from 'components/Input/utils';
import Form from 'components/Form';

type AppProps = {
  setSearch: (search: string) => void;
  placeholder?: string;
  loading?: boolean;
  testId?: string | null;
};

const Search: React.FC<AppProps> = ({
  setSearch,
  placeholder = 'Search',
  loading = false,
  testId = null,
}) => {
  const ref = useRef(null);

  const searchForm = [
    {
      key: 'search',
      fields: [
        {
          key: 'search',
          className: 'w-80',
          type: InputType.Text,
          placeholder,
          lefIcon: 'search',
          validations: [],
          testId: testId,
        },
      ],
    },
  ];

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (ref.current) {
      // @ts-ignore
      const { search } = ref.current.getValues();
      setSearch(search);
    }
  };

  return (
    <div className="py-4 pl-6 pr-5 bg-gray-50 rounded-lg" data-testid={testId}>
      <form onSubmit={handleSearch}>
        <div className="flex items-center">
          <Form config={searchForm} ref={ref} />

          <div className="ml-5">
            <Button
              label="Search"
              disabled={loading}
              testId={testId + 'search-btn'}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Search;
