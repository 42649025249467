import React from 'react';
import { Redirect } from 'react-router-dom';
import { Status } from 'utils/enum';
import Banner from 'components/Banner';
import useRoles from 'hooks/useRoles';

type AppProps = {
  onboardingStatus?: string;
  testId?: string | null;
};

const OnboardingStatus: React.FC<AppProps> = ({
  onboardingStatus = '',
  testId = null,
}) => {
  const { isShipper, isCarrier } = useRoles();

  if (onboardingStatus === Status.Approved) {
    if (isShipper) {
      return <Redirect to="/load-board" />;
    } else if (isCarrier) {
      return <Redirect to="/load-board" />;
    }
    return null;
  }

  if (onboardingStatus === Status.Open) {
    return (
      <div
        className="flex justify-center items-center p-10"
        data-testid={testId}
      >
        <div>
          <Banner
            title="Your account request is under processing. We will update you once the status changes"
            canClose={false}
            testId="account-underprocess-bannner"
          />
        </div>
      </div>
    );
  }

  return <Redirect to="/onboarding" />;
};

export default OnboardingStatus;
