import React from 'react';
import classNames from 'classnames';
import { truncate } from 'lodash';

import Icon from 'components/Icon';
import ConfirmationBox from 'components/ConfirmationBox';

export type AppProps = {
  documentLink?: string | null;
  name?: string;
  onDelete: () => void;
  deleteButtonEnable: boolean;
  error?: string;
  testId?: string;
};

const UploadedFile: React.FC<AppProps> = ({
  documentLink = null,
  name,
  onDelete,
  deleteButtonEnable = true,
  error = null,
  testId = null,
}) => {
  const contentStyle = classNames(
    'w-full flex flex-col items-start justify-start border rounded-md border-gray-200 mb-3',
    {
      'border-error': !!error,
    }
  );

  const unfilteredFilename = name || documentLink?.split('?')[0].split('/').reverse()[0];
  const fileName = unfilteredFilename?.replace(/\s|%20|%28|%29/g, '');

  return (
    <div className={contentStyle} data-testid={testId}>
      <div className="w-full inline-flex space-x-4 items-start justify-between pl-3 pr-4 py-3">
        <div className="flex space-x-2 items-start justify-start">
          <Icon name="paper-clip" className="w-5 h-5 text-gray-500" />
          <p className="text-sm leading-tight text-gray-900">
            {truncate(fileName, { length: 32 })}
          </p>
        </div>
        <div className="inline-flex">
          {documentLink && (
            <button
              className="text-sm font-medium leading-tight text-primary mr-3"
              onClick={() => window.open(documentLink)}
            >
              View
            </button>
          )}
          {deleteButtonEnable && (
            <ConfirmationBox
              title="Delete file?"
              message={`Are you sure you want to delete this "${truncate(
                fileName,
                { length: 32 }
              )}" file?`}
              trigger={
                <div className="text-sm font-medium leading-tight text-error">
                  Delete
                </div>
              }
              onConfirm={async () => onDelete()}
              confirmLabel="Delete"
              onCancel={() => null}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadedFile;
