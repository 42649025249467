import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export type ItemProps = {
  label?: string;
  path?: string;
  disabled?: boolean;
  onClick?: () => void;
  renderModal?: (ref: any) => React.ReactNode;
  renderNode?: () => React.ReactNode;
  testId?: string | null;
};

export type AppProps = {
  trigger: React.ReactNode;
  items: ItemProps[];
  testId?: string | null;
};

const TMenu: React.FC<AppProps> = ({ items, trigger, testId = null }) => {
  const _items = items.map((item) => {
    if (item.renderModal) {
      const ref = React.createRef();
      return {
        ...item,
        ref,
        onClick: () => {
          if (ref.current) {
            // @ts-ignore
            ref.current.click();
          }
        },
      };
    }
    return item;
  });

  const optionStyle = (active: boolean) =>
    classNames(
      'cursor-default select-none relative py-2 pl-4 pr-6 cursor-pointer text-base rounded-md',
      {
        'text-gray-900': !active,
        'text-white bg-primary': active,
      }
    );

  const renderItem = (item: ItemProps, active: boolean) => {
    if (item.path) {
      return (
        <Link className={optionStyle(active)} to={item.path || ''}>
          <span data-testid={item.testId}>{item.label}</span>
        </Link>
      );
    }

    if (item.renderNode) return item.renderNode();

    return (
      <span className={optionStyle(active)} data-testid={item.testId}>
        {item.label}
      </span>
    );
  };

  return (
    <div className="" data-testid={testId}>
      <Menu as="div" className="relative">
        <Menu.Button as="div">{trigger}</Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="w-max absolute right-0 flex flex-col mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
            {_items.map((item, idx) => (
              <Menu.Item
                key={idx}
                disabled={item.disabled}
                onClick={item.onClick}
              >
                {({ active }) => renderItem(item, active)}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      {_items
        .filter((item) => item.renderModal)
        .map((item) => {
          // @ts-ignore
          return <div key={item.label}>{item.renderModal(item.ref)}</div>;
        })}
    </div>
  );
};

export default TMenu;
