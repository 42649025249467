// Check input for proper formats
export const checkFormat = (rules: RegExp[], val: string): null | RegExp => {
  const rulesCount = rules.length;
  for (let count = 0; count < rulesCount; count += 1) {
    const matcher: RegExp = rules[count];
    if (!val.match(matcher)) {
      return matcher;
    }
  }
  return null;
};

export default checkFormat;
