import { gql } from '@apollo/client';

export const fragments: any = {
  bids: gql`
    fragment BidFragment on Bid {
      _id
      amount
      status
      carrier {
        _id
        companyName
      }
      createdAt
      createdBy
      notes
      updatedAt
    }
  `,
};

export const CONFIRM_OFFER = gql`
  mutation ConfirmOffer($body: UpdateBidInput!) {
    confirmOffer(updateBidInput: $body) {
      _id
    }
  }
`;

export const CREATE_BID = gql`
  mutation CreateBid($body: CreateBidInput!) {
    createBid(createBidInput: $body) {
      _id
      status
    }
  }
`;

export const ACCEPT_NON_BID_OFFER = gql`
  mutation AcceptOffer($carrierId: String!, $shipmentId: String!) {
    acceptOffer(carrier: $carrierId, shipment: $shipmentId) {
      _id
      bids {
        amount
      }
    }
  }
`;
