import React, { useImperativeHandle, useState } from 'react';

export type AppProps = {
  name: string;
  label?: string | React.ReactNode;
  initialValue?: boolean | string;
  disabled?: boolean;
  onChange?: (v: boolean) => void;
  testId?: string;
};

const Checkbox = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      label = '',
      initialValue = false,
      disabled = false,
      onChange = () => null,
      testId = null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState(!!initialValue);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: boolean) => setValue(!!v),
        getValue: () => ({ [name]: value }),
        getError: () => ({ [name]: null }),
        checkError: () => false,
      })
    );

    const handleChange = () => {
      onChange(!value);
      setValue((value) => !value);
    };

    return (
      <label
        className="inline-flex items-center justify-between cursor-pointer"
        data-testid={testId}
      >
        <input
          autoComplete="never"
          ref={inputRef}
          type="checkbox"
          disabled={disabled}
          className="w-4 h-4 rounded-full outline-none focus:border-primary cursor-pointer"
          onChange={handleChange}
          checked={value}
        />
        <p className="px-2 text-sm leading-tight text-gray-900">{label}</p>
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
