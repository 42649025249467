import { gql } from '@apollo/client';
import { fragments as miscFragments } from './miscFragments';

export const fragments = {
  toggleManager: gql`
    fragment ToggleManagerFragment on ToggleEmployee {
      _id
      contact {
        ...ContactFragment
      }
      role
      createdAt
      linked
    }
    ${miscFragments.contact}
  `,
};

export const GET_TOGGLE_EMPLOYEE_BY_EMAIL = gql`
  query GetToggleEmployeeByEmail($email: String!) {
    getToggleEmployeeByEmail(email: $email) {
      ...ToggleManagerFragment
    }
  }
  ${fragments.toggleManager}
`;

export const GET_TOGGLE_EMPLOYEES = gql`
  query getToggleEmployees {
    getToggleEmployees {
      ...ToggleManagerFragment
    }
  }
  ${fragments.toggleManager}
`;

export const GET_TOGGLE_EMPLOYEES_BY_EMAIL_ID = gql`
  query getToggleEmployeesByEmailId(
    $email: String!
    $limit: Int
    $internalUserFilter: InternalUserFilterInput
    $offset: Int
    $search: String
    $sortBy: String
    $sortingOrder: SortingOrder) {
    getToggleEmployeesByEmailId(email:$email
     limit: $limit
      internalUserFilter: $internalUserFilter
      offset: $offset
      search: $search
      sortBy: $sortBy
      sortingOrder: $sortingOrder) {
          adminData {
        ...ToggleManagerFragment
      }
          info {
        ...PaginationFragment
      }
    }
  }
  ${fragments.toggleManager}
  ${miscFragments.pagination}

`;

export const CREATE_TOGGLE_EMPLOYEES = gql`
  mutation NewAdminCreatedByEmailId(
    $body: CreateToggleEmployeeInput!) {
    NewAdminCreatedByEmailId(body:$body) {
      ...ToggleManagerFragment
    }
  }
  ${fragments.toggleManager}
`;

export const REMOVE_ADMIN_BY_ID = gql`
  mutation removeAdminById(
    $id: String!) {
    removeAdminById(id:$id) {
      ...ToggleManagerFragment
    }
  }
  ${fragments.toggleManager}
`;
export const ADMIN_EDIT_BY_ID = gql`
  mutation editInternalUser($contact: ContactInput!,$id: String!) {
    adminUpdateById(id: $id,contact:$contact) {
      ...ToggleManagerFragment
    }
  }
  ${fragments.toggleManager}
`;

export const GET_TOGGLE_EMPLOYEE = gql`
  query getToggleEmployee($id: String!) {
    getToggleEmployee (id:$id){
      ...ToggleManagerFragment
    }
  }
  ${fragments.toggleManager}
`;