import React, { useImperativeHandle, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { find } from 'lodash';
import Icon from 'components/Icon';

export interface IOptionProps {
  label?: string;
  renderModal?: (ref: any) => React.ReactNode;
  onClick?: () => void;
  value?: string;
}

export type AppProps = {
  name: string;
  options?: IOptionProps[];
  initialValue?: string;
  placeholder?: string;
  validator?: any;
  onSelect?: (v: any) => void;
  isClearable?: boolean;
  disabled?: boolean;
  className?: string;
  testId?: string;
};

const Select = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      options = [],
      initialValue = '',
      placeholder = 'Select',
      onSelect = () => null,
      disabled = false,
      isClearable = false,
      validator,
      className,
      testId = null,
    },
    ref
  ) => {
    const [_options, setOptions] = useState(
      options.map((item) => {
        if (item.renderModal) {
          const ref = React.createRef();
          return {
            ...item,
            ref,
            onClick: () => {
              if (ref.current) {
                // @ts-ignore
                ref.current.click();
              }
            },
          };
        }
        return item;
      })
    );
    const inputRef = React.useRef<any>(null);
    const initialSelectedOption = initialValue
      ? find(_options, { value: initialValue })
      : null;
    const [selected, setSelected] = useState(initialSelectedOption);
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      name,
      focus: () => inputRef?.current?.focus?.(),
      overrideValue: (v: any) =>
        setSelected(v ? find(_options, { value: v }) : null),
      overrideOptions: (newOptions: any) => {
        setSelected(null);
        setOptions(newOptions);
      },
      setDisabled,
      getValue: () => ({ [name]: selected ? selected.value : null }),
      getError: () => ({ [name]: null }),
      checkError: () => {
        try {
          validator.validateSync(selected?.value);
          return false;
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
            return true;
          }
          return false;
        }
      },
    }));

    const inputStyle = classNames(
      'w-full inline-flex space-x-1 items-center justify-between px-3 py-2 bg-white shadow border rounded-md border-gray-300 transition ease-in-out duration-150 outline-none',
      {
        'focus:border-primary': !error && !isDisabled,
        'bg-gray-100 cursor-default': isDisabled,
      }
    );

    const optionStyle = (active: boolean) =>
      classNames('cursor-default select-none relative py-2 pl-3 pr-9', {
        'text-gray-900': !active,
        'text-white bg-primary': active,
      });

    const optionLabelStyle = (selected: boolean) =>
      classNames('flex items-center truncate', {
        'font-normal': !selected,
        'font-bold': selected,
      });

    const handleChange = (item: any) => {
      setSelected(item);
      onSelect(item);
      setError(null);
    };

    const handleClear = (e: React.MouseEvent<EventTarget>) => {
      e.stopPropagation();
      setSelected(null);
      onSelect(null);
      setError(null);
    };

    return (
      <div className="w-full" data-testid={testId} ref={inputRef} tabIndex={0}>
        <Listbox
          as="div"
          value={selected}
          onChange={handleChange}
          className="w-full"
        >
          {({ open }) => (
            <>
              <div className="relative">
                <div className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    disabled={isDisabled}
                    className={`${inputStyle} ${className}`}
                  >
                    <span className="text-base text-gray-900">
                      {selected ? (
                        <span>{selected.label}</span>
                      ) : (
                        <span className="text-gray-400">{placeholder}</span>
                      )}
                    </span>
                    <div className="flex">
                      {isClearable && selected && (
                        <span className="px-2" onClick={handleClear}>
                          <Icon name="x" className="h-5 w-5 text-gray-400" />
                        </span>
                      )}
                      <span className="flex-center flex-col w-5 h-5">
                        <Icon
                          name="chevron-up"
                          className="w-full flex justify-self-end text-gray-400"
                        />
                        <Icon
                          name="chevron-down"
                          className="w-full flex justify-self-start text-gray-400"
                        />
                      </span>
                    </div>
                  </Listbox.Button>
                </div>
                {!isDisabled && (
                  <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg mb-11">
                    <Transition
                      show={open}
                      leave="transition duration-100 ease-in"
                      leaveFrom="transform opacity-100"
                      leaveTo="transform opacity-0"
                    >
                      <Listbox.Options
                        static
                        className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {_options.length ? (
                          _options.map((option) => {
                            if (option.renderModal) {
                              return (
                                <div
                                  key={option.value}
                                  className="cursor-pointer hover:bg-primary hover:text-white"
                                  onClick={option.onClick}
                                >
                                  <div className="space-x-1 justify-between px-3 py-2 flex items-center">
                                    {option.label}
                                  </div>
                                </div>
                              );
                            }
                            return (
                              <Listbox.Option
                                as="div"
                                key={option.value}
                                value={{
                                  label: option.label,
                                  value: option.value,
                                }}
                              >
                                {({ selected, active }) => {
                                  return (
                                    <li
                                      key={option.value}
                                      className={optionStyle(active)}
                                      onClick={option.onClick}
                                    >
                                      <div className="flex items-center">
                                        <span
                                          className={optionLabelStyle(selected)}
                                        >
                                          {option.label}
                                        </span>
                                      </div>
                                    </li>
                                  );
                                }}
                              </Listbox.Option>
                            );
                          })
                        ) : (
                          <div className="flex items-center justify-center text-gray-500">
                            No choices
                          </div>
                        )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </div>
            </>
          )}
        </Listbox>
        {_options
          .filter((item) => item.renderModal)
          .map((item) => {
            // @ts-ignore
            return <div key={item.label}>{item.renderModal(item.ref)}</div>;
          })}
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
