import React from 'react';

import Step, { AppProps as StepAppProps } from './components/Step';

// Actiave key is being calculated by the breadcrumb.
export type StepProps = Omit<StepAppProps, 'active'>;
export type AppProps = {
  path: StepProps[];
  className?: string;
  testId?: string;
};

const Breadcrumb: React.FC<AppProps> = ({
  path,
  className = '',
  testId = 'breadcrumb',
}) => {
  return (
    <div
      className={`flex items-center justify-start py-4 pl-5 pr-96 bg-white ${className}`}
      data-testid={testId}
      role="list"
    >
      <div className="inline-flex space-x-2 items-center justify-start rounded-md">
        {path.map((step, index) => {
          return (
            <Step
              key={step.label}
              path={step.path}
              active={index === path.length - 1}
              label={step.label}
              testId={step.testId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumb;
