import React from 'react';
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  BanIcon,
  ChevronRightIcon,
  CheckIcon,
  XIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ClockIcon,
  MinusIcon,
  PlusIcon,
  PaperClipIcon,
  RefreshIcon,
  TrashIcon,
  PencilIcon,
  PencilAltIcon,
  SwitchVerticalIcon,
  CalendarIcon,
  ExclamationIcon,
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  XCircleIcon,
  CheckCircleIcon,
  SortDescendingIcon,
  SortAscendingIcon,
  DownloadIcon,
  EyeIcon,
  EyeOffIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  CubeIcon,
  InformationCircleIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  DuplicateIcon,
  DotsHorizontalIcon,
  CogIcon
} from '@heroicons/react/solid';

import {
  BellIcon,
  UserIcon,
  DocumentAddIcon,
  SearchIcon,
  CurrencyDollarIcon,
  LogoutIcon,
  FilterIcon,
} from '@heroicons/react/outline';
import { emptyFunction } from 'utils/constant';

export const iconMap: Record<string, React.ElementType> = {
  'arrow-circle-up': ArrowCircleUpIcon,
  'arrow-circle-down': ArrowCircleDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-down': ArrowDownIcon,
  filter: FilterIcon,
  ban: BanIcon,
  bell: BellIcon,
  cog: CogIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  'check-circle': CheckCircleIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-left': ChevronLeftIcon,
  currency: CurrencyDollarIcon,
  'double-chevron-right': ChevronDoubleRightIcon,
  'double-chevron-left': ChevronDoubleLeftIcon,
  'chevron-up': ChevronUpIcon,
  'chevron-down': ChevronDownIcon,
  cube: CubeIcon,
  clock: ClockIcon,
  delete: TrashIcon,
  'document-add': DocumentAddIcon,
  'download-icon': DownloadIcon,
  duplicate: DuplicateIcon,
  ellipse: DotsHorizontalIcon,
  edit: PencilAltIcon,
  exclamation: ExclamationIcon,
  eye: EyeIcon,
  eyeOff: EyeOffIcon,
  filterCol: SwitchVerticalIcon,
  info: InformationCircleIcon,
  loading: RefreshIcon,
  logout: LogoutIcon,
  minus: MinusIcon,
  'paper-clip': PaperClipIcon,
  pencil: PencilIcon,
  plus: PlusIcon,
  search: SearchIcon,
  'sort-ascending': SortAscendingIcon,
  'sort-descending': SortDescendingIcon,
  user: UserIcon,
  x: XIcon,
  'x-circle': XCircleIcon,
};

export type AppProps = {
  name: string;
  onClick?: (...args: any[]) => any;
  className?: string;
  label?: string;
  testId?: string;
};

const Icon: React.FC<AppProps> = ({
  name,
  onClick = emptyFunction,
  className = '',
  label = '',
  testId = 'icon',
}) => {
  const IconComponent = iconMap[name];

  const role = onClick !== emptyFunction ? 'button' : 'none';
  label = label ? label : name;

  return (
      <IconComponent
        className={className}
        role={role}
        aria-label={label}
        // The below is needed to override the `aria-hidde="true"` set in multiple icons imported above.
        // Since, we're handling the role value on our own, we can overwrite this value making it more semantic and easier to test as well.
        aria-hidden={false}
        onClick={onClick}
        data-testid={testId}
      />
  );
};

export default Icon;
