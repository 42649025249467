import React from 'react';
import { unixTimestampToLocaleString } from 'utils/time';
import Tooltip from 'components/Tooltip';

type AppProps = {
  value: number;
  warning?: string;
  testId?: string | null;
};

const Date: React.FC<AppProps> = ({ value, warning = '', testId = null }) => {
  if (warning) {
    return (
      <Tooltip message={warning} position="bottom" testId={testId}>
        <p className="text-sm font-medium text-yellow-800">
          {unixTimestampToLocaleString(value, 'MM/DD/YYYY')}
        </p>
      </Tooltip>
    );
  }

  return (
    <p className="text-sm font-medium">
      {unixTimestampToLocaleString(value, 'MM/DD/YYYY')}
    </p>
  );
};

export default Date;
