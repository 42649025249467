import React, { useImperativeHandle, useState } from 'react';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { filter } from 'lodash';

export type OptionProps = {
  label?: string;
  value?: string;
};

export type MultiSelectProps = {
  name: string;
  options?: OptionProps[];
  placeholder?: string;
  initialValue?: any;
  validator?: any;
  onSelect?: (v: any) => void;
  isLoading?: boolean;
  noOptionLabel?: string;
  testId?: string;
};

const MultiSelect = React.forwardRef<HTMLElement, MultiSelectProps>(
  (
    {
      name,
      options = [],
      placeholder = '',
      initialValue = [],
      validator,
      onSelect = () => null,
      isLoading = false,
      noOptionLabel = 'No Options',
      testId = null,
    },
    ref
  ) => {
    const filterInitialOptions = (_values: Array<string>) => {
      if (!_values) {
        return [];
      }
      return filter(options, (o: any) => _values?.includes(o.value));
    };

    const inputRef = React.useRef<any>(null);
    const [error, setError] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState<any[]>(
      filterInitialOptions(initialValue)
    );

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      name,
      focus: () => inputRef?.current?.focus?.(),
      overrideValue: (v: any) => setSelectedOptions(filterInitialOptions(v)),
      getValue: () => ({
        [name]: selectedOptions ? selectedOptions.map((o) => o.value) : [],
      }),
      getError: () => ({ [name]: null }),
      checkError: () => {
        try {
          validator.validateSync(
            selectedOptions?.length ? selectedOptions : []
          );
          return false;
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
            return true;
          }
          return false;
        }
      },
    }));

    const optionStyle = (active: boolean) =>
      classNames('cursor-default select-none relative py-2 pl-3 pr-9', {
        'text-gray-900': !active,
        'text-white bg-primary': active,
      });

    const optionLabelStyle = (selected: boolean) =>
      classNames('flex items-center truncate', {
        'font-normal': !selected,
        'font-bold': selected,
      });

    const style = {
      control: (base: any) => {
        return {
          ...base,
          borderRadius: '0.375rem',
          boxShadow: `0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f`,
        };
      },
      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
      multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: '#E0E7FF',
        borderRadius: '12px',
        paddingLeft: '3px',
        color: '#3730A3',
      }),
      multiValueRemove: (provided: any) => ({
        ...provided,
        borderRadius: '12px',
        paddingRight: '3px',
        '&:hover': {
          backgroundColor: '#E0E7FF',
          color: '#3730A3',
        },
      }),
    };

    const onChange = (option: any) => {
      setSelectedOptions(option);
      onSelect(option);
      setError(null);
      return option;
    };
    const formatOptionLabel = ({ label }: any) => <div>{label}</div>;

    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <span className="flex-center flex-col w-5 h-5">
            <Icon
              name="chevron-up"
              className="w-full flex justify-self-end text-gray-400"
            />
            <Icon
              name="chevron-down"
              className="w-full flex justify-self-start text-gray-400"
            />
          </span>
        </components.DropdownIndicator>
      );
    };

    const CustomOption = ({
      innerProps,
      isDisabled,
      data,
      isFocused,
      isSelected,
    }: any) =>
      !isDisabled ? (
        <div {...innerProps}>
          <div className={optionStyle(isFocused)}>
            <div className="flex items-center">
              <span className={optionLabelStyle(isSelected)}>{data.label}</span>
            </div>
          </div>
        </div>
      ) : null;

    const MultiValue = (props: any) => (
      <components.MultiValue {...props}>
        <span className="text-indigo-800">{props.data.label}</span>
      </components.MultiValue>
    );

    const MultiValueContainer = (props: any) => {
      return (
        <div className="bg-white rounded-sm">
          <components.MultiValueContainer {...props} clasName="rounded" />
        </div>
      );
    };
    const MultiValueRemove = (props: any) => {
      return (
        <components.MultiValueRemove {...props}>
          <Icon name="x" className="w-4" />
        </components.MultiValueRemove>
      );
    };

    return (
      <div className="w-full" data-testid={testId}>
        <Select
          ref={inputRef}
          formatOptionLabel={formatOptionLabel}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          value={selectedOptions}
          components={{
            Option: CustomOption,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            MultiValue,
            MultiValueContainer,
            MultiValueRemove,
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll
          menuPosition={'fixed'}
          menuPlacement={'bottom'}
          className="w-full"
          isSearchable
          styles={style}
          isLoading={isLoading}
          noOptionsMessage={() => noOptionLabel}
          theme={(theme: any) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'rgba(79, 70, 229, var(--tw-border-opacity))',
            },
          })}
          classNamePrefix="toggle"
          isMulti
        />
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';
export default MultiSelect;
