import React, { useImperativeHandle, useState } from 'react';
import clx from 'classnames';
import Icon from 'components/Icon';

export type AppProps = {
  name: string;
  initialValue?: number;
  validator: any;
  testId?: string;
};

const Counter = React.forwardRef<HTMLElement, AppProps>(
  ({ name, initialValue = 0, validator, testId = null }, ref) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState(() => initialValue);
    const [error, setError] = useState(null);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => {
          const initialValue = parseInt(v || '0', 10);
          setValue(initialValue);
          setError(null);
        },
        getValue: () => ({ [name]: value }),
        getError: () => ({ [name]: error }),
        checkError: () => {
          try {
            validator.validateSync(value || 0);
            return false;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
            }
            return true;
          }
        },
      })
    );

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      // @ts-ignore
      if (!isNaN(v)) {
        const _v = parseInt(v, 10);
        setValue(_v);
        setError(null);
        try {
          validator.validateSync(_v || 0);
          setError(null);
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
          }
        }
      }
    };

    const handleCounterDecrease = () => {
      const newValue = value - 1;
      if (newValue >= 0) {
        setValue(newValue);
        try {
          validator.validateSync(newValue);
          setError(null);
        } catch (e: any) {
          if (e.errors && e.errors[0]) {
            setError(e.errors[0]);
          }
        }
      }
    };

    const handleCounterIncrease = () => {
      // @ts-ignore
      const newValue = (value ? parseInt(value, 10) : 0) + 1;
      setValue(newValue);
      try {
        validator.validateSync(newValue);
        setError(null);
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          setError(e.errors[0]);
        }
      }
    };

    const containerStyle = clx(
      'w-full inline-flex space-x-8 items-center justify-between px-3 py-2 bg-white shadow rounded-md border',
      {
        'border-gray-300': !error,
        'border-error focus:border-red': error,
      }
    );

    return (
      <div className="w-full " data-testid={testId}>
        <div className={containerStyle}>
          <div onClick={handleCounterDecrease}>
            <Icon
              name="minus"
              className="w-3.5 h-3.5 text-gray-400 cursor-pointer"
            />
          </div>
          <input
            autoComplete="never"
            value={value}
            ref={inputRef}
            onChange={handleOnChange}
            type="number"
            className="w-full text-base text-center text-gray-900 outline-none"
          />
          <div onClick={handleCounterIncrease}>
            <Icon
              name="plus"
              className="w-3.5 h-3.5 text-gray-400 cursor-pointer"
            />
          </div>
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

Counter.displayName = 'Counter';

export default Counter;
