import React from 'react';
import { startCase } from 'lodash';

export type AppProps = {
  name?: string;
  profileImage?: string;
  className?: string;
  testId?: string;
};

const Avatar: React.FC<AppProps> = ({
  name = 'User',
  className = '',
  profileImage,
  testId = 'avatar',
}) => {
  if (profileImage) {
    return (
      <div
        className={`w-8 h-8 rounded-full ${className}`}
        role="none"
        data-testid={testId}
      >
        <img src={profileImage} className="rounded-full" />
      </div>
    );
  }

  const [first, last] = name.split(' ');
  const initials = startCase(first[0]) + startCase(last ? last[0] : '');
  return (
    <div
      className={`inline-flex items-center justify-center w-8 h-8 py-2 px-1.5 bg-gray-500 rounded-full ${className}`}
      role="none"
      data-testid={testId}
    >
      <p className="text-sm leading-none text-center text-white">{initials}</p>
    </div>
  );
};

export default Avatar;
