import React from 'react';
import { formatCurrency } from 'utils/misc';

type AppProps = {
  value: number;
  testId?: string | null;
};

const Amount: React.FC<AppProps> = ({ value, testId = null }) => {
  return (
    <p className="text-sm font-medium" data-testid={testId}>
      {value ? formatCurrency(value) : '-'}
    </p>
  );
};

export default Amount;
