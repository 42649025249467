import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import { logout } from 'utils/misc';

const getUserPool = () => {
  return {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  };
};

/**
 * Check whether the given cognito user attribute is custom one
 */
const customAttributes = ['role', 'organization'];
const isCustomAttribute = (name: string): boolean => {
  return customAttributes.includes(name);
};

/**
 * Return the user attribute composed with given name and value
 */
const createUserAttribute = (name: string, value: string) => {
  let formattedName = name;
  if (isCustomAttribute(name)) {
    formattedName = `custom:${name}`;
  }
  return { Name: formattedName, Value: value };
};

// ----------- Cognito handler for signup
interface ISignUpCallback {
  onSuccess: (result: any) => void;
  onFailure: (error: any) => void;
}

export const signUp = (
  email: string,
  password: string,
  attributes: { [key: string]: string },
  { onSuccess, onFailure }: ISignUpCallback
) => {
  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);

  const attributeList: CognitoUserAttribute[] = [];
  const validationData: CognitoUserAttribute[] = [];

  // Add additional attributes from the formData
  Object.entries(attributes).forEach(([key, value]) => {
    const formattedAttribute = createUserAttribute(key, value);
    const attribute = new CognitoUserAttribute(formattedAttribute);
    if (key !== 'password' && key !== 'confirmPassword') {
      attributeList.push(attribute);
    }
  });

  userPool.signUp(email, password, attributeList, validationData, (err, result) => {
    if (err) {
      onFailure(err);
      return;
    }
    onSuccess(result);
  });
};

// ----------- Cognito handler for code verification
interface IVerificationCallback {
  onSuccess: (result: any) => void;
  onFailure: (error: any) => void;
}

export const verifyRegisterUser = (
  email: string,
  code: string,
  { onSuccess, onFailure }: IVerificationCallback
) => {
  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(code, true, (err, result) => {
    if (err) {
      onFailure(err);
      return;
    } else {
      onSuccess(result);
    }
  });
};

// ----------- Cognito handler for verification code resend
interface IResendCodeCallback {
  onSuccess: (result: any) => void;
  onFailure: (error: any) => void;
}

export const resendVerificationCode = (
  email: string,
  { onSuccess, onFailure }: IResendCodeCallback
) => {
  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.resendConfirmationCode((err, result) => {
    if (err) {
      onFailure(err);
      return;
    } else {
      onSuccess(result);
    }
  });
};


// ----------- Cognito handler for signin
interface ISignInCallback {
  onSuccess: (result: CognitoUserSession) => any;
  onFailure: (error: any) => any;
  onNewPasswordRequired: (result: CognitoUserSession, congintoUser: any) => any;
}

export const signIn = (
  email: string,
  password: string,
  { onSuccess, onFailure, onNewPasswordRequired }: ISignInCallback
) => {
  const authenticationData = {
    Username: email,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess,
    onFailure,
    newPasswordRequired: (result: CognitoUserSession) =>
      onNewPasswordRequired(result, cognitoUser),
  });
  return true;
};

// -------- cogninto handler for set new password
interface ISetPasswordCallback {
  onSuccess: () => any;
  onFailure: (error: any) => any;
}

export const setPassword = (
  password: string,
  context: any,
  { onSuccess, onFailure }: ISetPasswordCallback
) => {
  const { requiredData, cognitoUser } = context;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email_verified, ..._requiredData } = requiredData;
  cognitoUser.completeNewPasswordChallenge(password, _requiredData, {
    onSuccess,
    onFailure,
  });
  return true;
};

// ------- conginto handler for forgot password flow
interface IForgotPasswordCallback {
  onSuccess: () => any;
  onFailure: (error: any) => any;
}

export const forgotPasswordIinitiate = (
  email: string,
  { onSuccess, onFailure }: IForgotPasswordCallback
) => {
  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
    onSuccess,
    onFailure,
  });
  return true;
};

interface IResetPasswordCallback {
  onSuccess: () => any;
  onFailure: (error: any) => any;
}

export const resetPassword = (
  email: string,
  code: string,
  password: string,
  { onSuccess, onFailure }: IResetPasswordCallback
) => {
  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.confirmPassword(code, password, {
    onSuccess,
    onFailure,
  });
  return true;
};

// -------- Cognito handler for change password

interface IChangePasswordCallback {
  onSuccess: (result: CognitoUserSession) => any;
  onFailure: (error: any) => any;
  onNewPasswordRequired: (err: any, result: any) => any;
}

export const changePassword = (
  email: string,
  oldPassword: string,
  newPassword: string,
  { onSuccess, onFailure, onNewPasswordRequired }: IChangePasswordCallback
) => {
  const poolData = getUserPool();
  const authData = {
    Username: email,
    Password: oldPassword,
  };
  const authDetails = new AuthenticationDetails(authData);

  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess,
    onFailure,
    newPasswordRequired: () => {
      cognitoUser.changePassword(
        oldPassword,
        newPassword,
        onNewPasswordRequired
      );
    },
  });

  return true;
};

// ---------- cognito handlers for refreshing token
export const refreshIdToken: any = async () => {
  const poolData = getUserPool();
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    logout();
  } else {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err: any, session: any) => {
        const refreshToken = session.getRefreshToken();
        cognitoUser.refreshSession(refreshToken, (err, newSession) => {
          if (err) {
            return reject(err);
          } else {
            return resolve(newSession.idToken.jwtToken);
          }
        });
      });
    });
  }
};
