import { useEffect } from 'react';
import { errorToast } from 'components/Toaster';

const useErrorToast = (err: any = null) => {
  useEffect(() => {
    if (err) {
      errorToast(err.message || 'Something went wrong');
    }
  }, [err]);
};

export default useErrorToast;
