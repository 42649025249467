import { gql } from '@apollo/client';
import { fragments as locationFragments } from './location';
import { fragments as lookupFragments } from './lookup';

export const fragments = {
  freight: gql`
    fragment FreightFragment on Freight {
      _id
      description
      handlingUnit {
        ...LookupFragment
      }
      handlingUnitNotes
      pickupLocation {
        ...LocationFragment
      }
      dropLocation {
        ...LocationFragment
      }
      quantity
      specialNotes
      shipment
      specialNotes
      stcc
      originalId
      totalVolume {
        H
        L
        W
      }
      totalWeight
      createdAt
      updatedAt
    }
    ${locationFragments.location}
    ${lookupFragments.lookup}
  `,
};

export const CREATE_FREIGHT = gql`
  mutation CreateFreight($body: FreightInput!) {
    createFreight(createFreightInput: $body) {
      _id
    }
  }
`;

export const UPDATE_FREIGHT = gql`
  mutation UpdateFreight($body: UpdateFreightInput!) {
    updateFreight(updateFreightInput: $body) {
      _id
    }
  }
`;

export const DELETE_FREIGHT = gql`
  mutation DeleteFreight($id: String!) {
    removeFreight(id: $id) {
      _id
    }
  }
`;
