import React, { useImperativeHandle, useState } from 'react';
import moment, { now } from 'moment';
import DatePicker from 'react-datepicker';
import Icon from 'components/Icon';
import 'react-datepicker/dist/react-datepicker.css';
import clx from 'classnames';

export type AppProps = {
  name: string;
  validator: any;
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  testId?: string;
};

const TimePicker = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      validator,
      initialValue = '',
      placeholder = '',
      disabled = false,
      testId = null,
    },
    ref
  ) => {
    const validateInputDate = (date: string) => {
      if (date) {
        const _date = moment(date, 'hh:mm A');
        if (_date.isValid()) {
          return new Date(_date.format('YYYY/MM/DD hh:mm a'));
        }
      }
      return null;
    };
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState<any>(validateInputDate(initialValue));
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);
    const [skipValidation, setSkipValidation] = useState(false);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => {
          setValue(validateInputDate(v));
          setError(null);
        },
        setDisabled,
        getValue: () => ({
          [name]: value ? moment(value).format('hh:mm A') : '',
        }),
        skipValidation: setSkipValidation,
        getError: () => ({ [name]: error }),
        checkError: () => {
          if (skipValidation) {
            return false;
          }
          try {
            validator.validateSync(value || '');
            return false;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
              return true;
            }
            return false;
          }
        },
      })
    );

    const handleOnChange = (date: any) => {
      const val = date;
      setValue(val);
      setError(null);
      // try {
      //   if (val) {
      //     if (!regex.test(val)) {
      //       // @ts-ignore
      //       setError('Please enter a valid value');
      //     }
      //   }
      // } catch (e: any) {
      //   if (e.errors && e.errors[0]) {
      //     // @ts-ignore
      //     setError('Please enter a valid value');
      //   }
      // }
    };
    const filterPassedTime = (time: any) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    };
    const inputStyle = clx(
      'w-full inline-flex space-x-2 items-center justify-start pl-2 py-2 bg-white shadow border rounded-md border-gray-300 outline-none',
      {
        'border-error focus:border-red': error,
        'focus-within:border-primary': !error,
        'bg-gray-100': isDisabled,
      }
    );

    return (
      <div className="w-full" data-testid={testId}>
        <div className={inputStyle} tabIndex={0} ref={inputRef}>
          <Icon name="clock" className="w-5 h-5 text-gray-500 rounded-full" />
          <DatePicker
            selected={value}
            onChange={handleOnChange}
            onChangeRaw={(e) => e.preventDefault()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Pickup Time"
            // filterTime={filterPassedTime}
            dateFormat="h:mm aa"
            placeholderText={placeholder}
            disabled={isDisabled}
            className="w-full outline-none focus:outline-none"
          />
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

TimePicker.displayName = 'TimePicker';

export default TimePicker;
