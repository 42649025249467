import React from 'react';
import ReactTooltip from 'react-tooltip';

export type AppProps = {
  children?: React.ReactNode;
  message: string;
  position?: string;
  testId?: string | null;
  disable?: boolean;
  isOnClick?: boolean;
  multiline?: boolean;
  className?: string;
};

const Tooltip: React.FC<AppProps> = ({
  children,
  message,
  position,
  testId = null,
  disable = false,
  isOnClick = false,
  multiline= false,
  className= ''
}) => {
  return (
    <div data-tip={message} data-testid={testId}>
      {children}
      <ReactTooltip 
        className={className}
        data-scroll-hide={true}
        multiline={multiline} 
        globalEventOff='click' 
        event={isOnClick ? 'click' : ''} 
        data-place={position} 
        disable={disable}
      />
    </div>
  );
};

export default Tooltip;
