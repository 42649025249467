import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = (props: any) => {
  return (
    <Scrollbars
      style={{ height: '100vh' }}
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      renderThumbVertical={(props) => <div {...props} className="scrollbar" />}
      thumbSize={30}
      {...props}
    />
  );
};

export default CustomScrollbars;
