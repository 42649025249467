import { gql } from '@apollo/client';

export const fragments = {
  lookup: gql`
    fragment LookupFragment on LookupItem {
      label
      value
    }
  `,
  lookupAccessorial: gql`
    fragment LookupAccessorialFragment on LKAccessorialCharge {
      label
      value
    }
  `,
};

export const GET_ALL_LOOKUP_CHOICES = gql`
  query GetManageLoadLookupData {
    getLookupData {
      accessorialCharges {
        label
        value
        shipmentMode
      }
      accessorialUnitTypes {
        ...LookupFragment
      }
      equipmentTypes {
        ...LookupFragment
      }
      handlingUnitTypes {
        ...LookupFragment
      }
      hazardousCategories {
        ...LookupFragment
      }
      shipmentModes {
        ...LookupFragment
      }
      unloadMethods {
        ...LookupFragment
      }
      countries {
        ...LookupFragment
      }
    }
  }
  ${fragments.lookup}
`;
