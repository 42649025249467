import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icon from 'components/Icon';

export enum States {
  Completed = 'completed',
  Current = 'current',
}

export type AppProps = {
  label: string;
  path?: string;
  active?: boolean;
  testId?: string;
};

const Step: React.FC<AppProps> = ({
  label,
  path = null,
  active = false,
  testId = 'step',
}) => {
  const current = path && active ? 'page' : 'false';
  const textStyle = classNames(`text-sm font-medium leading-tight`, {
    'text-primary cursor-pointer': !active,
    'text-gray-900': active,
  });

  const renderLabel = () => {
    if (path) {
      return (
        <Link to={path}>
          <p className={textStyle}>{label}</p>
        </Link>
      );
    }
    return <p className={textStyle}>{label}</p>;
  };

  return (
    <div
      className="flex-1 space-x-2 flex items-center whitespace-nowrap"
      data-testid={testId}
      aria-label={label}
      role="listitem"
      aria-current={current}
    >
      {renderLabel()}
      {!active ? (
        <Icon
          name="chevron-right"
          className="w-5 h-full rounded-lg"
          label="separator"
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Step;
