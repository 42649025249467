import { gql } from '@apollo/client';

export const fragments = {
  contact: gql`
    fragment ContactFragment on Contact {
      firstName
      lastName
      email
      phoneNumber
      extension
      companyName
    }
  `,
  address: gql`
    fragment AddressFragment on Address {
      line1
      line2
      city
      state
      country
      zip
    }
  `,
  pagination: gql`
    fragment PaginationFragment on PaginationInfo {
      total
    }
  `,
};

export const internalUserFragments = {
  internalUserContact: gql`
    fragment InternalUserContactFragment on InternalUserContact {
      firstName
      lastName
      email
      phoneNumber
      extension
      companyName
      isInternalUser
    }
  `,
};

export const locationFragments = {
  pickupDrop: gql`
    fragment PickupDropFragment on PickupDrop {
      _id
      address {
        ...AddressFragment
      }
      shipper
    }
    ${fragments.address}
  `,
};
