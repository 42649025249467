import Icon from 'components/Icon';
import Select from 'components/Input/components/Select';
import React from 'react';
import Pagination from 'rc-pagination';
import './pagination.css';

export type PaginationProps = {
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  // canPreviousPage: boolean;
  // canNextPage: boolean;
  pageIndex: number;
  setPageSize: (size: number) => void;
  pageSize: number;
  page: any;
  total: number;
  loading: boolean;
  testId?: string | null;
};

const pageSizeOptions = [5, 10, 15, 20, 25].map((item: any) => {
  return {
    label: item,
    value: item,
  };
});

const CustomPagination: React.FC<PaginationProps> = ({
  pageIndex,
  page,
  pageSize,
  setPageSize,
  total,
  gotoPage,
  previousPage,
  nextPage,
  loading = false,
  testId = null,
}) => {
  const btnStyle =
    'inline-flex items-center px-2 py-2 border border-gray-300 bg-gray-50 text-sm font-medium text-gray-500 hover:bg-gray-100';

  const toCount = pageIndex * pageSize + page.length;
  return (
    <div data-testid={testId}>
      <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200">
        <div className="w-full flex justify-between">
          <div className="flex items-center text-sm text-gray-700">
            <span className=" mr-2">Items per page </span>
            <div className="w-24">
              <Select
                name="pageSize"
                // @ts-ignore
                initialValue={pageSize}
                options={pageSizeOptions}
                onSelect={(item) => setPageSize(item.value)}
              />
            </div>
            {!!toCount && (
              <div className="ml-6">
                Showing{' '}
                <span className="font-medium">{pageIndex * pageSize + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {pageIndex * pageSize + page.length}
                </span>{' '}
                of <span className="font-medium">{total}</span> results
              </div>
            )}
          </div>

          <div>
            <nav
              className="inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <Pagination
                disabled={loading}
                hideOnSinglePage
                // showLessItems
                className="bg-gray-50"
                prevIcon={
                  <div
                    className={`${btnStyle} rounded-l-md`}
                    onClick={() => previousPage()}
                  >
                    <Icon name="chevron-left" className="h-5 w-5" />
                  </div>
                }
                nextIcon={
                  <div
                    className={`${btnStyle} rounded-r-md`}
                    onClick={() => nextPage()}
                  >
                    <Icon name="chevron-right" className="h-5 w-5" />
                  </div>
                }
                // jumpNextIcon={
                //   <div className={`${btnStyle}`}>
                //     <Icon name="ellipse" className="h-5 w-5" />
                //   </div>
                // }
                current={pageIndex + 1}
                pageSize={pageSize}
                total={total}
                onChange={(idx) => gotoPage(idx - 1)}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPagination;
