import React, { useImperativeHandle, useState } from 'react';
import classNames from 'classnames';

export type AppProps = {
  name: string;
  placeholder?: string;
  initialValue?: string;
  validator: any;
  disabled?: boolean;
  suffix?: string;
  onChange?: (val: any) => void;
  testId?: string;
};

const Volume = React.forwardRef<HTMLElement, AppProps>(
  (
    {
      name,
      placeholder = '',
      initialValue = '',
      disabled = false,
      validator,
      suffix = '',
      onChange = () => null,
      testId = null,
    },
    ref
  ) => {
    const inputRef = React.useRef<any>(null);
    const [value, setValue] = useState(initialValue || '');
    const [isDisabled, setDisabled] = useState(disabled);
    const [error, setError] = useState(null);

    useImperativeHandle(
      ref,
      // @ts-ignore
      () => ({
        name,
        focus: () => inputRef?.current?.focus?.(),
        overrideValue: (v: string) => {
          setValue(v);
          setError(null);
        },
        setDisabled,
        getValue: () => ({
          [name]: value,
        }),
        getError: () => ({ [name]: error }),
        checkError: () => {
          try {
            validator.validateSync(value || '');
            if (!validateIndividualValue(value)) {
              // @ts-ignore
              setError('Volume should be less than 9,999,999,999');
              return true;
            }
            return false;
          } catch (e: any) {
            if (e.errors && e.errors[0]) {
              setError(e.errors[0]);
              return true;
            }
            return false;
          }
        },
      })
    );

    const validateIndividualValue = (v: string) => {
      const vArr = v.split('x');
      let volume = 1;
      vArr.forEach((_v) => {
        // @ts-ignore
        if (!isNaN(_v) && parseFloat(_v)) {
          volume = volume * parseFloat(_v);
        }
      });
      return volume < 9999999999;
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      const _v = v.replaceAll(' ', 'x');
      // @ts-ignore
      if (!isNaN(_v.replaceAll('x', '')) && _v.split('x').length <= 3) {
        onChange(_v);
        setValue(_v);
      }
      try {
        validator.validateSync(v || '');
        if (!validateIndividualValue(v)) {
          // @ts-ignore
          setError('Volume should be less than 9,999,999,999');
        } else {
          setError(null);
        }
      } catch (e: any) {
        if (e.errors && e.errors[0]) {
          setError(e.errors[0]);
        }
      }
    };

    const inputStyle = classNames(
      'w-full text-base leading-normal text-gray-900 outline-none placeholder-gray-400 px-3 py-2 bg-white shadow border rounded-md border-gray-300',
      {
        'border-error focus:border-red': error,
        'focus:border-primary': !error,
        'bg-gray-100': isDisabled,
      }
    );

    return (
      <div className="w-full relative" data-testid={testId}>
        <input
          autoComplete="never"
          ref={inputRef}
          className={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          disabled={isDisabled}
        />
        {suffix && (
          <div className="absolute inset-y-0 right-2 flex items-center text-base leading-normal text-gray-500">
            {suffix}
          </div>
        )}
        {error && <div className="error-text">{error}</div>}
      </div>
    );
  }
);

Volume.displayName = 'Volume';

export default Volume;
