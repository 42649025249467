import React, { useRef } from 'react';
import Modal from 'components/Modal';
import Button, { Variants as ButtonVariants } from 'components/Button';
import { InputType } from 'components/Input/utils';
import Form from 'components/Form';
import { useMutation } from '@apollo/client';
import { EDIT_INTERNAL_USER } from 'queries/internalUser';
import { successToast } from 'components/Toaster';
import useErrorToast from 'hooks/useErrorToast';
import useAuth from 'hooks/useAuth';
import { SHIPPER_EDIT_BY_ID } from 'queries/shipper';
import { Roles } from 'utils/enum';
import { ADMIN_EDIT_BY_ID } from 'queries/toggleManagers';
type AppProps = {
  trigger: React.ReactElement;
  testId?: string | null;
  _id: string;
  internalUser: Record<string, any>;
  roles: any;
};

const EditInternaUserModal: React.FC<AppProps> = ({
  trigger,
  testId = null,
  _id,
  internalUser = {},
  roles = {},
}) => {
  const authInfos: any = useAuth();
  const [editInternalUser, { loading: editing, error }] = useMutation(authInfos.accountInfo.role === Roles.Shipper ? SHIPPER_EDIT_BY_ID:authInfos.accountInfo.role === Roles.Toggle
    ?ADMIN_EDIT_BY_ID: EDIT_INTERNAL_USER);

  const internalUserRef = useRef(null);
  useErrorToast(error);

  const handleSubmit = async (hideModal: () => void) => {
    if (internalUserRef.current) {
      // @ts-ignore
      if (!internalUserRef.current.hasErrors()) {
        // @ts-ignore
        const formValues = internalUserRef.current.getValues();
        const payload: any = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          phoneNumber: formValues.phoneNumber,
        };
        try {
          if (authInfos.accountInfo.role === Roles.Shipper) {
            payload.email = formValues.email
            await editInternalUser({
              variables: {
                contact: payload,
                shipperId: _id,
              },
            })
            successToast('Shipper Contact Updated Successfully');
          }else if (authInfos.accountInfo.role === Roles.Toggle) {
            payload.email = formValues.email

            await editInternalUser({
              variables: {
                contact: payload,
                id: _id,
              },
            })
            successToast('Admin User Updated Successfully');
          }
          else {
            await editInternalUser({
              variables: {
                internalUser: payload,
                editInternalUserId: _id,
              },
            })
            successToast('Internal User Updated Successfully');
          }
          hideModal();
          history.go(0);
        } catch (e) { }
      }
    }
  };

  const internalUserForm = [
    {
      key: 'toggleManager',
      className: 'px-5',
      fields: [
        {
          key: 'line1',
          fields: [
            {
              key: 'part1',
              fields: [
                {
                  key: 'firstName',
                  label: 'First Name',
                  type: InputType.Text,
                  value: internalUser?.firstName,
                  validations: [
                    {
                      type: 'required',
                      params: ['This is a required field'],
                    },
                  ],
                  testId: `${testId}-firstName`,
                },
                {
                  key: 'lastName',
                  label: 'Last Name',
                  type: InputType.Text,
                  value: internalUser.lastName,
                  // disabled: !!selected,
                  validations: [
                    {
                      type: 'required',
                      params: ['This is a required field'],
                    },
                  ],
                  testId: `${testId}-lastName`,
                },
              ],
            },
          ],
        },
        {
          key: 'email',
          label: 'Email Address',
          type: InputType.Email,
          value: internalUser?.email,
          disabled: true,
          validations: [
            {
              type: 'required',
              params: ['This is a required field'],
            },
            {
              type: 'email',
              params: ['Please enter a valid email'],
            },
          ],
          testId: `${testId}-email`,
        },
        {
          key: 'line2',
          fields: [
            {
              key: 'phoneNumber',
              label: 'Phone Number',
              placeholder: '(000) 000-0000',
              type: InputType.Phone,
              value: internalUser?.phoneNumber,
              // disabled: !!selected,
              validations: [
                {
                  type: 'required',
                  params: ['This is a required field'],
                },
              ],
              testId: `${testId}-phoneNumber`,
            },
            {
              key: 'role',
              label: 'Role',
              disabled: true,
              type: InputType.Autocomplete,
              options: roles?.getAllRoles?.map(
                (role: { roleDesc: any; roleId: any }) => ({
                  label: role.roleDesc,
                  value: role.roleId,
                })
              ),
              placeholder: 'Select Role',
              value: internalUser?.role,
              validations: [
                {
                  type: 'required',
                  params: ['This is a required field'],
                },
              ],
              //testId: { testId },
            },
          ],
        },
      ],
    },
  ];

  const renderContent = () => {
    return (
      <div>
        <div className="h-72 w-full py-2">
          <Form key="shipper" config={internalUserForm} ref={internalUserRef} />
        </div>
      </div>
    );
  };

  const renderFooter = (hideModal: () => void) => {
    return (
      <div className="w-full flex justify-between items-center">
        <div>
          <Button
            variant={ButtonVariants.Outline}
            label="Cancel"
            onClick={hideModal}
          // disabled={loading}
          // testId="shipper-impersonate-cancel-btn"
          />
        </div>
        <div>
          <Button
            variant={ButtonVariants.Primary}
            label="SAVE"
            rightIcon="arrow-right"
            disabled={editing}
            loading={editing}
            onClick={() => handleSubmit(hideModal)}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Edit Internal User"
      className="w-3/5"
      trigger={trigger}
      content={renderContent}
      footer={renderFooter}
    // testId={testId}
    />
  );
};

export default EditInternaUserModal;
